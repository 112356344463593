import { updateCollectionSearchParams } from './updateCollectionSearchParams'
import { useState, useEffect } from 'react'
import { CollectionSearchParamsNames } from '@aether/collection'
import { useSearchParams } from 'next/navigation'

export type UpdateFiltersParamsFnType = (
  filterName: CollectionSearchParamsNames,
  value: string,
  isSingleChoice?: boolean,
) => void

export const useCollectionSearchParams = () => {
  const searchParamsString = useSearchParams().toString()
  const [query, setQuery] = useState<URLSearchParams>(
    new URLSearchParams(searchParamsString),
  )

  const updateQuery: UpdateFiltersParamsFnType = (
    filterName,
    value,
    isSingleChoice,
  ) => {
    const updatedQuery = updateCollectionSearchParams(
      query,
      filterName,
      value,
      isSingleChoice,
    )
    setQuery(updatedQuery)
  }

  useEffect(() => {
    setQuery(new URLSearchParams(searchParamsString))
  }, [searchParamsString])

  return [query, updateQuery] as const
}
