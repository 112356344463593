import { FC } from 'react'
import { SectionSingleImageProps } from '@aether/models'
import {
  Container,
  Media,
  PortableText,
  SectionWrapper,
  ProductsModalMediaWrap,
} from '@aether/components'
import { styled } from '@aether/styles'

const StyledContainer = styled(Container, {
  width: '100%',
  display: 'grid',
})
const MediaWrap = styled('div', {
  gridColumn: 1,
  gridRow: 1,
})
const Caption = styled('div', {
  display: 'block',
  $aetherFont: 'body02',
  color: '$black',
  marginTop: '$16',
})

export const SectionSingleImage: FC<SectionSingleImageProps> = ({
  image,
  sectionSpaceSize,
  priority,
  imageCaption,
  shopifyIds,
  productsButtonAppearance,
}) => {
  return (
    <SectionWrapper>
      <StyledContainer spaceSize={sectionSpaceSize}>
        <MediaWrap>
          <ProductsModalMediaWrap
            shopifyIds={shopifyIds}
            appearance={productsButtonAppearance}
          >
            <Media
              {...image}
              priority={priority}
              sizes={{ default: '100vw' }}
              withBackground
            />
          </ProductsModalMediaWrap>
        </MediaWrap>
        {imageCaption && (
          <Caption>
            <PortableText
              value={imageCaption}
              withSpacing={false}
              withStyles={false}
            />
          </Caption>
        )}
      </StyledContainer>
    </SectionWrapper>
  )
}
