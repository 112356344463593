import type { NextPage } from 'next'
import { FC, RefObject, useRef } from 'react'
import { NextSeo } from 'next-seo'
import { useRouter } from 'next/router'

import { PageBuilder } from '@aether/page-builder'
import {
  PageResponse,
  PageBuilderSection,
  SiteConfiguration,
  ShopifyProductsData,
} from '@aether/models'
import { Sidebar } from '@aether/sidebar'
import { styled } from '@aether/styles'
import { CookieDeclaration, Module, seoImageLoader } from '@aether/components'
import { ROUTE_PRIVACY_POLICY } from '@aether/configs'

const Wrap = styled('main', {
  variants: {
    sidebar: {
      true: {
        '@lg': {
          display: 'grid',
          gridTemplateColumns: 'minmax(300px, 3fr) 7fr',
          alignItems: 'start',
        },
      },
    },
  },
})

const MappedPageBuilder: FC<{
  pageBuilder: PageBuilderSection[]
  siteConfiguration?: SiteConfiguration
  pageBuilderRef?: RefObject<HTMLDivElement>
  shopifyData?: ShopifyProductsData
}> = ({ pageBuilder, siteConfiguration, pageBuilderRef, shopifyData }) => {
  return (
    <>
      {pageBuilder?.map((section, index) => {
        return (
          <PageBuilder
            section={{
              ...section,
              priority: index === 0,
              siteConfiguration,
              pageBuilderRef,
            }}
            shopifyData={shopifyData}
            key={`${section._type}-${index}`}
          />
        )
      })}
    </>
  )
}

export const DefaultPage: NextPage<PageResponse<PageBuilderSection[]>> = ({
  pageBuilder,
  sidebar,
  shopifyData,
  siteConfiguration,
  modules,
  seo,
}) => {
  const pageBuilderRef = useRef(null)
  const { asPath } = useRouter()
  const fullUrl = `${process.env.BASE_URL}${asPath}`
  const canonicalUrl = `${process.env.BASE_URL}${
    asPath === '/' ? '' : asPath.split('?')[0]
  }`
  const isPrivacyPage = asPath.endsWith(ROUTE_PRIVACY_POLICY)

  const seoImage = seoImageLoader(seo?.ogImage, seo?.ogAltImage)
  return (
    <Wrap id="main" sidebar={!!sidebar} ref={pageBuilderRef}>
      {sidebar && <Sidebar links={sidebar.links} />}
      {seo && (
        <NextSeo
          title={seo.metaTitle}
          description={seo.metaDescription}
          canonical={canonicalUrl}
          openGraph={{
            url: fullUrl,
            type: 'website',
            title: seo.ogTitle,
            description: seo.ogDescription,
            images: [...(seoImage ? [seoImage] : [])],
          }}
          twitter={{
            cardType: 'summary_large_image',
          }}
        />
      )}
      {sidebar ? (
        <div>
          <MappedPageBuilder
            pageBuilder={pageBuilder}
            shopifyData={shopifyData}
            siteConfiguration={siteConfiguration}
            pageBuilderRef={pageBuilderRef}
          />
          {isPrivacyPage && <CookieDeclaration />}
        </div>
      ) : (
        <MappedPageBuilder
          pageBuilder={pageBuilder}
          shopifyData={shopifyData}
          siteConfiguration={siteConfiguration}
          pageBuilderRef={pageBuilderRef}
        />
      )}
      {modules?.map((mod) => (
        <Module key={mod._key} moduleData={mod} />
      ))}
    </Wrap>
  )
}
