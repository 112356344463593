import { FC } from 'react'
import {
  InfiniteProductsList,
  InfiniteProductsListItem,
  ProductItem,
  SectionWrapper,
} from '@aether/components'

import { SiteConfiguration, ShopifyProductsData } from '@aether/models'
import { DEFAULT_LOCALE_ID } from '@aether/configs'

import { useRouter } from 'next/router'
import { useSearchParams } from 'next/navigation'
import {
  useCollectionProducts,
  useCollectionRelatedProducts,
} from '@aether/collection'

type Props = {
  shopifyData?: ShopifyProductsData
  siteConfiguration?: SiteConfiguration
}

export const FilteredProductsList: FC<Props> = ({
  shopifyData,
  siteConfiguration,
}) => {
  const { locale = DEFAULT_LOCALE_ID } = useRouter()
  const handle = shopifyData?.collection?.handle || ''
  const searchParamsString = useSearchParams().toString()

  const { fetchNextPage, isFetching, hasNextPage, products, lastPageProducts } =
    useCollectionProducts({
      locale,
      handle,
      searchParams: new URLSearchParams(searchParamsString),
    })

  const lastPageProductsTitles = lastPageProducts.map((p) => p.title)

  const relatedProducts = useCollectionRelatedProducts({
    handle,
    locale,
    titles: lastPageProductsTitles,
  })

  const updatedShopifyData = {
    products: [...products, ...relatedProducts]?.reduce(
      (acc, product) => ({
        ...acc,
        [product.id]: product,
      }),
      {},
    ),
    variants: {},
  }

  const sectionProductsIds = products?.map((p) => p.id)

  const getMoreProducts = () => {
    if (hasNextPage) {
      fetchNextPage()
    }
  }

  return (
    <SectionWrapper>
      <InfiniteProductsList getMore={getMoreProducts} isLoading={isFetching}>
        {sectionProductsIds?.map((productId) => {
          return (
            <InfiniteProductsListItem key={productId}>
              <ProductItem
                colorsMap={siteConfiguration?.colorsMap}
                productId={productId}
                shopifyData={updatedShopifyData}
                key={productId}
              />
            </InfiniteProductsListItem>
          )
        })}
      </InfiniteProductsList>
    </SectionWrapper>
  )
}
