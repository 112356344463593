import { useState, RefObject } from 'react'
import { useMediaQuery } from '@aether/styles'
import useResizeObserver from '@react-hook/resize-observer'
import { useIsomorphicLayoutEffect } from '@aether/utils'

/**
 * Scale settings
 */
const SCALE_RATIO_FAHRENHEIT = 20
const SCALE_RATIO_FAHRENHEIT_MOBILE = 20
const SCALE_RATIO_CELSIUS = 10
const SCALE_RATIO_CELSIUS_MOBILE = 10

const MIN_SCALE_FAHRENHEIT = 0
const MAX_SCALE_FAHRENHEIT = 90

const MIN_SCALE_CELSIUS = -20
const MAX_SCALE_CELSIUS = 30

/**
 * Chart Bar settings
 */
const BAR_WIDTH = 4
const BAR_WIDTH_MOBILE = 2

const BAR_MARGIN = 0
const BAR_MARGIN_MOBILE = 3

const BAR_MAX_HEIGHT = 46
const BAR_MIN_HEIGHT = 2

const scale = (
  num: number,
  inMin: number,
  inMax: number,
  outMin: number,
  outMax: number,
): number => {
  const result = ((num - inMin) * (outMax - outMin)) / (inMax - inMin) + outMin

  if (result < outMin) {
    return outMin
  } else if (result > outMax) {
    return outMax
  }

  return result
}

const getSlopedValue = (
  tempValue: number,
  minTemp: number,
  maxTemp: number,
) => {
  const isInScope = tempValue >= minTemp && tempValue <= maxTemp

  if (!isInScope) {
    return BAR_MIN_HEIGHT
  }

  const slope = (maxTemp + minTemp) / 2
  const barMaxHeight = BAR_MAX_HEIGHT * 2

  if (tempValue < slope) {
    return scale(tempValue, minTemp, maxTemp, BAR_MIN_HEIGHT, barMaxHeight)
  }

  if (tempValue > slope) {
    return scale(tempValue, maxTemp, minTemp, BAR_MIN_HEIGHT, barMaxHeight)
  }

  return scale(slope, minTemp, maxTemp, BAR_MIN_HEIGHT, barMaxHeight)
}

const convertToCelsius = (deg: number) => ((deg - 32) * 5) / 9

type Props = {
  minTemp: number
  maxTemp: number
  chartRef: HTMLElement | RefObject<HTMLElement> | null
  unit: 'C' | 'F'
}

type Return = {
  data: number[]
  barWidth: number
  barMargin: number
  scaleMin: number
  scaleMax: number
  scaleRatio: number
  minTempConverted: number
  maxTempConverted: number
}

export const useTemperatureChart = ({
  minTemp,
  maxTemp,
  chartRef,
  unit,
}: Props): Return => {
  const [width, setWidth] = useState<number>(100)
  const [barsAmmount, setBarsAmount] = useState<number>(100)
  const isMd = useMediaQuery('md')
  const isCels = unit === 'C'

  /**
   * Responsive settings
   */
  const {
    barWidth,
    barMargin,
    scaleRatio,
    minTempConverted,
    maxTempConverted,
    scaleMax,
    scaleMin,
  } = (() => {
    // Desktop Celsius
    if (isMd && isCels) {
      return {
        barWidth: BAR_WIDTH,
        barMargin: BAR_MARGIN,
        minTempConverted: convertToCelsius(minTemp),
        maxTempConverted: convertToCelsius(maxTemp),
        scaleMax: MAX_SCALE_CELSIUS,
        scaleMin: MIN_SCALE_CELSIUS,
        scaleRatio: SCALE_RATIO_CELSIUS,
      }
    }
    // Mobile Celsius
    if (!isMd && isCels) {
      return {
        barWidth: BAR_WIDTH_MOBILE,
        barMargin: BAR_MARGIN_MOBILE,
        minTempConverted: convertToCelsius(minTemp),
        maxTempConverted: convertToCelsius(maxTemp),
        scaleMax: MAX_SCALE_CELSIUS,
        scaleMin: MIN_SCALE_CELSIUS,
        scaleRatio: SCALE_RATIO_CELSIUS_MOBILE,
      }
    }

    // Desktop Fahrenheit
    if (isMd && !isCels) {
      return {
        barWidth: BAR_WIDTH,
        barMargin: BAR_MARGIN,
        minTempConverted: minTemp,
        maxTempConverted: maxTemp,
        scaleMax: MAX_SCALE_FAHRENHEIT,
        scaleMin: MIN_SCALE_FAHRENHEIT,
        scaleRatio: SCALE_RATIO_FAHRENHEIT,
      }
    }

    // Mobile Fahrenheit
    return {
      barWidth: BAR_WIDTH_MOBILE,
      barMargin: BAR_MARGIN_MOBILE,
      minTempConverted: minTemp,
      maxTempConverted: maxTemp,
      scaleMax: MAX_SCALE_FAHRENHEIT,
      scaleMin: MIN_SCALE_FAHRENHEIT,
      scaleRatio: SCALE_RATIO_FAHRENHEIT_MOBILE,
    }
  })()

  useResizeObserver(chartRef, (entry) => setWidth(entry.contentRect.width))
  useIsomorphicLayoutEffect(() => {
    // set amount of chart bars needed to fill in the full width of the chart
    const dots = Math.floor(width / (barWidth + barMargin))
    setBarsAmount(dots)
  }, [width, barMargin, barWidth])

  // add min/max values to the chart data
  const data = [...Array(barsAmmount)]
    // distribute degree values along the bars values from min to max value of the scale
    .map((_, i) => scale(i, 0, barsAmmount, scaleMin, scaleMax))
    // add slope
    .map((scaledValue) =>
      getSlopedValue(scaledValue, minTempConverted, maxTempConverted),
    )

  return {
    data,
    barWidth,
    barMargin,
    scaleMin,
    scaleMax,
    scaleRatio,
    minTempConverted,
    maxTempConverted,
  }
}
