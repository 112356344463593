import { FC, useEffect, useRef, useState } from 'react'
import {
  MediaFieldType,
  SectionProductConversionAreaProps,
  ShopifyProduct,
} from '@aether/models'
import { styled, useMediaQuery } from '@aether/styles'
import { Button, Media, Modal, SectionWrapper } from '@aether/components'
import { useWunderkind } from '@aether/services/wunderkind-service'
import { useTranslation } from 'react-i18next'
import { useRouter } from 'next/router'
import { GTMViewItem } from '@aether/services/gtm-service'
import { getProductMetafieldValues } from '@aether/utils'
import { ProductGalleryDesktop } from './ProductGalleryDesktop'
import { ProductGalleryMobile } from './ProductGalleryMobile'
import { ProductDetails } from './ProductDetails'
import { ProductSoldOut } from './ProductSoldOut'
import { SectionTechnicalSpecifications } from '../SectionTechnicalSpecifications/SectionTechnicalSpecifications'
import { useUrlFragment } from '@aether/utils'
import { TechnicalFeaturesModal } from './TechnicalFeaturesModal/TechnicalFeaturesModal'
import { TechnicalFeaturesComponent } from './TechnicalFeaturesComponent'

const Wrap = styled('div', {
  display: 'grid',
  gridGap: '$24',
  gridTemplateColumns: '1fr',
  $containerSpace: 'none',
  $containerSize: 'XL',
  '@lg': {
    $containerSpace: 'small',
    gridGap: '$40',
    gridTemplateColumns: '4fr 3fr',
    position: 'relative',
  },
})

const TechnicalFeatures = styled('div', {
  paddingTop: '$16',
  display: 'grid',
})
const FeatureImage = styled('div', {
  width: '$72',
  height: '$72',
  margin: '0 auto',
  background: '$gray_light',
  padding: '$12',
  borderRadius: '$r3',
  transition: '300ms ease-in-out',

  '@md': {
    background: 'none',
    '&:hover': {
      borderRadius: '$r3',
      background: '$gray_extra_light',
    },
  },

  variants: {
    type: {
      modal: {
        width: '$72',
        height: '$72',
        background: '$gray_light',
        padding: '$16',
        borderRadius: '$r3',
        margin: 0,
      },
    },
    isMobile: {
      true: {
        '@md': {
          display: 'none',
        },
      },
    },
  },
})
const FeatureText = styled('span', {
  lineHeight: '13px',
})

const FeaturesTitle = styled('h3', {
  $aetherFont: 'heading05',
  $containerSpace: 'small',
  '@lg': {
    $containerSpace: 'none',
  },
})
const FeaturesWrapSlider = styled('div', {
  marginTop: '$20',
  display: 'flex',
  overflowX: 'scroll',
  scrollSnapType: 'x mandatory',
  scrollBehavior: 'smooth',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
  width: '100%',
  '@lg': {
    overflow: 'hidden',
  },
})
const FeaturesContent = styled('div', {
  $containerSpace: 'small',
  display: 'grid',
  gridAutoColumns: '100px',
  gridAutoFlow: 'column',
  columnGap: '4px',

  '@lg': {
    width: '100%',
    padding: 0,
    gridTemplateColumns: 'repeat(auto-fill, minmax(80px, 1fr))',
    gridAutoFlow: 'unset',
    gridAutoColumns: 'unset',
    rowGap: '$16',
    columnGap: '$16',
  },
})

const Feature = styled('div', {
  $aetherFont: 'ui03',
  justifySelf: 'center',
  display: 'flex',
  cursor: 'pointer',
  ':not(:last-child)': {
    marginBottom: '$8',
  },
  flexDirection: 'column',
  textAlign: 'center',
  '& svg': {
    display: 'block',
    margin: '0 auto',
  },
})

export const DividerWrap = styled('div', {
  $containerSpace: 'small',
  '@lg': {
    $containerSpace: 'none',
  },
})

export const Divider = styled('div', { height: 1, background: '$black' })

const SectionWrapperNoTopMargin = styled(SectionWrapper, {
  marginTop: 0,
})

const StyledProductGalleryDesktopStickyWrap = styled('div', {
  position: 'relative',
  top: 0,
  left: 0,
  borderWidth: 1,
  borderColor: 'black',
  minHeight: '100%',
})

const StyledProductGalleryDesktop = styled(ProductGalleryDesktop, {
  display: 'none',
  '@lg': {
    display: 'grid',
    position: 'sticky',
    top: '$16',
  },
})

const StyledProductGalleryMobile = styled(ProductGalleryMobile, {
  display: 'grid',
  '@lg': {
    display: 'none',
  },
})

const TechnicalFeaturesHeadingWrap = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
})

const TechnicalFeaturesLinkWrap = styled('div', {
  display: 'flex',
  $containerSpace: 'small',
  marginTop: '$20',

  '@lg': {
    marginTop: '$24',
    padding: '0',
    display: 'block',
  },
})

const TechnicalSpecificationsWrap = styled('div', {
  position: 'relative',
})

const MODAL_FRAGMENT = 'technical-specification'

type TechnicalFeaturesType = {
  label: string
  description: string
  image: MediaFieldType
}[]

export const SectionProductConversionArea: FC<
  SectionProductConversionAreaProps
> = ({
  productId: initialProductId,
  sectionTechnicalSpecification,
  shopifyData,
  siteConfiguration,
  priority,
  isTechnicalSpecificationPresent,
}) => {
  const technicalFeaturesMap = siteConfiguration?.technicalFeaturesMap || []
  const performanceFeaturesMap = siteConfiguration?.performanceFeaturesMap
  const router = useRouter()
  const [urlFragment, setUrlFragment] = useUrlFragment()
  const matchesMd = useMediaQuery('md')
  const currentHandle = Array.isArray(router.query.slug)
    ? router.query.slug[0]
    : router.query.slug

  const productId =
    Object.values(shopifyData?.products || {}).find((product) => {
      return product.handle === currentHandle
    })?.id || initialProductId

  const productData: ShopifyProduct | undefined =
    shopifyData?.products[productId]

  useWunderkind({
    pageType: 'product',
    productData,
  })

  const isSoldOut =
    productData?.soldOut && productData?.soldOut?.value === 'true'

  const images = productData?.images || []
  const { t } = useTranslation('product')

  const switchImage = (index: number) => {
    setActiveProductMediaId(index)
  }

  const stickyButtonTopRef = useRef<HTMLDivElement>(null)

  const mappedFeatures = (() => {
    if (!productData?.techFeatures?.value || !technicalFeaturesMap) return []
    const productTechFeatures: string[] = getProductMetafieldValues(
      'techFeatures',
      productData,
    )

    return productTechFeatures.reduce<TechnicalFeaturesType>(
      (acc, productFeature) => {
        const sanityFeature = technicalFeaturesMap.find(
          (sanityFeature) => sanityFeature.shopifyId === productFeature,
        )
        return sanityFeature
          ? [
              ...acc,
              {
                label: sanityFeature?.label,
                description: sanityFeature?.description,
                image: sanityFeature?.image,
              },
            ]
          : acc
      },
      [],
    )
  })()

  useEffect(() => {
    if (productData) {
      const productFirstVariant =
        productData.variantsIds && productData.variantsIds[0]
      if (productFirstVariant) {
        GTMViewItem(productData, shopifyData?.variants[productFirstVariant])
      }
    }
  }, [productData?.id, JSON.stringify(shopifyData?.variants)])

  const [activeProductMediaId, setActiveProductMediaId] = useState(0)
  const [showTechnicalFeaturesModal, setShowTechnicalFeaturesModal] =
    useState(false)

  const [selectedTechnicalFeature, setSelectedTechnicalFeature] = useState(0)

  const handleOpenTechnicalFeaturesModal = (mappedFeature: number) => {
    setSelectedTechnicalFeature(mappedFeature)
    setShowTechnicalFeaturesModal(true)
  }

  const handleCloseTechnicalFeaturesModal = () => {
    setShowTechnicalFeaturesModal(false)
  }

  useEffect(() => {
    setActiveProductMediaId(0)
  }, [productData?.id])

  const isEvent = productData?.pdpType?.value?.toLowerCase() === 'event'

  return (
    <SectionWrapperNoTopMargin>
      <Wrap id="section-product-conversion-area-main">
        <StyledProductGalleryDesktopStickyWrap>
          <StyledProductGalleryDesktop
            images={images}
            priority={priority}
            activeImageId={activeProductMediaId}
            setActiveImage={switchImage}
          />
        </StyledProductGalleryDesktopStickyWrap>
        <StyledProductGalleryMobile
          images={images}
          priority={priority}
          activeImageId={activeProductMediaId}
          setActiveImage={switchImage}
        />
        <div ref={stickyButtonTopRef}>
          {isSoldOut ? (
            <ProductSoldOut
              productData={productData}
              shopifyData={shopifyData}
            />
          ) : (
            <ProductDetails
              siteConfiguration={siteConfiguration}
              productData={productData}
              shopifyData={shopifyData}
              productId={productId}
            />
          )}
          {!isEvent && (
            <DividerWrap>
              <Divider />
            </DividerWrap>
          )}
          {mappedFeatures.length > 0 && (
            <TechnicalFeatures>
              <TechnicalFeaturesHeadingWrap>
                <FeaturesTitle>{t('techFeatures')}</FeaturesTitle>
              </TechnicalFeaturesHeadingWrap>
              <FeaturesWrapSlider>
                <FeaturesContent>
                  {mappedFeatures.map((feature, index) => (
                    <Feature
                      key={feature.label}
                      onClick={() => handleOpenTechnicalFeaturesModal(index)}
                    >
                      <FeatureImage>
                        <Media
                          {...feature.image}
                          hardcropRatio="square"
                          sizes={{ default: '42px' }}
                        />
                      </FeatureImage>
                      <FeatureText>{feature.label}</FeatureText>
                    </Feature>
                  ))}
                </FeaturesContent>
              </FeaturesWrapSlider>
            </TechnicalFeatures>
          )}
          <TechnicalFeaturesComponent
            productData={productData}
            performanceFeaturesMap={performanceFeaturesMap}
          />
          {!!isTechnicalSpecificationPresent && (
            <TechnicalFeaturesLinkWrap>
              <Button
                appearance="badgeOutline"
                onClick={() => setUrlFragment(MODAL_FRAGMENT)}
              >
                {t('scrollToTechnicalSpecification')}
              </Button>
            </TechnicalFeaturesLinkWrap>
          )}
        </div>
      </Wrap>
      {!!isTechnicalSpecificationPresent && sectionTechnicalSpecification && (
        <Modal
          preventScroll={true}
          isOpen={urlFragment === MODAL_FRAGMENT}
          size={matchesMd ? 'stretch-y' : 'stretch'}
          position={matchesMd ? 'right' : 'center'}
          onRequestClose={() => setUrlFragment('')}
          transition={'slide-from-right'}
          overlayVariant={'darker'}
          style={{
            content: {
              maxWidth: matchesMd ? '66%' : '100%',
              right: 0,
              left: matchesMd ? 'auto' : 0,
            },
          }}
        >
          <TechnicalSpecificationsWrap>
            <SectionTechnicalSpecifications
              {...sectionTechnicalSpecification}
              shopifyData={shopifyData}
              productId={productId}
              siteConfiguration={siteConfiguration}
              isRenderedInModal={true}
              handleModalClose={() => setUrlFragment('')}
              isModal={true}
            />
          </TechnicalSpecificationsWrap>
        </Modal>
      )}
      {showTechnicalFeaturesModal && (
        <TechnicalFeaturesModal
          showTechnicalFeaturesModal={showTechnicalFeaturesModal}
          handleOpenTechnicalFeaturesModal={handleOpenTechnicalFeaturesModal}
          handleCloseTechnicalFeaturesModal={handleCloseTechnicalFeaturesModal}
          selectedTechnicalFeature={mappedFeatures[selectedTechnicalFeature]}
          mappedFeatures={mappedFeatures}
        />
      )}
    </SectionWrapperNoTopMargin>
  )
}
