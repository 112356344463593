import { FC } from 'react'
import { SectionHeroProps } from '@aether/models'
import {
  Container,
  Link,
  Media,
  SectionWrapper,
  PortableText,
} from '@aether/components'
import { styled } from '@aether/styles'

const Wrap = styled('div', {
  width: '100%',
  $posterHeight: true,
  display: 'grid',
  variants: {
    heroTextTheme: {
      light: {
        color: '$white',
      },
      dark: {
        color: '$black',
      },
    },
  },
})
const MediaWrap = styled('div', {
  width: '100%',
  gridColumn: 1,
  gridRow: 1,
})
const StyledContainer = styled(Container, {
  width: '100%',
  zIndex: '$content',
  gridColumn: 1,
  gridRow: 1,
  padding: '$64 0',
})
const ContentWrap = styled('div', {
  display: 'grid',
  gridGap: '$24',
  width: '100%',
  height: '100%',
  alignContent: 'end',
  '@lg': {
    gridTemplateRows: 'auto 1fr',
    alignSelf: 'end',
    gridRow: '2/3',
  },
  variants: {
    borderPosition: {
      top: {
        '@lg': {
          paddingTop: '$24',
          borderTop: '1px solid',
        },
      },
      left: {
        paddingLeft: '$24',
        borderLeft: '1px solid',
      },
      none: {
        border: 'none',
      },
    },
  },
})
const Title = styled('h1', {
  $aetherFont: 'heading01',
  maxWidth: 800,
})
const TopWrap = styled('div', {
  display: 'grid',
  justifyItems: 'start',
  gridGap: '$24',
})
const BottomText = styled('div', {
  display: 'none',
  $aetherFont: 'ui06',
  alignSelf: 'end',
  '@lg': {
    display: 'grid',
  },
})
export const SectionHero: FC<SectionHeroProps> = ({
  title,
  background,
  ctaLabel,
  ctaLink,
  caption,
  heroTextTheme,
  borderPosition = 'left',
}) => {
  return (
    <SectionWrapper withBackground>
      <Wrap heroTextTheme={heroTextTheme}>
        <MediaWrap>
          {background && (
            <Media
              {...background}
              layout="fill"
              priority
              sizes={{ default: '100vw' }}
              withBackground
            />
          )}
        </MediaWrap>
        {(title || ctaLink || caption) && (
          <StyledContainer>
            <ContentWrap borderPosition={borderPosition}>
              <TopWrap>
                <Title>{title}</Title>
                {ctaLink && (
                  <Link {...ctaLink} appearance="primary">
                    {ctaLabel}
                  </Link>
                )}
              </TopWrap>
              <BottomText>
                {caption && <PortableText value={caption} withStyles={false} />}
              </BottomText>
            </ContentWrap>
          </StyledContainer>
        )}
      </Wrap>
    </SectionWrapper>
  )
}
