import { FC, useEffect, useRef } from 'react'
import { SidebarProps } from '@aether/models'
import { styled, useMediaQuery } from '@aether/styles'
import { Container, Link } from '@aether/components'
import { useRouter } from 'next/router'
import { NavigationHeader } from './NavigationHeader'

const UtilityPageLink = styled(Link, {
  $aetherFont: 'heading06',
  gap: '$12',
  alignItems: 'center',
  $focusStyle: 'default',
})

const LinkWrap = styled(Container, {
  padding: '$48 0',
  position: 'sticky',
  top: '$48',
  bottom: '$48',
  zIndex: '$content',
  ':not(:first-child)': {
    paddingTop: '$20',
  },
  ':not(:last-child)': {
    paddingBottom: '$20',
  },
})

export const Dot = styled('div', {
  width: '$12',
  height: '$12',
  borderRadius: '$rMax',
  backgroundColor: '$black',
})

export const Sidebar: FC<SidebarProps> = ({ links }) => {
  const isLg = useMediaQuery('lg')
  const sliderRef = useRef<HTMLUListElement>(null)
  const activeSliderRef = useRef<HTMLLIElement>(null)
  const { asPath: currentPath } = useRouter()

  useEffect(() => {
    if (activeSliderRef?.current && sliderRef.current) {
      const activeSlideEl = activeSliderRef.current
      const sliderEl = sliderRef.current
      const { width } = activeSlideEl.getBoundingClientRect()
      const { offsetLeft } = activeSlideEl
      sliderEl.scroll({
        top: 0,
        left: offsetLeft - width,
      })
    }
  }, [activeSliderRef, sliderRef])

  return (
    <div>
      {isLg ? (
        <LinkWrap>
          {links.map((link, index) => (
            <UtilityPageLink
              key={`${link.path}-${index}`}
              appearance="flex"
              href={link.path}
            >
              {link.path === currentPath && <Dot />}
              {link.title}
            </UtilityPageLink>
          ))}
        </LinkWrap>
      ) : (
        <NavigationHeader links={links} />
      )}
    </div>
  )
}
