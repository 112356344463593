import { FC } from 'react'
import { captureException } from '@sentry/nextjs'
import { useTranslation } from 'react-i18next'
import { CloseCircle } from '@aether/icons'

import { ShopifyProductsData, SiteConfiguration } from '@aether/models'
import {
  AccordionHeaderDefault,
  AccordionItem,
  AccordionItemsList,
  Button,
  Loader,
} from '@aether/components'
import { styled } from '@aether/styles'

import { useCollectionSearchParams } from './useCollectionSearchParams'
import { ColorGroupFilter } from './filters/ColorGroupFilter'
import { TechFeaturesFilter } from './filters/TechFeaturesFilter'
import { TemperatureFilter } from './filters/TemperatureFilter'
import { MetaFieldFilter } from './filters/MetaFieldFilter'
import { PriceFilter } from './filters/PriceFilter'
import { SizeFilter } from './filters/SizeFilter'
import { SortFilter } from './filters/SortFilter'
import Div100vh from 'react-div-100vh'
import { DEFAULT_LOCALE_ID } from '@aether/configs'
import { useRouter } from 'next/router'
import { useCustomerContext } from '@aether/account/utils-customer-context'
import {
  useCollectionProducts,
  FILTERS_GROUPS_MAP,
  getFiltersArray,
} from '@aether/collection'

const FilterIndicator = styled('div', {
  borderRadius: '$rMax',
  overflow: 'hidden',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  $aetherFont: 'ui02',
  variants: {
    color: {
      black: {
        background: '$black',
        color: '$white',
        width: '$24',
        height: '$24',
      },
      white: {
        background: '$white',
        color: '$black',
        width: '$20',
        height: '$20',
      },
    },
  },
})

const CloseButton = styled('button', {
  background: 'none',
  justifySelf: 'end',
  margin: 0,
  padding: 0,
  $focusStyle: 'default',
})

const AccordionWrap = styled('div', {
  overflow: 'scroll',
  height: '100%',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
})

const TitleWrap = styled('div', {
  $aetherFont: 'heading03',
  paddingTop: '$32',
  paddingBottom: '$32',
  borderTop: '1px solid',
  display: 'flex',
  justifyContent: 'space-between',
})

const FooterWrap = styled('div', {
  background: '$white',
  display: 'grid',
  padding: '$24 0',
  borderTop: '1px solid',
  gridTemplateColumns: '1fr 1fr',
  gridGap: '$12',
})
const ClearAll = styled(Button, {
  display: 'inline-flex',
  $aetherFont: 'ui02',
  lineHeight: '22px',
  borderRadius: '$r1',
  height: '$40',
  border: '1px solid',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: '$black',
    color: '$white',
  },

  '&:disabled': {
    backgroundColor: '$gray_light',
    border: 'none',
    color: '$gray_medium',
    cursor: 'default',
  },
})

const ShowResults = styled(Button, {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  $aetherFont: 'ui02',
  lineHeight: '22px',
  borderRadius: '$r1',
  height: '$40',
  border: '1px solid',
  backgroundColor: '$black',
  color: '$white',

  '&:disabled': {
    backgroundColor: '$gray_light',
    border: 'none',
    color: '$gray_medium',
    cursor: 'default',
  },
})

const FilterGroupNameContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  gap: '$12',
})

const visibleFilters: Array<keyof typeof FILTERS_GROUPS_MAP> = [
  'sort',
  'color_group',
  'tech_features',
  'temperature',
  'type_of_product',
  'size',
  'price',
  'activity',
]

const StyledDiv100vh = styled(Div100vh, {
  padding: '$24 $24 0 $24',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  overflow: 'hidden',
  position: 'relative',
  '@sm': {
    width: '430px',
  },
})

type Props = {
  onClose: () => void
  applyFilters: (filtersQuery: URLSearchParams) => void
  clearFilters: () => void
  siteConfiguration?: SiteConfiguration
  shopifyData?: ShopifyProductsData
}

export const FiltersPicker: FC<Props> = ({
  onClose,
  siteConfiguration,
  shopifyData,
  applyFilters,
  clearFilters,
}) => {
  const { t } = useTranslation('collection')
  const { userRole } = useCustomerContext()
  const isVip = userRole === 'vip'
  const handle = shopifyData?.collection?.handle || ''
  const { locale = DEFAULT_LOCALE_ID } = useRouter()

  const [newSearchParams, updateNewSearchParams] = useCollectionSearchParams()

  const { isFetching, filters, resolvedFilters } = useCollectionProducts({
    locale,
    handle,
    searchParams: newSearchParams,
  })

  // count items based on VIP filter. do not include vip products if user is not vip
  const filteredProductsAmount = filters
    .find((f) => f.id === 'filter.p.m.product.vip_product')
    ?.values.filter((val) =>
      isVip ? val : val.label === 'false' || val.label === 'No',
    )
    ?.reduce((acc, val) => acc + val.count, 0)

  const handleApplyFilters = () => {
    applyFilters(newSearchParams)
  }

  const handleClearFilters = () => {
    newSearchParams.delete('filters')
    newSearchParams.delete('sort')
    clearFilters()
  }

  const activeFiltersAmount = getFiltersArray(
    newSearchParams.getAll('filters'),
  ).length
  const activeSortAmount = newSearchParams.getAll('sort').length

  return (
    <StyledDiv100vh>
      <TitleWrap>
        <h3>{t('filter')}</h3>
        <CloseButton onClick={onClose} aria-label="Close">
          <CloseCircle />
        </CloseButton>
      </TitleWrap>
      <AccordionWrap>
        {filters.length ? (
          <AccordionItemsList
            allowMultipleActiveItems={true}
            accordionId={'filterProduct'}
          >
            {(handleRequestOpen, handleRequestClose, isActive, accordionId) => {
              return visibleFilters.map((filterGroupName, index) => {
                const itemId = `${accordionId}-${index}`

                const { searchParamName } = FILTERS_GROUPS_MAP[filterGroupName]
                const selectedFiltersAmount =
                  getFiltersArray(
                    newSearchParams.getAll(searchParamName),
                  )?.filter((f) => f.startsWith(filterGroupName)).length || 0

                const filterValues = resolvedFilters.filter(
                  (v) => v.filterName === filterGroupName,
                )

                return (
                  <AccordionItem
                    key={`${filterGroupName}-${itemId}`}
                    headerType="default"
                    isActive={isActive(itemId)}
                    itemId={itemId}
                    onRequestOpen={handleRequestOpen}
                    onRequestClose={handleRequestClose}
                    label={filterGroupName}
                    headerComponent={({ isActive }) => (
                      <AccordionHeaderDefault isActive={isActive}>
                        <FilterGroupNameContainer>
                          <h5>{t(filterGroupName)}</h5>
                          {selectedFiltersAmount > 0 && (
                            <FilterIndicator color="black">
                              {selectedFiltersAmount}
                            </FilterIndicator>
                          )}
                        </FilterGroupNameContainer>
                      </AccordionHeaderDefault>
                    )}
                  >
                    {(() => {
                      switch (filterGroupName) {
                        case 'color_group':
                          return (
                            <ColorGroupFilter
                              selectedFilters={getFiltersArray(
                                newSearchParams.getAll(searchParamName),
                              )}
                              onFilterChange={updateNewSearchParams}
                              siteConfiguration={siteConfiguration}
                              filters={filterValues}
                            />
                          )
                        case 'tech_features':
                          return (
                            <TechFeaturesFilter
                              selectedFilters={getFiltersArray(
                                newSearchParams.getAll(searchParamName),
                              )}
                              onFilterChange={updateNewSearchParams}
                              siteConfiguration={siteConfiguration}
                              filters={filterValues}
                            />
                          )
                        case 'temperature':
                          return (
                            <TemperatureFilter
                              onFilterChange={updateNewSearchParams}
                              siteConfiguration={siteConfiguration}
                              filters={filterValues}
                              selectedFilters={getFiltersArray(
                                newSearchParams.getAll(searchParamName),
                              )}
                            />
                          )
                        case 'price':
                          return (
                            <PriceFilter
                              onFilterChange={updateNewSearchParams}
                              filters={filterValues}
                              selectedFilters={getFiltersArray(
                                newSearchParams.getAll(searchParamName),
                              )}
                            />
                          )
                        case 'size':
                          return (
                            <SizeFilter
                              onFilterChange={updateNewSearchParams}
                              filters={filterValues}
                              selectedFilters={getFiltersArray(
                                newSearchParams.getAll(searchParamName),
                              )}
                            />
                          )
                        case 'sort':
                          return (
                            <SortFilter
                              filtersQuery={newSearchParams}
                              onFilterChange={updateNewSearchParams}
                              selectedFilters={newSearchParams.getAll(
                                searchParamName,
                              )}
                              queryKey={searchParamName}
                            />
                          )
                        case 'activity':
                          return (
                            <MetaFieldFilter
                              onFilterChange={updateNewSearchParams}
                              filters={filterValues}
                              selectedFilters={getFiltersArray(
                                newSearchParams.getAll(searchParamName),
                              )}
                            />
                          )
                        case 'type_of_product':
                          return (
                            <MetaFieldFilter
                              onFilterChange={updateNewSearchParams}
                              filters={filterValues}
                              selectedFilters={getFiltersArray(
                                newSearchParams.getAll(searchParamName),
                              )}
                            />
                          )
                        default:
                          captureException(
                            new Error(
                              `filter "${filterGroupName}" is not handled in Filters component`,
                            ),
                          )
                          return null
                      }
                    })()}
                  </AccordionItem>
                )
              })
            }}
          </AccordionItemsList>
        ) : (
          <Loader />
        )}
      </AccordionWrap>
      <FooterWrap>
        <ClearAll
          appearance="block"
          disabled={activeFiltersAmount === 0 && activeSortAmount === 0}
          onClick={handleClearFilters}
        >
          {t('clearAll')}
        </ClearAll>

        <ShowResults
          appearance="block"
          onClick={handleApplyFilters}
          disabled={filteredProductsAmount === 0 || isFetching}
        >
          {isFetching ? (
            <Loader />
          ) : (
            t('showResults', {
              value: filteredProductsAmount,
            })
          )}
        </ShowResults>
      </FooterWrap>
    </StyledDiv100vh>
  )
}
