import { FC } from 'react'
import { SectionVideoPlayerProps } from '@aether/models'
import { styled, useMediaQuery } from '@aether/styles'
import {
  Container,
  Link,
  SectionWrapper,
  YouTubePlayer,
} from '@aether/components'
import MuxPlayer from '@mux/mux-player-react'
import { getYouTubeIdFromUrl } from '@aether/utils'

const StyledContainer = styled(Container, {
  display: 'grid',
  gridGap: '$24',
  width: '100%',
  height: '100%',
  gridTemplateAreas: `
                      'top'
                      'bottom'
                      `,
})
const VideoWrap = styled('div', {
  variants: {
    imagePosition: {
      top: {
        gridArea: 'top',
      },
      bottom: {
        gridArea: 'top',
        '@lg': {
          gridArea: 'bottom',
        },
      },
    },
  },
})
const Title = styled('h2', {
  $aetherFont: 'heading02',
  marginBottom: '$16',
})

const Caption = styled('p', {
  $aetherFont: 'body02',
  marginBottom: '$16',

  '@lg': {
    width: '70%',
  },
})
const ColumnWithText = styled('div', {
  gap: '$24',
  paddingLeft: '$12',
  borderLeft: '1px solid $black',
  '@lg': {
    paddingLeft: 0,
    borderLeft: 'none',
  },
  variants: {
    videoPosition: {
      top: {
        gridArea: 'bottom',
      },
      bottom: {
        gridArea: 'bottom',

        '@lg': {
          gridArea: 'top',
        },
      },
    },
  },
})

const StyledMuxPlayer = styled(MuxPlayer, {
  '--seek-backward-button': 'none',
  '--seek-forward-button': 'none',
  '--pip-button': 'none',
  '--playback-rate-button': 'none',
  '--controls-backdrop-color': 'rgb(0 0 0 / 45%)',
})

export const SectionVideoPlayer: FC<SectionVideoPlayerProps> = ({
  title,
  caption,
  video,
  alt,
  sectionSpaceSize,
  videoPosition,
  ctaLabel,
  ctaLink,
  videoYT,
  videoType,
}) => {
  const matchesMd = useMediaQuery('md')
  const aspectRatio =
    video?.width && video?.height ? video.width / video.height : null

  return (
    <SectionWrapper>
      <StyledContainer spaceSize={matchesMd ? sectionSpaceSize : 'small'}>
        <VideoWrap imagePosition={videoPosition}>
          {video && video.playbackId && videoType !== 'youtubeVideo' && (
            <StyledMuxPlayer
              css={aspectRatio ? { 'aspect-ratio': aspectRatio } : {}}
              playbackId={video.playbackId}
              streamType="on-demand"
              metadataVideoTitle={alt && alt}
              poster={video.firstFrame}
            />
          )}
          {videoYT?.url && videoType == 'youtubeVideo' && (
            <YouTubePlayer
              videoId={getYouTubeIdFromUrl(videoYT?.url)}
              autoplay={false}
            />
          )}
        </VideoWrap>

        <ColumnWithText videoPosition={videoPosition}>
          {title && <Title>{title}</Title>}
          {caption && <Caption>{caption}</Caption>}
          {ctaLabel && (
            <Link {...ctaLink} appearance="primary">
              {ctaLabel}
            </Link>
          )}
        </ColumnWithText>
      </StyledContainer>
    </SectionWrapper>
  )
}
