import { FC } from 'react'
import { SectionStoreDetailsProps } from '@aether/models'
import { styled } from '@aether/styles'
import {
  Container,
  Link,
  SectionWrapper,
  PortableText,
} from '@aether/components'
import { useTranslation } from 'react-i18next'

const StyledContainer = styled(Container, {
  display: 'grid',
  rowGap: '$32',
  gridTemplateColumns: '1fr',
  '@lg': {
    gridTemplateColumns: '1fr auto',
    columnGap: '$64',
  },
  '@xl': {
    columnGap: '$168',
  },
})

const MainInfo = styled('div', {
  display: 'grid',
  gridGap: '$24',
  alignContent: 'start',
  justifyItems: 'start',
  $aetherFont: 'body01',
  '@lg': {
    minWidth: '460px',
  },
})
const Description = styled('div', {
  $aetherFont: 'body01',
})

const StyledRichText = styled(PortableText, {
  '*': {
    marginBottom: '$24',
  },
  '*:last-child': {
    marginBottom: 0,
  },
})

export const SectionStoreDetails: FC<SectionStoreDetailsProps> = ({
  store,
}) => {
  const { t } = useTranslation('stores')
  return (
    <SectionWrapper>
      <StyledContainer>
        <MainInfo>
          <StyledRichText value={store?.addressInfo} withSpacing={false} />
          <Link {...store?.addressUrl} appearance="primary">
            {t('getDirections')}
          </Link>
          <StyledRichText value={store?.additionalNotes} withSpacing={false} />
        </MainInfo>
        <Description>
          <StyledRichText value={store?.description} withSpacing={false} />
        </Description>
      </StyledContainer>
    </SectionWrapper>
  )
}
