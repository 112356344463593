import { BaseFeatureValue } from '@aether/models'
import { PerformanceFeatureBaseValue } from '../PerformanceBaseFeature/PerformanceBaseFeature'

export const getBasePerformanceFeatureValues = (
  shopifyId?: string,
  sanityBasePerformanceValues?: BaseFeatureValue[],
): PerformanceFeatureBaseValue[] => {
  if (shopifyId === undefined || !shopifyId || !sanityBasePerformanceValues) {
    return []
  }
  let found = false
  return sanityBasePerformanceValues.map((feature) => {
    if (found) {
      return {
        title: feature.title,
        subtitle: feature.subtitle,
        selected: feature.shopifyId === shopifyId,
        inRange: false,
      }
    }

    if (feature.shopifyId === shopifyId) {
      found = true
    }

    return {
      title: feature.title,
      subtitle: feature.subtitle,
      selected: feature.shopifyId === shopifyId,
      inRange: true,
    }
  })
}
