import { ShopifyProductCollectionSortKeys } from '@aether/shopify-sdk'
import { SORT_FILTERS_VALUES } from '../constants'

export const getShopifySortVariables = (
  searchParams: URLSearchParams,
): { sortKey: ShopifyProductCollectionSortKeys; reverse: boolean } => {
  const currentSortValue = searchParams.getAll('sort')[0] as
    | keyof typeof SORT_FILTERS_VALUES
    | undefined

  const sortValue = currentSortValue
    ? SORT_FILTERS_VALUES[currentSortValue]
    : undefined

  return sortValue ? sortValue : SORT_FILTERS_VALUES['sort.featured']
}
