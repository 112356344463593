import { FC } from 'react'
import { styled } from '@aether/styles'
import { AccordionTechSectionType } from '@aether/models'
import {
  AccordionHeaderDefault,
  AccordionItem,
  AccordionItemsList,
  PortableText,
} from '@aether/components'

type Props = AccordionTechSectionType & {
  className?: string
}

const AccordionWrap = styled('div', {
  marginBottom: '$72',
  borderBottom: '1px solid $black',
})

const AccordionContent = styled('div', {
  $aetherFont: 'body02',
  marginBottom: '$28',
  '@lg': {
    marginRight: '$144',
  },
  '& a': {
    textDecoration: 'underline',
  },
})

const StyledAccordionHeaderDefault = styled(AccordionHeaderDefault, {
  $aetherFont: 'heading06Caps',
  fontSize: 18,
  letterSpacing: '1.3px',
})

export const AccordionTechSection: FC<Props> = ({ className, items }) => {
  return (
    <div className={className}>
      <AccordionWrap>
        {items && items?.length > 0 && (
          <AccordionItemsList
            allowMultipleActiveItems={true}
            accordionId={'techSection'}
          >
            {(handleRequestOpen, handleRequestClose, isActive, accordionId) => {
              return items.map(
                ({ accordionHeader, accordionContent }, itemIndex) => {
                  const itemId = `${accordionId}-${itemIndex}`

                  return (
                    <AccordionItem
                      key={itemId}
                      isActive={isActive(itemId)}
                      itemId={itemId}
                      onRequestOpen={handleRequestOpen}
                      onRequestClose={handleRequestClose}
                      headerComponent={({ isActive }) => (
                        <StyledAccordionHeaderDefault isActive={isActive}>
                          {accordionHeader || ''}
                        </StyledAccordionHeaderDefault>
                      )}
                    >
                      <AccordionContent>
                        <PortableText
                          value={accordionContent}
                          fontSize="S"
                          withSpacing={true}
                        />
                      </AccordionContent>
                    </AccordionItem>
                  )
                },
              )
            }}
          </AccordionItemsList>
        )}
      </AccordionWrap>
    </div>
  )
}
