import { FC } from 'react'
import { Container, Link, SectionWrapper, MediaLink } from '@aether/components'
import { styled, useMediaQuery } from '@aether/styles'
import { SectionPictureWithCaptionProps } from '@aether/models'

const StyledContainer = styled(Container, {
  display: 'grid',
  gridGap: '$24',
  width: '100%',
  height: '100%',
  gridTemplateAreas: `
                      'top'
                      'bottom'
                      `,
})
const MediaWrap = styled('div', {
  variants: {
    imagePosition: {
      top: {
        gridArea: 'top',
      },
      bottom: {
        gridArea: 'top',
        '@lg': {
          gridArea: 'bottom',
        },
      },
    },
  },
})
const Title = styled('h2', {
  $aetherFont: 'heading02',
  marginBottom: '$16',
})

const Caption = styled('p', {
  $aetherFont: 'body02',
  marginBottom: '$16',

  '@lg': {
    width: '70%',
  },
})
const ColumnWithText = styled('div', {
  gap: '$24',
  paddingLeft: '$12',
  borderLeft: '1px solid $black',
  '@lg': {
    paddingLeft: 0,
    borderLeft: 'none',
  },
  variants: {
    imagePosition: {
      top: {
        gridArea: 'bottom',
      },
      bottom: {
        gridArea: 'bottom',

        '@lg': {
          gridArea: 'top',
        },
      },
    },
  },
})
export const SectionPictureWithCaption: FC<SectionPictureWithCaptionProps> = ({
  title,
  caption,
  image,
  sectionSpaceSize,
  imagePosition,
  ctaLabel,
  ctaLink,
}) => {
  const matchesMd = useMediaQuery('md')
  return (
    <SectionWrapper>
      <StyledContainer spaceSize={matchesMd ? sectionSpaceSize : 'small'}>
        <MediaWrap imagePosition={imagePosition}>
          {image && (
            <MediaLink
              media={{
                ...image,
                hardcropRatio: matchesMd ? 'landscape1' : 'portrait1',
                sizes: { default: '100vw' },
              }}
              link={ctaLabel && ctaLink ? ctaLink : undefined}
            />
          )}
        </MediaWrap>
        <ColumnWithText imagePosition={imagePosition}>
          {title && <Title>{title}</Title>}
          {caption && <Caption>{caption}</Caption>}
          {ctaLabel && (
            <Link {...ctaLink} appearance="primary">
              {ctaLabel}
            </Link>
          )}
        </ColumnWithText>
      </StyledContainer>
    </SectionWrapper>
  )
}
