import { FC } from 'react'
import { SectionAccordionGroupsProps } from '@aether/models'
import {
  AccordionItem,
  AccordionItemsList,
  Container,
  SectionWrapper,
  PortableText,
} from '@aether/components'
import { styled } from '@aether/styles'

const GroupWrap = styled('div', {
  marginBottom: '$72',
  borderBottom: '1px solid',
})
const GroupTitle = styled('h3', {
  $aetherFont: 'heading03',
  marginBottom: '$28',
})
const AccordionContent = styled('div', {
  marginBottom: '$28',
  'p, ul, ol': {
    padding: 0,
  },
})

export const SectionAccordionGroups: FC<SectionAccordionGroupsProps> = ({
  groups,
}) => {
  return (
    <SectionWrapper>
      <Container>
        {groups &&
          groups.length > 0 &&
          groups.map((group, index) => (
            <GroupWrap key={`${group.title}-${index}`}>
              <GroupTitle>{group.title}</GroupTitle>
              {group.accordions &&
                group.accordions?.length > 0 &&
                group._key && (
                  <AccordionItemsList
                    allowMultipleActiveItems={true}
                    accordionId={group._key}
                  >
                    {(
                      handleRequestOpen,
                      handleRequestClose,
                      isActive,
                      accordionId,
                    ) => {
                      return group.accordions.map(
                        ({ accordionHeader, accordionContent }, itemIndex) => {
                          const itemId = `${accordionId}-${itemIndex}`
                          return (
                            <AccordionItem
                              key={itemId}
                              headerType="default"
                              isActive={isActive(itemId)}
                              label={accordionHeader || ''}
                              itemId={itemId}
                              onRequestOpen={handleRequestOpen}
                              onRequestClose={handleRequestClose}
                            >
                              <AccordionContent>
                                <PortableText
                                  value={accordionContent}
                                  withSpacing={false}
                                />
                              </AccordionContent>
                            </AccordionItem>
                          )
                        },
                      )
                    }}
                  </AccordionItemsList>
                )}
            </GroupWrap>
          ))}
      </Container>
    </SectionWrapper>
  )
}
