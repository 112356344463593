import { ShopifyProduct, SiteConfiguration } from '@aether/models'
import { styled } from '@aether/styles'
import { getBasePerformanceFeatureValues } from '../SectionProductConversionArea/helpers/getBasePerformanceFeatureValues'
import { getTemperaturePerformanceValues } from '../SectionProductConversionArea/helpers/getTemperaturePerformanceValues'
import { PerformanceBaseFeature } from './PerformanceFeature/PerformanceBaseFeature'
import { PerformanceTemperatureFeature } from './PerformanceFeature/PerformanceTemperatureFeature'
import { useTranslation } from 'react-i18next'

const PerformanceWrapper = styled('div', {
  borderTop: '1px solid black',
  padding: '$24 0 0',
})

const PerformanceFeaturesTitle = styled('h3', {
  $aetherFont: 'heading06Caps',
  letterSpacing: '1.3px',
})

const PerformanceFeaturesContainer = styled('div', {
  margin: '$48 0',
  display: 'grid',
  gridTemplateColumns: '1fr',
  gap: '$48',

  variants: {
    isModal: {
      true: {
        '@md': {
          gridTemplateColumns: '1fr',
          gap: '$64',
        },
        '@xl': {
          gridTemplateColumns: '1fr 1fr',
        },
      },
      false: {
        '@md': {
          gridTemplateColumns: '1fr 1fr',
        },
        '@xl': {
          gap: '80px',
        },
      },
    },
  },
})

type PerformanceFeaturesTechSectionProps = {
  productData?: ShopifyProduct | null
  performanceFeaturesMap?: SiteConfiguration['performanceFeaturesMap']
  isModal: boolean
}

export const PerformanceFeaturesTechSection = ({
  productData,
  performanceFeaturesMap,
  isModal,
}: PerformanceFeaturesTechSectionProps) => {
  const { t } = useTranslation('product')

  const insulationValues = getBasePerformanceFeatureValues(
    productData?.performanceInsulation?.value,
    performanceFeaturesMap?.performanceInsulation?.values,
  )
  const waterproofValues = getBasePerformanceFeatureValues(
    productData?.performanceWaterproof?.value,
    performanceFeaturesMap?.performanceWaterproof?.values,
  )
  const windproofValues = getBasePerformanceFeatureValues(
    productData?.performanceWindproof?.value,
    performanceFeaturesMap?.performanceWindproof?.values,
  )
  const powderRatingValues = getBasePerformanceFeatureValues(
    productData?.performancePowderRating?.value,
    performanceFeaturesMap?.performancePowderRating?.values,
  )
  const motoRatingValues = getBasePerformanceFeatureValues(
    productData?.performanceMotoRating?.value,
    performanceFeaturesMap?.performanceMotoRating?.values,
  )
  const movementRatingValues = getBasePerformanceFeatureValues(
    productData?.performanceMovementRating?.value,
    performanceFeaturesMap?.performanceMovementRating?.values,
  )
  const temperatureValues = getTemperaturePerformanceValues(
    productData?.performanceTemperatureRange?.value,
    performanceFeaturesMap?.performanceTemperature?.values,
  )

  if (
    !(
      insulationValues.length > 0 ||
      waterproofValues.length > 0 ||
      windproofValues.length > 0 ||
      powderRatingValues.length > 0 ||
      motoRatingValues.length > 0 ||
      movementRatingValues.length > 0 ||
      temperatureValues.length > 0
    )
  )
    return null

  return (
    <div>
      <PerformanceWrapper>
        <PerformanceFeaturesTitle>{t('performance')}</PerformanceFeaturesTitle>
        <PerformanceFeaturesContainer isModal={isModal}>
          <PerformanceBaseFeature
            title={performanceFeaturesMap?.performanceInsulation?.title}
            description={
              performanceFeaturesMap?.performanceInsulation?.description
            }
            values={insulationValues}
          />
          <PerformanceBaseFeature
            title={performanceFeaturesMap?.performanceWaterproof?.title}
            description={
              performanceFeaturesMap?.performanceWaterproof?.description
            }
            values={waterproofValues}
          />
          <PerformanceBaseFeature
            title={performanceFeaturesMap?.performanceWindproof?.title}
            description={
              performanceFeaturesMap?.performanceWindproof?.description
            }
            values={windproofValues}
          />
          <PerformanceBaseFeature
            title={performanceFeaturesMap?.performancePowderRating?.title}
            description={
              performanceFeaturesMap?.performancePowderRating?.description
            }
            values={powderRatingValues}
          />
          <PerformanceBaseFeature
            title={performanceFeaturesMap?.performanceMotoRating?.title}
            description={
              performanceFeaturesMap?.performanceMotoRating?.description
            }
            values={motoRatingValues}
          />
          <PerformanceBaseFeature
            title={performanceFeaturesMap?.performanceMovementRating?.title}
            description={
              performanceFeaturesMap?.performanceMovementRating?.description
            }
            values={movementRatingValues}
          />
          <PerformanceTemperatureFeature
            title={performanceFeaturesMap?.performanceTemperature?.title}
            description={
              performanceFeaturesMap?.performanceTemperature?.description
            }
            values={temperatureValues}
          />
        </PerformanceFeaturesContainer>
      </PerformanceWrapper>
    </div>
  )
}
