import { styled } from '@aether/styles'
import {
  Container,
  FullWidthImageSlider,
  ProgressIndicator,
} from '@aether/components'
import { FC, useState, useEffect, useRef } from 'react'
import { ShopifyImageMediaPayload } from '@aether/models'
import { Close } from '@aether/icons'

const scaleFactor = 2.5

const Root = styled('div', {
  display: 'grid',
  gridAutoRows: 'auto',
  rowGap: '$24',
  zIndex: 100,
  position: 'relative',
  variants: {
    fixed: {
      true: {
        position: 'fixed',
        top: 0,
        height: '100dvh',
        background: 'white',
        width: '100%',
      },
    },
  },
})

const FocusButton = styled('button', {
  height: 40,
  width: 40,
  borderRadius: 20,
  backgroundColor: '$white',
  opacity: '0.8',
  position: 'absolute',
  top: 20,
  right: 20,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  transition: 'transform .2s ease-in-out',
})

const ImageSliderWrapper = styled('div', {
  variants: {
    isInset: {
      true: {
        position: 'absolute',
        bottom: '20px',
        width: '100%',
      },
    },
  },
})

const SliderWrapper = styled('div', {
  display: 'flex',
  alignItems: 'center',
})

const ZoomWrapper = styled('div', {
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  variants: {
    isZoomed: {
      true: {
        alignItems: 'flex-start',
        overflow: 'scroll',
        '> *': {
          transform: `scale(${scaleFactor})`,
          transformOrigin: 'top left',
        },
      },
    },
  },
})

type Props = {
  images: ShopifyImageMediaPayload[]
  className?: string
  priority?: boolean
  activeImageId: number
  setActiveImage: (index: number) => void
}

export const ProductGalleryMobile: FC<Props> = ({
  images = [],
  className,
  priority,
  activeImageId = 0,
  setActiveImage,
}) => {
  const [galleryFocused, setGalleryFocused] = useState<boolean>(false)
  const [zoomActive, setZoomActive] = useState<boolean>(false)
  const wrapper = useRef<HTMLDivElement>(null)
  const wrapperParent = wrapper.current
  const wrapperChild = wrapper.current?.firstChild as HTMLElement

  useEffect(() => {
    if (galleryFocused) {
      document.body.style.overflow = 'hidden'
      document.documentElement.style.overflow = 'hidden'
    }
    return () => {
      document.body.style.overflow = 'visible'
      document.documentElement.style.overflow = 'visible'
    }
  }, [galleryFocused])

  useEffect(() => {
    if (wrapperParent && wrapperChild) {
      const childHeightScaled = wrapperChild.offsetHeight * scaleFactor
      const childWidthScaled = wrapperChild.offsetWidth * scaleFactor

      wrapperParent.scroll({
        top: (childHeightScaled - wrapperParent.offsetHeight) / 2,
        left: (childWidthScaled - wrapperParent.offsetWidth) / 2,
      })
    }
  }, [zoomActive, wrapperChild, wrapperParent])

  return (
    <Root className={className} fixed={galleryFocused}>
      <SliderWrapper onClick={() => setGalleryFocused(true)}>
        <ZoomWrapper isZoomed={zoomActive} ref={wrapper}>
          <FullWidthImageSlider
            images={images}
            onSlideChange={setActiveImage}
            priority={priority}
            isEnabled={!zoomActive}
            onClick={() => {
              if (galleryFocused) {
                setZoomActive(!zoomActive)
              }
            }}
          />
        </ZoomWrapper>
      </SliderWrapper>
      {!zoomActive && (
        <ImageSliderWrapper isInset={galleryFocused}>
          <Container spaceSize="small">
            <ProgressIndicator
              images={images}
              currentSlide={activeImageId}
              size="M"
            />
          </Container>
        </ImageSliderWrapper>
      )}

      {galleryFocused && (
        <FocusButton
          onClick={() => {
            setGalleryFocused(false)
            setZoomActive(false)
          }}
        >
          <Close />
        </FocusButton>
      )}
    </Root>
  )
}
