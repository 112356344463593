import { Link, Media, SectionWrapper } from '@aether/components'
import { SectionHalfImageProps } from '@aether/models'
import { styled, useMediaQuery } from '@aether/styles'
import { FC } from 'react'

const Wrap = styled('div', {
  '@lg': {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    $posterHeight: true,
  },
})
const BlackWrap = styled('div', {
  $containerSpace: 'small',
  background: '$black',
  color: '$white',
  paddingTop: '$24',
  paddingBottom: '$24',
  '@lg': {
    paddingTop: '$48',
    paddingBottom: '$48',
  },
})
const ImageWrap = styled('div', {
  display: 'grid',
})

const Border = styled('div', {
  height: '100%',
  paddingLeft: '$12',
  borderLeft: '1px solid',
  '@lg': {
    paddingLeft: 0,
    borderLeft: 'none',
    paddingTop: '$20',
    borderTop: '1px solid',
  },
})
const MediaWrap = styled('div', {
  gridRow: 1,
  gridColumn: 1,
})
const ImageTitle = styled('div', {
  $aetherFont: 'heading04',
  gridRow: 1,
  gridColumn: 1,
  zIndex: '$content',
  $containerSpace: 'small',
  paddingTop: '$24',
  paddingBottom: '$24',
  '@lg': {
    $aetherFont: 'heading03',
    paddingTop: '$48',
    paddingBottom: '$48',
  },
})
const SecondTitle = styled('div', {
  $aetherFont: 'heading04',
  color: '$white',
  marginBottom: '$64',
  '@lg': {
    $aetherFont: 'heading03',
    marginBottom: 0,
  },
})
const CtaGroupTitle = styled('div', {
  $aetherFont: 'heading06',
  color: '$white',
  marginBottom: '$24',
  '@lg': {
    $aetherFont: 'heading04',
  },
})

const BlackContent = styled(Border, {
  '@lg': {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    gap: '$24',
  },
})

export const SectionHalfImage: FC<SectionHalfImageProps> = ({
  imageTitle,
  secondTitle,
  image,
  ctaGroupTitle,
  links,
}) => {
  const matchesLg = useMediaQuery('lg')
  return (
    <SectionWrapper withBackground>
      <Wrap>
        <ImageWrap>
          <ImageTitle>
            <Border>{imageTitle}</Border>
          </ImageTitle>
          <MediaWrap>
            <Media
              {...image}
              hardcropRatio="portrait1"
              layout={matchesLg ? 'fill' : 'responsive'}
              sizes={{ lg: '50vw' }}
              withBackground
            />
          </MediaWrap>
        </ImageWrap>
        <BlackWrap>
          <BlackContent>
            <SecondTitle>{secondTitle}</SecondTitle>
            <div>
              <CtaGroupTitle>{ctaGroupTitle}</CtaGroupTitle>
              <div>
                {links?.map((link) => (
                  <Link
                    {...link.ctaLink}
                    key={link.ctaLabel}
                    appearance="primary"
                  >
                    {link.ctaLabel}
                  </Link>
                ))}
              </div>
            </div>
          </BlackContent>
        </BlackWrap>
      </Wrap>
    </SectionWrapper>
  )
}
