import { styled } from '@aether/styles'
import { useEffect } from 'react'
import { renderKlaviyoReviewsWidgets } from './renderKlaviyoReviewsWidgets'
import { KlaviyoRatingStar } from '@aether/icons'

function StarsRating({
  productId,
  productTitle,
}: {
  productId: string
  productTitle: string
}) {
  useEffect(() => {
    renderKlaviyoReviewsWidgets()
  }, [])

  return (
    <Wrapper>
      <StyledDummyStarsWrapper>
        <KlaviyoRatingStar />
        <KlaviyoRatingStar />
        <KlaviyoRatingStar />
        <KlaviyoRatingStar />
        <KlaviyoRatingStar />
      </StyledDummyStarsWrapper>
      <KlaviyoStarRatingWidgetWrapper id="aether-klaviyo-star-rating-widget-wrapper">
        <div
          className="klaviyo-star-rating-widget"
          data-id={productId.replace('gid://shopify/Product/', '')}
          data-product-title={productTitle}
        />
      </KlaviyoStarRatingWidgetWrapper>
    </Wrapper>
  )
}

export { StarsRating }

const StyledDummyStarsWrapper = styled('div', {
  position: 'absolute',
})
const Wrapper = styled('div', { display: 'flex', height: 20 })

const KlaviyoStarRatingWidgetWrapper = styled('div', {
  zIndex: 1,
  '&#aether-klaviyo-star-rating-widget-wrapper': {
    display: 'flex',
    minWidth: 100,
    minHeight: 20,

    '.klaviyo-star-rating-widget': {
      display: 'flex',

      '.kl_reviews__star_rating_widget': {
        cursor: 'default',
        pointerEvents: 'none',
      },

      '.kl_reviews__stars_badge': {
        padding: 0,
      },
      '.kl_reviews__star_rating_widget__label': {
        display: 'none',
      },
    },
  },
})
