import { Media } from '@aether/components'
import { ShopifyImageMediaPayload } from '@aether/models'
import { styled } from '@aether/styles'

const THUMBNAIL_TRANSITION_MS = 250
export const THUMBNAIL_PADDING_LEFT = 14
export const THUMBNAIL_WIDTH = 80

const ThumbnailContainer = styled('div', {
  margin: 0,
  paddingLeft: THUMBNAIL_PADDING_LEFT,
  boxSizing: 'content-box',
  position: 'relative',
  transition: `opacity ${THUMBNAIL_TRANSITION_MS}ms`,
  '&:before': {
    content: '',
    left: '0px',
    height: '100%',
    width: '1px',
    position: 'absolute',
    transition: `background-color ${THUMBNAIL_TRANSITION_MS}ms`,
    backgroundColor: 'transparent',
  },
  variants: {
    active: {
      false: {
        opacity: 0.5,
        '&:before': {
          backgroundColor: 'transparent',
        },
      },
      true: {
        '&:before': {
          backgroundColor: '$black',
        },
      },
    },
  },
})

const HiddenRadio = styled('input', {
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  border: 'transparent',
  margin: 0,
  background: 'transparent',
  '-webkit-appearance': 'none',
  '&:focus-visible': {
    outline: '$blue solid 3px',
    outlineOffset: '2px',
  },
})

const Label = styled('label', {
  display: 'block',
  position: 'relative',
})

interface GalleryThumbnailProps {
  image: ShopifyImageMediaPayload
  name: string
  checked: boolean
  index: number
  onSelected: (index: number) => void
  priority: boolean
}

export const GalleryThumbnail = ({
  image,
  name,
  checked,
  index,
  onSelected,
  priority,
}: GalleryThumbnailProps) => {
  return (
    <ThumbnailContainer
      onMouseEnter={() => {
        onSelected(index)
      }}
      active={checked}
    >
      <Label htmlFor={`${name}-${index}`}>
        <HiddenRadio
          type="radio"
          name={name}
          checked={checked}
          aria-label={`show bigger ${image.image.alt || 'image ' + index}`}
          onChange={() => {
            onSelected(index)
          }}
          id={`${name}-${index}`}
        />
        <Media
          mediaPayload={{
            ...image,
            image: { ...image.image, width: THUMBNAIL_WIDTH },
          }}
          priority={priority && index === 0}
          hardcropRatio="portrait1"
          sizes={`${THUMBNAIL_WIDTH}px`}
          withBackground
        />
      </Label>
    </ThumbnailContainer>
  )
}
