import { ShopifyProduct, ShopifyProductVariant } from '@aether/models'
import { createItem } from './createItem'
import { GTMResetEcommerce } from './gtmResetEcommerce'

export const GTMViewItem = (
  product?: ShopifyProduct,
  productVariant?: ShopifyProductVariant,
) => {
  if (!product || !productVariant) {
    return
  }

  GTMResetEcommerce()

  const w = window as any
  w.dataLayer = w.dataLayer || []

  const data = {
    event: 'view_item',
    ecommerce: {
      items: [
        createItem({
          productTitle: product.title,
          currency: productVariant.price.currencyCode,
          index: 0,
          variantTitle: productVariant.title,
          priceAmount: productVariant.price.amount,
          quantity: 1,
          sku: productVariant.sku,
          image: product.featuredImage?.image.src,
        }),
      ],
    },
  }

  w.dataLayer.push(data)
}
