import { ShopifyCollectionFiltersFieldsFragment } from '@aether/shopify-sdk'
import { FILTERS_GROUPS_MAP, PRICE_FILTERS } from '../constants'
import { ResolvedFilter } from '../models'

export const buildResolvedFilters = (
  filters: ShopifyCollectionFiltersFieldsFragment['products']['filters'],
): ResolvedFilter[] =>
  [...filters, PRICE_FILTERS].reduce<ResolvedFilter[]>(
    (
      acc,
      filter: ShopifyCollectionFiltersFieldsFragment['products']['filters'][0],
    ) => {
      const [filterName, filterData] =
        Object.entries(FILTERS_GROUPS_MAP).find(
          (entry) => entry[1].shopifyFilterGroupId === filter.id,
        ) || []

      if (!filterData || !filterName) {
        return acc
      }

      const { shopifyFilterGroupId, searchParamName } = filterData

      const filtersValues: ResolvedFilter[] = filter.values.map(
        (filterValues) => ({
          value: filterValues.id.replace(
            filterData.shopifyFilterGroupId,
            filterName,
          ),
          shopifyFilterGroupId,
          shopifyFilterId: filterValues.id,
          shopifyInput: filterValues.input,
          filterName,
          searchParamName,
          count: filterValues.count,
          label: filterValues.label,
        }),
      )

      return [...acc, ...filtersValues]
    },
    [],
  )
