import { FC } from 'react'
import { SectionDoubleCardsWithCaptionProps } from '@aether/models'
import { styled, useMediaQuery } from '@aether/styles'
import { Container, Link, SectionWrapper, MediaLink } from '@aether/components'

const Root = styled('div', {
  position: 'relative',
  width: '100%',
})

const Title = styled('h3', {
  $aetherFont: 'heading03',
  color: '$black',
})

const Text = styled('p', {
  $aetherFont: 'body02',
})

const StyledLink = styled(Link, {
  display: 'flex',
  width: 'min-content',
})

const Card = styled('article', {
  display: 'grid',
  gap: '$16',
  height: '100%',
  width: '100%',
  '@lg': {
    gap: '$24',
  },
})

const CardsList = styled('ul', {
  display: 'grid',
  gap: '$56',
  '@lg': {
    gridArea: 'images',
    flexDirection: 'unset',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gridColumnGap: '$24',
  },
})

const TextContainer = styled('div', {
  display: 'grid',
  gridAutoRows: 'max-content',
  gap: '$12',
  padding: '$24 0 0 0',
  margin: '0 0 $24 0',
  borderTop: '1px solid $black',
  '@lg': {
    borderTop: 'none',
    borderLeft: '1px solid $black',
    width: '100%',
    gridArea: 'text',
    padding: '0 0 0 $16',
    margin: 0,
  },
  variants: {
    imagePosition: {
      left: {
        '@lg': {
          borderLeft: 'none',
          borderRight: '1px solid $black',
          padding: '0 $48 0 $28',
        },
      },
      right: {
        '@lg': {
          margin: 0,
          padding: '0 $48 0 $16',
          borderLeft: '1px solid $black',
        },
      },
    },
  },
})

const StyledContainer = styled(Container, {
  variants: {
    imagePosition: {
      left: {
        '@lg': {
          display: 'grid',
          gridTemplateColumns: '2fr 1fr',
          gridTemplateAreas: '"images text"',
        },
      },
      right: {
        '@lg': {
          display: 'grid',
          gridTemplateColumns: '1fr 2fr',
          gridTemplateAreas: '"text images"',
        },
      },
    },
  },
})

const CardListItem = styled('li', {
  height: '100%',
  '@lg': {
    flexGrow: 1,
    overflow: 'hidden',
  },
})

export const SectionDoubleCardsWithCaption: FC<
  SectionDoubleCardsWithCaptionProps
> = ({
  title,
  text,
  cards,
  sectionColor,
  sectionSpaceSize = 'small',
  imagePosition,
}) => {
  const sectionCards = cards ? cards.slice(0, 2) : []
  const isMd = useMediaQuery('md')

  return sectionCards.length === 2 ? (
    <SectionWrapper withBackground={sectionColor && sectionColor !== 'default'}>
      <Root>
        <StyledContainer
          sectionColor={sectionColor}
          spaceSize={isMd ? sectionSpaceSize : 'small'}
          // this is a stitches prop
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          imagePosition={imagePosition || 'right'}
        >
          <TextContainer
            // this is a stitches prop
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            imagePosition={imagePosition}
          >
            {title && <Title>{title}</Title>}
            {text && <Text>{text}</Text>}
          </TextContainer>
          <CardsList>
            {sectionCards.map(({ image, link, label }, index) => (
              <CardListItem key={`${label}-${index}`}>
                <Card>
                  {image && (
                    <MediaLink
                      media={{
                        ...image,
                        hardcropRatio: 'portrait1',
                        sizes: { lg: '33vw' },
                      }}
                      noIndex
                      link={label && link ? link : undefined}
                    />
                  )}
                  {label && link && (
                    <StyledLink {...link} key={label} appearance="primary">
                      {label}
                    </StyledLink>
                  )}
                </Card>
              </CardListItem>
            ))}
          </CardsList>
        </StyledContainer>
      </Root>
    </SectionWrapper>
  ) : null
}
