import { PerformanceBaseFeature } from './PerformanceBaseFeature/PerformanceBaseFeature'
import { PerformanceTemperatureFeature } from './PerfomanceTemperatureFeature/PerformanceTemperatureFeature'
import { getBasePerformanceFeatureValues } from './helpers/getBasePerformanceFeatureValues'
import { getTemperaturePerformanceValues } from './helpers/getTemperaturePerformanceValues'
import { SanityProductPerformanceType, ShopifyProduct } from '@aether/models'
import { styled } from '@aether/styles'
import { useTranslation } from 'react-i18next'

const SectionTechnicalFeatures = styled('div', {
  margin: '$28 $24 0',
  padding: '$16 0 $24',
  borderTop: '1px solid $black',
  borderBottom: '1px solid $black',

  '@sm': {
    margin: '$28 $32 0',
  },
  '@md': {
    margin: '$28 $48 0',
  },
  '@lg': {
    padding: '$16 0 $24',
    margin: '$28 0 0',
  },
})

const TechnicalFeaturesHeadingWrap = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
})

const FeaturesTitle = styled('div', {
  $aetherFont: 'heading05',
})

type TechnicalFeaturesProps = {
  productData?: ShopifyProduct
  performanceFeaturesMap?: SanityProductPerformanceType
}

export const TechnicalFeaturesComponent = ({
  productData,
  performanceFeaturesMap,
}: TechnicalFeaturesProps) => {
  const { t } = useTranslation('product')

  const insulationValues = getBasePerformanceFeatureValues(
    productData?.performanceInsulation?.value,
    performanceFeaturesMap?.performanceInsulation?.values,
  )
  const waterproofValues = getBasePerformanceFeatureValues(
    productData?.performanceWaterproof?.value,
    performanceFeaturesMap?.performanceWaterproof?.values,
  )
  const windproofValues = getBasePerformanceFeatureValues(
    productData?.performanceWindproof?.value,
    performanceFeaturesMap?.performanceWindproof?.values,
  )
  const powderRatingValues = getBasePerformanceFeatureValues(
    productData?.performancePowderRating?.value,
    performanceFeaturesMap?.performancePowderRating?.values,
  )
  const motoRatingValues = getBasePerformanceFeatureValues(
    productData?.performanceMotoRating?.value,
    performanceFeaturesMap?.performanceMotoRating?.values,
  )
  const movementRatingValues = getBasePerformanceFeatureValues(
    productData?.performanceMovementRating?.value,
    performanceFeaturesMap?.performanceMovementRating?.values,
  )
  const temperatureValues = getTemperaturePerformanceValues(
    productData?.performanceTemperatureRange?.value,
    performanceFeaturesMap?.performanceTemperature?.values,
  )

  if (
    !(
      insulationValues.length > 0 ||
      waterproofValues.length > 0 ||
      windproofValues.length > 0 ||
      powderRatingValues.length > 0 ||
      motoRatingValues.length > 0 ||
      movementRatingValues.length > 0 ||
      temperatureValues.length > 0
    )
  )
    return null

  return (
    <SectionTechnicalFeatures>
      <TechnicalFeaturesHeadingWrap>
        <FeaturesTitle>{t('performance')}</FeaturesTitle>
      </TechnicalFeaturesHeadingWrap>
      <PerformanceBaseFeature
        title={performanceFeaturesMap?.performanceInsulation?.title}
        description={performanceFeaturesMap?.performanceInsulation?.description}
        values={insulationValues}
      />
      <PerformanceBaseFeature
        title={performanceFeaturesMap?.performanceWaterproof?.title}
        description={performanceFeaturesMap?.performanceWaterproof?.description}
        values={waterproofValues}
      />
      <PerformanceBaseFeature
        title={performanceFeaturesMap?.performanceWindproof?.title}
        description={performanceFeaturesMap?.performanceWindproof?.description}
        values={windproofValues}
      />
      <PerformanceBaseFeature
        title={performanceFeaturesMap?.performancePowderRating?.title}
        description={
          performanceFeaturesMap?.performancePowderRating?.description
        }
        values={powderRatingValues}
      />
      <PerformanceBaseFeature
        title={performanceFeaturesMap?.performanceMotoRating?.title}
        description={performanceFeaturesMap?.performanceMotoRating?.description}
        values={motoRatingValues}
      />
      <PerformanceBaseFeature
        title={performanceFeaturesMap?.performanceMovementRating?.title}
        description={
          performanceFeaturesMap?.performanceMovementRating?.description
        }
        values={movementRatingValues}
      />
      <PerformanceTemperatureFeature
        title={performanceFeaturesMap?.performanceTemperature?.title}
        description={
          performanceFeaturesMap?.performanceTemperature?.description
        }
        values={temperatureValues}
      />
    </SectionTechnicalFeatures>
  )
}
