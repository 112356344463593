import { RefObject, useCallback, useEffect, useState } from 'react'
import { useScrollPosition } from '@aether/utils'

const VALUE_MAX = 100
const VALUE_MIN = 0
const ROUND_FACTOR = 10
const THRESHOLD_MS = 50

export const useProgressIndicator = (
  elementRef: RefObject<HTMLElement>,
  containerRef?: RefObject<HTMLElement>,
): number => {
  const [scrollPercent, setScrollPercent] = useState(0)
  const [scrollOffset, setScrollOffset] = useState(0)

  useEffect(() => {
    if (elementRef?.current && containerRef?.current) {
      /**
       * offsetTop of element is calculated from start of document.
       * In order to calculate the sum of all siblings above the element inside the container, we need to subtract
       * element offset and container offset
       */
      const containerOffset = containerRef?.current?.offsetTop
      const elementOffset = elementRef?.current?.offsetTop
      const scrollOffset = elementOffset - containerOffset
      setScrollOffset(scrollOffset)
    }
  }, [elementRef, containerRef])

  const handleValue = useCallback(
    (scrolled: number) => {
      const value = Math.round(scrolled * ROUND_FACTOR) / ROUND_FACTOR
      setScrollPercent(Math.max(Math.min(value, VALUE_MAX), VALUE_MIN))
    },
    [ROUND_FACTOR],
  )

  useScrollPosition(
    ({ currPos: { y } }) => {
      const containerHeight = containerRef?.current?.clientHeight || 0
      const elementHeight = elementRef?.current?.clientHeight || 0
      // we want ot rich 100% when bottom of the element meets the bottom border of the container
      const scrollableHeight = containerHeight - scrollOffset - elementHeight
      const scrollPosition = (y + scrollOffset) * -1
      const scrollPercent = (scrollPosition / scrollableHeight) * VALUE_MAX
      handleValue(scrollPercent)
    },
    [scrollOffset],
    {
      element: containerRef,
      wait: THRESHOLD_MS,
    },
  )

  return scrollPercent
}
