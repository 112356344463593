import { Link, ShopifyDescriptionHTMLWrap } from '@aether/components'
import { FC } from 'react'
import { styled } from '@aether/styles'
import { ShopifyProduct, ShopifyProductsData } from '@aether/models'
import { ProductBreadcrumbs } from './ProductBreadcrumbs'
import { ROUTE_BASE_COLLECTIONS } from '@aether/configs'
import { useTranslation } from 'react-i18next'
import { formatProductName } from '@aether/utils'

const Root = styled('div', {
  paddingBottom: '$24',
  display: 'grid',
  gridTemplateColumns: '1fr',
  gap: '$24',
  $containerSpace: 'small',
  '@lg': {
    paddingTop: '$16',
    $containerSpace: 'none',
    borderTop: '1px solid $black',
  },
})

const ProductMainInfo = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '$16',
})

const ProductTitle = styled('h1', {
  $aetherFont: 'heading03',
})

const ButtonWrap = styled('div', {
  padding: '$12 0',
  display: 'grid',
  gap: '$16',
})

const SoldOutBadge = styled('div', {
  backgroundColor: '$gray_light',
  color: '$black',
  display: 'inline-flex',
  $aetherFont: 'ui02',
  lineHeight: 1,
  borderRadius: '$r1',
  height: '$buttonHeightL',
  padding: '0 $20',
  alignItems: 'center',
  justifyContent: 'center',
  $focusStyle: 'default',
})

type Props = {
  productData?: ShopifyProduct
  shopifyData?: ShopifyProductsData
}

export const ProductSoldOut: FC<Props> = ({ productData, shopifyData }) => {
  const { t } = useTranslation('product')
  const breadcrumbs = shopifyData?.breadcrumbs || []
  const lastBreadcrumb = breadcrumbs?.[breadcrumbs.length - 1]

  return (
    <Root>
      <ProductMainInfo>
        <ProductBreadcrumbs breadcrumbs={shopifyData?.breadcrumbs} />

        <ProductTitle>
          {formatProductName(productData?.title || '')}
        </ProductTitle>

        <ButtonWrap>
          <SoldOutBadge>{t('soldOutBadge')}</SoldOutBadge>

          {lastBreadcrumb?.handle && (
            <Link
              href={`/${ROUTE_BASE_COLLECTIONS}/${lastBreadcrumb?.handle}`}
              appearance="badgeOutlineBig"
            >
              {t('explore', {
                title: lastBreadcrumb?.breadcrumbTitle?.value || '',
              })}
            </Link>
          )}
        </ButtonWrap>

        {productData?.descriptionHtml && (
          <ShopifyDescriptionHTMLWrap>
            <div
              dangerouslySetInnerHTML={{
                __html: productData.descriptionHtml,
              }}
            />
          </ShopifyDescriptionHTMLWrap>
        )}
      </ProductMainInfo>
    </Root>
  )
}
