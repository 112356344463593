import { FC } from 'react'
import { SectionScatteredGalleryProps } from '@aether/models'
import { styled, useMediaQuery } from '@aether/styles'
import {
  Media,
  PortableText,
  ProductsModalMediaWrap,
  SectionWrapper,
} from '@aether/components'

const ITEM_SPACE_SIZE: Record<string, [string, string]> = {
  SMALL: ['$24', '$48'],
  SMALL_DOUBLE: ['$48', '$96'],
  MEDIUM: ['$24', '$72'],
  MEDIUM_DOUBLE: ['$48', '$144'],
  LARGE: ['$24', '$144'],
  LARGE_DOUBLE: ['$48', '$288'],
  XLARGE: ['$24', '$192'],
  XLARGE_DOUBLE: ['$24', '$416'],
}

const Wrap = styled('div', {
  display: 'grid',
  gridTemplateColumns: '1fr',
  rowGap: '$56',
  '@lg': {
    gridTemplateColumns: '1fr 1fr',
    columnGap: '$72',
  },
  variants: {
    spaceSize: {
      none: {
        $containerSpace: 'none',
      },
      small: {
        $containerSpace: 'small',
      },
      medium: {
        $containerSpace: 'medium',
      },
      large: {
        $containerSpace: 'large',
      },
      xlarge: {
        $containerSpace: 'xlarge',
      },
    },
  },
})

const Column = styled('div', {
  display: 'grid',
  variants: {
    verticalPosition: {
      top: {
        alignContent: 'start',
      },
      center: {
        alignContent: 'center',
      },
      bottom: {
        alignContent: 'end',
      },
    },
  },
})
const ItemWrap = styled('div', {
  variants: {
    horizontalPosition: {
      left: {},
      center: {},
      right: {},
    },
    itemSpaceSize: {
      none: {},
      small: {},
      medium: {},
      large: {},
      xlarge: {},
    },
  },
  compoundVariants: [
    // SMALL
    {
      horizontalPosition: 'left',
      itemSpaceSize: 'small',
      css: {
        $containerSpace: 'small',
        '@lg': {
          $containerSpace: 'none',
          $lin: {
            marginRight: ITEM_SPACE_SIZE.SMALL_DOUBLE,
          },
        },
      },
    },
    {
      horizontalPosition: 'center',
      itemSpaceSize: 'small',
      css: {
        $lin: {
          marginLeft: ITEM_SPACE_SIZE.SMALL,
          marginRight: ITEM_SPACE_SIZE.SMALL,
        },
      },
    },
    {
      horizontalPosition: 'right',
      itemSpaceSize: 'small',
      css: {
        $containerSpace: 'small',
        '@lg': {
          $containerSpace: 'none',
          $lin: {
            marginLeft: ITEM_SPACE_SIZE.SMALL_DOUBLE,
          },
        },
      },
    },
    // MEDIUM
    {
      horizontalPosition: 'left',
      itemSpaceSize: 'medium',
      css: {
        $containerSpace: 'medium',
        '@lg': {
          $containerSpace: 'none',
          $lin: {
            marginRight: ITEM_SPACE_SIZE.MEDIUM_DOUBLE,
          },
        },
      },
    },
    {
      horizontalPosition: 'center',
      itemSpaceSize: 'medium',
      css: {
        $lin: {
          marginLeft: ITEM_SPACE_SIZE.MEDIUM,
          marginRight: ITEM_SPACE_SIZE.MEDIUM,
        },
      },
    },
    {
      horizontalPosition: 'right',
      itemSpaceSize: 'medium',
      css: {
        $containerSpace: 'medium',
        '@lg': {
          $containerSpace: 'none',
          $lin: {
            marginLeft: ITEM_SPACE_SIZE.MEDIUM_DOUBLE,
          },
        },
      },
    },
    // LARGE
    {
      horizontalPosition: 'left',
      itemSpaceSize: 'large',
      css: {
        $containerSpace: 'large',
        '@lg': {
          $containerSpace: 'none',
          $lin: {
            marginRight: ITEM_SPACE_SIZE.LARGE_DOUBLE,
          },
        },
      },
    },
    {
      horizontalPosition: 'center',
      itemSpaceSize: 'large',
      css: {
        $lin: {
          marginLeft: ITEM_SPACE_SIZE.LARGE,
          marginRight: ITEM_SPACE_SIZE.LARGE,
        },
      },
    },
    {
      horizontalPosition: 'right',
      itemSpaceSize: 'large',
      css: {
        $containerSpace: 'large',
        '@lg': {
          $containerSpace: 'none',
          $lin: {
            marginLeft: ITEM_SPACE_SIZE.LARGE_DOUBLE,
          },
        },
      },
    },
    // XLARGE
    {
      horizontalPosition: 'left',
      itemSpaceSize: 'xlarge',
      css: {
        $containerSpace: 'large',
        '@lg': {
          $containerSpace: 'none',
          $lin: {
            marginRight: ITEM_SPACE_SIZE.XLARGE_DOUBLE,
          },
        },
      },
    },
    {
      horizontalPosition: 'center',
      itemSpaceSize: 'xlarge',
      css: {
        $lin: {
          marginLeft: ITEM_SPACE_SIZE.XLARGE,
          marginRight: ITEM_SPACE_SIZE.XLARGE,
        },
      },
    },
    {
      horizontalPosition: 'right',
      itemSpaceSize: 'xlarge',
      css: {
        $containerSpace: 'large',
        '@lg': {
          $containerSpace: 'none',
          $lin: {
            marginLeft: ITEM_SPACE_SIZE.XLARGE_DOUBLE,
          },
        },
      },
    },
  ],
})
const Spacer = styled('div', {
  width: '100%',
  height: '$56',
  variants: {
    size: {
      small: {
        '@lg': {
          height: '$72',
        },
      },
      medium: {
        '@lg': {
          height: '$144',
        },
      },
      large: {
        '@lg': {
          height: '$240',
        },
      },
      none: {
        display: 'none',
      },
    },
  },
})
const Text = styled('div', {
  $aetherFont: 'heading03',
  variants: {
    borderPosition: {
      left: {
        paddingLeft: '$24',
        borderLeft: '1px solid $black',
      },
      right: {
        paddingRight: '$24',
        borderRight: '1px solid $black',
      },
      none: {},
    },
  },
})
const Caption = styled('span', {
  display: 'block',
  $aetherFont: 'body02',
  color: '$black',
  marginTop: '$16',
})

// TODO: add media sizes based on user settings
export const SectionScatteredGallery: FC<SectionScatteredGalleryProps> = ({
  sectionSpaceSize,
  leftColumn,
  rightColumn,
}) => {
  const matchesLg = useMediaQuery('lg')
  return (
    <SectionWrapper>
      <Wrap spaceSize={sectionSpaceSize}>
        <Column verticalPosition={leftColumn?.verticalPosition}>
          {leftColumn?.items?.map((item, index) => {
            if (item.type === 'spacer') {
              return (
                <Spacer
                  key={`${index}-spacer-left`}
                  size={
                    index + 1 === leftColumn.items?.length && !matchesLg
                      ? 'none'
                      : item.payload?.spacerSize
                  }
                />
              )
            }

            if (item.type === 'text') {
              return (
                <ItemWrap
                  key={`${index}-text-left`}
                  itemSpaceSize={item.payload?.itemSpaceSize}
                  horizontalPosition={item.payload?.horizontalPosition}
                >
                  <Text borderPosition={item.payload?.borderPosition}>
                    {item.payload?.text}
                  </Text>
                </ItemWrap>
              )
            }
            return (
              <ItemWrap
                key={`${index}-image-left`}
                itemSpaceSize={item.payload?.itemSpaceSize}
                horizontalPosition={item.payload?.horizontalPosition}
              >
                <ProductsModalMediaWrap
                  shopifyIds={item.payload?.shopifyIds}
                  appearance={item?.payload?.productsButtonAppearance}
                >
                  <Media {...item.payload?.image} withBackground />
                </ProductsModalMediaWrap>
                {item.payload?.imageCaption && (
                  <Caption>
                    <PortableText
                      value={item.payload.imageCaption}
                      withSpacing={false}
                      withStyles={false}
                    />
                  </Caption>
                )}
              </ItemWrap>
            )
          })}
        </Column>
        <Column verticalPosition={rightColumn?.verticalPosition}>
          {rightColumn?.items?.map((item, index) => {
            if (item.type === 'spacer') {
              return (
                <Spacer
                  key={`${index}-spacer-right`}
                  size={
                    index + 1 === rightColumn.items?.length && !matchesLg
                      ? 'none'
                      : item.payload?.spacerSize
                  }
                />
              )
            } else if (item.type === 'text') {
              return (
                <ItemWrap
                  key={`${index}-text-right`}
                  itemSpaceSize={item.payload?.itemSpaceSize}
                  horizontalPosition={item.payload?.horizontalPosition}
                >
                  <Text borderPosition={item.payload?.borderPosition}>
                    {item.payload?.text}
                  </Text>
                </ItemWrap>
              )
            }
            return (
              <ItemWrap
                key={`${index}-image-right`}
                itemSpaceSize={item.payload?.itemSpaceSize}
                horizontalPosition={item.payload?.horizontalPosition}
              >
                <ProductsModalMediaWrap
                  shopifyIds={item.payload?.shopifyIds}
                  appearance={item?.payload?.productsButtonAppearance}
                >
                  <Media {...item.payload?.image} withBackground />
                </ProductsModalMediaWrap>
                {item.payload?.imageCaption && (
                  <Caption>
                    <PortableText
                      value={item.payload?.imageCaption}
                      withSpacing={false}
                      withStyles={false}
                    />
                  </Caption>
                )}
              </ItemWrap>
            )
          })}
        </Column>
      </Wrap>
    </SectionWrapper>
  )
}
