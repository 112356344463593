import { styled } from '@aether/styles'
import { FC, Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { UpdateFiltersParamsFnType } from '../useCollectionSearchParams'
import {
  SORT_FILTERS_VALUES,
  CollectionSearchParamsNames,
} from '@aether/collection'

const Root = styled('div', {
  paddingBottom: '$48',
  display: 'grid',
  columnGap: '$12',
  rowGap: '$28',
  gridTemplateColumns: 'repeat(3, 1fr)',
})

const InputRadio = styled('input', {
  appearance: 'none',
  border: 0,
  position: 'absolute',
})

const LabelWrap = styled('label', {
  display: 'inline-flex',
  $aetherFont: 'ui02',
  borderRadius: '$r1',
  height: '$40',
  border: '1px solid',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  variants: {
    selected: {
      true: {
        backgroundColor: '$black',
        color: '$white',
      },
    },
  },
})

const HTML_ID_PREFIX = 'sort_filter'

type Props = {
  className?: string
  filtersQuery: URLSearchParams
  onFilterChange: UpdateFiltersParamsFnType
  selectedFilters: string[]
  queryKey: CollectionSearchParamsNames
}

export const SortFilter: FC<Props> = ({
  className,
  onFilterChange,
  selectedFilters,
  queryKey,
}) => {
  const { t } = useTranslation('collection')

  return (
    <Root className={className} role="radiogroup">
      {Object.keys(SORT_FILTERS_VALUES).map((value) => {
        const isSelected = selectedFilters?.includes(value)
        const HTML_ID = `${HTML_ID_PREFIX}_${value}`

        return (
          <Fragment key={value}>
            <InputRadio
              type="radio"
              id={HTML_ID}
              value={value}
              onClick={() => onFilterChange(queryKey, value, true)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  onFilterChange(queryKey, value, true)
                }
              }}
              checked={isSelected}
            />
            <LabelWrap selected={isSelected} htmlFor={HTML_ID}>
              {t(value)}
            </LabelWrap>
          </Fragment>
        )
      })}
    </Root>
  )
}
