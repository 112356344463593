import { Button } from '@aether/components'
import { styled } from '@aether/styles'
import { FC } from 'react'
import { FilterIcon } from '@aether/icons'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'next/navigation'
import { getFiltersArray } from '@aether/collection'

const StyledButton = styled(Button, {
  cursor: 'pointer',
  borderRadius: '$r1',

  $focusStyle: 'default',
  variants: {
    theme: {
      dark: {
        backgroundColor: '$black',
        color: '$white',
        transition: 'opacity ease-in 200ms',

        '&:hover': {
          opacity: 0.8,
        },
      },
      light: {
        backgroundColor: '$gray_light',
        color: '$black',
        transition: 'background ease-in 200ms',

        '&:hover': {
          background: '$gray_light_fourth',
        },
      },
    },
  },
})

const FilterIndicator = styled('div', {
  borderRadius: '$rMax',
  overflow: 'hidden',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  $aetherFont: 'ui02',
  background: '$white',
  color: '$black',
  width: '15px',
  height: '15px',
  variants: {
    theme: {
      light: {
        backgroundColor: '$black',
        color: '$white',
      },
      dark: {
        backgroundColor: '$gray_light',
        color: '$black',
      },
    },
  },
})

const IconWithLabel = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '$8 $16',
  width: '100%',
  height: '100%',
  $aetherFont: 'ui02',
  ':not(:first-child)': {
    marginLeft: '$12',
  },
})

type Props = {
  openFilters: () => void
  className?: string
  theme?: 'dark' | 'light'
}

export const FiltersButton: FC<Props> = ({
  openFilters,
  className,
  theme = 'light',
}) => {
  const { t } = useTranslation('collection')
  const searchParams = useSearchParams()

  const filtersAmount = getFiltersArray(searchParams.getAll('filters')).length

  const isFilterActive = Object.keys(searchParams || {}).length > 0

  const computedTheme: 'dark' | 'light' = (() => {
    if (isFilterActive) {
      return 'dark'
    }

    return theme
  })()

  return (
    <div className={className}>
      <StyledButton
        onClick={openFilters}
        appearance="flex"
        theme={computedTheme}
      >
        <IconWithLabel>
          <FilterIcon
            height={15}
            color={computedTheme === 'dark' ? 'white' : 'black'}
          />
          <span>{t('filter')}</span>
          {isFilterActive && filtersAmount > 0 && (
            <FilterIndicator theme={computedTheme}>
              {filtersAmount}
            </FilterIndicator>
          )}
        </IconWithLabel>
      </StyledButton>
    </div>
  )
}
