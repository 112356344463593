import { useEffect, useMemo } from 'react'
import { useCartContext } from '@aether/cart'
import { ShopifyProduct } from '@aether/models'
import { ROUTE_BASE_PRODUCTS } from '@aether/configs'
import { ShopifyResolvedListProduct } from '@aether/shopify-sdk'
import { CustomerResponseType } from '@aether/account/data-access'
import { useCustomerContext } from '@aether/account/utils-customer-context'

type WunderkindConfigType = {
  product?: {
    exclusion?: boolean
    id?: string
    sku?: string
    productName?: string
    url?: string
    imgUrl?: string
    price?: number
    inStock?: boolean
    itemCategory?: string
  }
  category?: {
    categoryIds?: string[]
    title?: string
  }
  cart?: {
    quantity?: number
    value?: number
  }
  user?: {
    email?: string
  }
}

type UseWunderkindProps = {
  pageType: 'product' | 'category' | 'other'
  productData?: ShopifyProduct
  collectionData?: {
    products?: ShopifyResolvedListProduct[]
    title?: string
  }
  userData?: CustomerResponseType | null
}

declare global {
  interface Window {
    wunderkind: WunderkindConfigType
  }
}

export const useWunderkind = ({
  pageType,
  productData,
  collectionData,
}: UseWunderkindProps): void => {
  const cartData = useCartContext()
  const userData = useCustomerContext()
  const BASE_URL = 'https://aetherapparel.com'

  const cart = useMemo(
    () => ({
      value: Number(cartData?.cart?.cost?.totalAmount?.amount) || 0,
      quantity: cartData?.quantity || 0,
    }),
    [cartData],
  )

  // return encoded e-mail if exists
  const user = useMemo(() => {
    const email = userData?.customer?.email
    return email
      ? {
          user: {
            email: btoa(String(email)),
          },
        }
      : {}
  }, [userData])

  const config = useMemo(() => {
    if (!pageType) return { ...user }

    switch (pageType) {
      case 'product':
        return {
          product: {
            id: productData?.id,
            productName: productData?.title,
            url: `${BASE_URL}${ROUTE_BASE_PRODUCTS}/${productData?.handle}`,
            imgUrl:
              productData?.featuredImage?.image?.src ||
              productData?.images[0]?.image?.src,
            price: Number(productData?.priceRange.maxVariantPrice.amount),
            inStock: productData?.availableForSale,
            itemCategories: productData?.collections.edges.map(
              (col) => col.node.title,
            ),
          },
          cart,
          ...user,
        }
      case 'category':
        return {
          category: {
            categoryIds: collectionData?.products?.map((product) => product.id),
            title: collectionData?.title,
          },
          cart,
          ...user,
        }
      case 'other':
      default:
        return { ...user }
    }
  }, [pageType, productData, collectionData, cart, user])

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.wunderkind = config
    }
  }, [config])
}
