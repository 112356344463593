import React, { useCallback, useEffect, useState } from 'react'
import { styled } from '@aether/styles'
import { Media } from '@aether/components'
import { ShopifyImageMediaPayload } from '@aether/models'

const scaleFactor = 2.5

const Wrapper = styled('div', {
  position: 'relative',
  variants: {
    active: {
      true: {
        display: 'block',
      },
      false: {
        display: 'none',
      },
    },
    hasZoomActive: {
      true: {
        cursor:
          "url(\"data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M19.47 18.44L15.05 14.02C16.3 12.53 17.06 10.62 17.06 8.53C17.06 3.83 13.24 0 8.53 0C3.82 0 0 3.83 0 8.53C0 13.23 3.83 17.06 8.53 17.06C10.78 17.06 12.83 16.18 14.36 14.74L18.76 19.14C18.86 19.24 18.99 19.29 19.11 19.29C19.23 19.29 19.37 19.24 19.46 19.14C19.66 18.94 19.66 18.63 19.46 18.43L19.47 18.44ZM8.53 16.06C4.38 16.06 1 12.68 1 8.53C1 4.38 4.38 1 8.53 1C12.68 1 16.06 4.38 16.06 8.53C16.06 12.68 12.68 16.06 8.53 16.06Z' fill='white'/%3E%3Cpath d='M4.12 8.03C3.84 8.03 3.62 8.25 3.62 8.53C3.62 8.81 3.84 9.03 4.12 9.03H12.95C13.23 9.03 13.45 8.81 13.45 8.53C13.45 8.25 13.23 8.03 12.95 8.03H4.12Z' fill='white'/%3E%3C/svg%3E%0A\"), zoom-in",
      },
      false: {
        cursor:
          "url(\"data:image/svg+xml, %3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none' %3E%3Cpath d='M19.47 18.44L15.05 14.02C16.3 12.53 17.06 10.62 17.06 8.53C17.06 3.83 13.24 0 8.53 0C3.82 0 0 3.83 0 8.53C0 13.23 3.83 17.06 8.53 17.06C10.78 17.06 12.83 16.18 14.36 14.74L18.77 19.14C18.87 19.24 19 19.29 19.12 19.29C19.24 19.29 19.38 19.24 19.47 19.14C19.67 18.94 19.67 18.63 19.47 18.43V18.44ZM8.53 16.06C4.38 16.06 1 12.68 1 8.53C1 4.38 4.38 1 8.53 1C12.68 1 16.06 4.38 16.06 8.53C16.06 12.68 12.68 16.06 8.53 16.06Z' fill='white' /%3E%3Cpath d='M12.95 8.03H9.04V4.12C9.04 3.84 8.82 3.62 8.54 3.62C8.26 3.62 8.04 3.84 8.04 4.12V8.03H4.13C3.85 8.03 3.63 8.25 3.63 8.53C3.63 8.81 3.85 9.03 4.13 9.03H8.04V12.94C8.04 13.22 8.26 13.44 8.54 13.44C8.82 13.44 9.04 13.22 9.04 12.94V9.03H12.95C13.23 9.03 13.45 8.81 13.45 8.53C13.45 8.25 13.23 8.03 12.95 8.03Z' fill='white' /%3E%3C/svg%3E\"), zoom-out",
      },
    },
  },
})

const ZoomImageWrapper = styled('div', {
  position: 'absolute',
  height: '100%',
  width: '100%',
  top: 0,
  overflow: 'hidden',
  variants: {
    isVisible: { false: { display: 'none' } },
  },
  '> *': {
    transform: `scale(${scaleFactor}) translate(var(--positionX), var(--positionY))`,
    transformOrigin: 'top left',
  },
  '@media (hover: none)': {
    overflow: 'scroll',
    '> *': {
      transform: `scale(${scaleFactor})`,
      transformOrigin: 'center transform(0, 0)',
    },
  },
})

type Props = {
  active: boolean
  priority: boolean
  mediaPayload: ShopifyImageMediaPayload
}

export const ProductGalleryMediaSlide = ({
  active = false,
  priority,
  mediaPayload,
}: Props) => {
  const [zoomActive, setZoomActive] = useState(false)
  const difference = (scaleFactor - 1) / scaleFactor

  const wrapper = useCallback(
    (node: HTMLDivElement) => {
      if (node && active) {
        const imageWrapperWidth = node.offsetWidth
        const imageWrapperHeight = node.offsetHeight

        node.addEventListener('mousemove', (ev: MouseEvent) => {
          node.style.setProperty(
            '--positionX',
            `${((ev.offsetX / imageWrapperWidth) * difference * -100).toFixed(
              2,
            )}%`,
          )

          node.style.setProperty(
            '--positionY',
            `${((ev.offsetY / imageWrapperHeight) * difference * -100).toFixed(
              2,
            )}%`,
          )
        })
      }
    },
    [active, difference],
  )

  useEffect(() => {
    setZoomActive(false)
  }, [active])

  return (
    <Wrapper
      key={mediaPayload.image.src}
      active={active}
      onClick={() => setZoomActive(!zoomActive)}
      ref={wrapper}
      hasZoomActive={zoomActive}
    >
      <Media
        priority={priority}
        mediaPayload={mediaPayload}
        hardcropRatio="portrait1"
        sizes={{ lg: '60vw', default: '0vw' }}
        withBackground
      />
      <ZoomImageWrapper isVisible={zoomActive} key={mediaPayload.image.src}>
        <Media
          priority={priority}
          mediaPayload={mediaPayload}
          hardcropRatio="portrait1"
          sizes={{ lg: '80vw', default: '0vw' }}
        />
      </ZoomImageWrapper>
    </Wrapper>
  )
}
