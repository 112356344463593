import { TemperatureFeatureValue } from '@aether/models'
import { PerformanceTemperatureValue } from '../PerfomanceTemperatureFeature/PerformanceTemperatureFeature'

export const getTemperaturePerformanceValues = (
  shopifyId?: string,
  sanityBasePerformanceValues?: TemperatureFeatureValue[],
): PerformanceTemperatureValue[] => {
  if (!shopifyId || !sanityBasePerformanceValues) return []

  return sanityBasePerformanceValues.map((feature) => {
    return {
      title: feature.title,
      subtitle: feature.subtitle,
      selected: feature.shopifyId === shopifyId,
      minTempValue: feature.minTempValue,
      maxTempValue: feature.maxTempValue,
    }
  })
}
