import { FC } from 'react'
import {
  SectionWrapper,
  InfiniteProductsList,
  InfiniteProductsListItem,
  ProductItem,
} from '@aether/components'

import { SectionCollectionItemsProps } from '@aether/models'
import { DEFAULT_LOCALE_ID } from '@aether/configs'
import { useRouter } from 'next/router'
import {
  useCollectionProducts,
  useCollectionRelatedProducts,
} from '@aether/collection'
import { useWunderkind } from '@aether/services/wunderkind-service'

export const SectionCollectionItems: FC<SectionCollectionItemsProps> = ({
  shopifyData,
  startItem,
  endItem,
  siteConfiguration,
}) => {
  const { locale = DEFAULT_LOCALE_ID } = useRouter()
  const handle = shopifyData?.collection?.handle || ''

  const { fetchNextPage, isFetching, hasNextPage, products, lastPageProducts } =
    useCollectionProducts({ locale, handle })

  useWunderkind({
    pageType: 'category',
    collectionData: {
      products,
      title: shopifyData?.collection?.title,
    },
  })

  const lastPageProductsTitles = lastPageProducts.map((p) => p.title)

  const relatedProducts = useCollectionRelatedProducts({
    handle,
    locale,
    titles: lastPageProductsTitles,
  })

  const startIndex = startItem || 0
  const endIndex = endItem || Infinity
  const sectionProductsAmount = Math.abs(endIndex - startIndex)
  const sectionProducts = products.slice(startIndex, endIndex)
  const sectionProductsIds = sectionProducts.map(({ id }) => id)
  const isSectionUnfulfilled = sectionProductsIds.length < sectionProductsAmount

  const getMoreProducts = () => {
    if (isSectionUnfulfilled && hasNextPage) {
      fetchNextPage()
    }
  }

  const updatedShopifyData = {
    products: [...products, ...relatedProducts]?.reduce(
      (acc, product) => ({
        ...acc,
        [product.id]: product,
      }),
      {},
    ),
    variants: {},
  }

  return (
    <SectionWrapper>
      <InfiniteProductsList
        getMore={getMoreProducts}
        isLoading={isSectionUnfulfilled && isFetching}
      >
        {sectionProductsIds?.map((productId) => {
          return (
            <InfiniteProductsListItem key={productId}>
              <ProductItem
                colorsMap={siteConfiguration?.colorsMap}
                productId={productId}
                shopifyData={updatedShopifyData}
                key={productId}
              />
            </InfiniteProductsListItem>
          )
        })}
      </InfiniteProductsList>
    </SectionWrapper>
  )
}
