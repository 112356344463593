import { FC } from 'react'
import { Container, Link, SectionWrapper, MediaLink } from '@aether/components'
import { styled, useMediaQuery } from '@aether/styles'
import { SectionLandscapePictureWithCaptionProps } from '@aether/models'
import { createDoubleLinksArray } from '@aether/utils'

const MediaWrap = styled('div', {
  variants: {
    imagePosition: {
      left: {
        gridArea: 'left',
      },
      right: {
        gridArea: 'left',
        '@lg': {
          gridArea: 'right',
        },
      },
    },
  },
})
const StyledContainer = styled(Container, {
  display: 'grid',
  gridGap: '$28',
  width: '100%',
  height: '100%',

  gridTemplateAreas: `
                      'left'
                      'right'
                      `,
  '@lg': {
    gridTemplateAreas: `
    'left right'
    `,
  },
  variants: {
    imagePosition: {
      left: {
        '@lg': {
          gridTemplateColumns: '2fr 1fr',
        },
      },
      right: {
        '@lg': {
          gridTemplateColumns: '1fr 2fr',
        },
      },
    },
  },
})
const Title = styled('h2', {
  $aetherFont: 'heading03',
})
const Description = styled('p', {
  $aetherFont: 'body02',
})
const ColumnWithText = styled('div', {
  display: 'grid',
  justifyItems: 'start',
  gridAutoFlow: 'row',
  gridAutoRows: 'min-content',
  paddingLeft: '$12',
  borderLeft: '1px solid $black',
  gap: '$16',
  '@lg': {
    paddingLeft: 'none',
    borderLeft: 'none',
  },
  variants: {
    imagePosition: {
      left: {
        gridArea: 'right',
        paddingLeft: '$12',
        borderLeft: '1px solid $black',
        '@lg': {
          borderLeft: 0,
          paddingLeft: 0,
          paddingRight: '$24',
          borderRight: '1px solid $black',
        },
      },
      right: {
        gridArea: 'right',
        '@lg': {
          gridArea: 'left',
          paddingLeft: '$32',
          borderLeft: '1px solid $black',
        },
      },
    },
  },
})
export const SectionLandscapePictureWithCaption: FC<
  SectionLandscapePictureWithCaptionProps
> = ({
  title,
  description,
  image,
  sectionSpaceSize,
  imagePosition,
  ctaLabel,
  ctaLink,
  secondaryCtaLabel,
  secondaryCtaLink,
}) => {
  const matchesMd = useMediaQuery('md')

  const links = createDoubleLinksArray(
    ctaLink,
    ctaLabel,
    secondaryCtaLink,
    secondaryCtaLabel,
  )

  return (
    <SectionWrapper>
      <StyledContainer
        spaceSize={sectionSpaceSize}
        imagePosition={imagePosition}
      >
        <MediaWrap imagePosition={imagePosition}>
          {image && (
            <MediaLink
              media={{
                ...image,
                hardcropRatio: matchesMd ? 'landscape1' : 'portrait1',
                sizes: { lg: '60vw' },
              }}
              link={links.length === 1 ? links[0].ctaLink : undefined}
            />
          )}
        </MediaWrap>
        <ColumnWithText imagePosition={imagePosition}>
          <Title>{title}</Title>
          {description && <Description>{description}</Description>}

          {links.length > 0 &&
            links.map(({ ctaLink, ctaLabel }, index) => (
              <Link
                {...ctaLink}
                appearance="primary"
                key={`${ctaLabel}-${index}`}
              >
                {ctaLabel}
              </Link>
            ))}
        </ColumnWithText>
      </StyledContainer>
    </SectionWrapper>
  )
}
