import { styled } from '@aether/styles'
import { FC, Fragment } from 'react'

import { UpdateFiltersParamsFnType } from '../useCollectionSearchParams'
import { NoFiltersInfoText } from './NoFiltersInfoText'
import { ResolvedFilter } from '@aether/collection'

const Root = styled('div', {
  paddingBottom: '$48',
  display: 'grid',
  columnGap: '$12',
  rowGap: '$28',
  gridTemplateColumns: 'repeat(3, 1fr)',
})

const InputRadio = styled('input', {
  appearance: 'none',
  border: 0,
  position: 'absolute',
})

const LabelWrap = styled('label', {
  display: 'inline-flex',
  $aetherFont: 'ui02',
  borderRadius: '$r1',
  height: '$40',
  border: '1px solid',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  variants: {
    selected: {
      true: {
        backgroundColor: '$black',
        color: '$white',
      },
    },
  },
})

const HTML_ID_PREFIX = 'price_filter'

type Props = {
  className?: string
  onFilterChange: UpdateFiltersParamsFnType
  filters: ResolvedFilter[]
  selectedFilters: string[]
}

export const PriceFilter: FC<Props> = ({
  className,
  onFilterChange,
  selectedFilters,
  filters,
}) => {
  if (!filters?.length) {
    return <NoFiltersInfoText />
  }

  const queryKey = filters[0].searchParamName

  return (
    <Root className={className}>
      {filters.map(({ value, label }) => {
        const isSelected = selectedFilters?.includes(value)
        const HTML_ID = `${HTML_ID_PREFIX}_${value}`

        return (
          <Fragment key={value}>
            <InputRadio
              type="checkbox"
              id={HTML_ID}
              value={value}
              onClick={() => onFilterChange(queryKey, value, true)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  onFilterChange(queryKey, value, true)
                }
              }}
              checked={isSelected}
            />
            <LabelWrap selected={isSelected} htmlFor={HTML_ID}>
              {label}
            </LabelWrap>
          </Fragment>
        )
      })}
    </Root>
  )
}
