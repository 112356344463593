import { styled } from '@aether/styles'

import { ShopifyImageMediaPayload } from '@aether/models'

import {
  GalleryThumbnail,
  THUMBNAIL_WIDTH,
  THUMBNAIL_PADDING_LEFT,
} from './GalleryThumbnail'

interface GalleryThumbnailsProps {
  images: ShopifyImageMediaPayload[]
  // name needed as each of the thumbnails is related to an input
  // in ourder to group the inputs they need to have a consistent name
  name: string
  activeImageIndex: number
  onImageSelected: (index: number) => void
  priority: boolean
}

export const Container = styled('fieldset', {
  overflow: 'hidden',
  top: 0,

  width: THUMBNAIL_WIDTH + THUMBNAIL_PADDING_LEFT + 5,
  left: 0,
  overflowY: 'scroll',
  display: 'grid',
  gridAutoFlow: 'row',
  gap: '$12',
  position: 'relative',
  height: 'fit-content',
  padding: '0 5px 5px 0',
  '&:before': {
    content: '',
    left: '0px',
    height: '100%',
    width: '1px',
    backgroundColor: '$gray_light_fourth',
    position: 'absolute',
  },

  '&::-webkit-scrollbar': {
    display: 'none',
  },
})

export const GalleryThumbnails = ({
  images,
  name,
  activeImageIndex,
  onImageSelected,
  priority,
}: GalleryThumbnailsProps) => {
  return images.length > 0 ? (
    <Container>
      {images.map((image, index) => {
        return (
          <GalleryThumbnail
            key={image.image.src}
            image={image}
            name={name}
            checked={index === activeImageIndex}
            index={index}
            onSelected={onImageSelected}
            priority={priority && index === 0}
          />
        )
      })}
    </Container>
  ) : null
}
