import React, { FC } from 'react'
import { SectionCatalogProps } from '@aether/models'
import {
  Container,
  Link,
  Media,
  PortableText,
  SectionWrapper,
} from '@aether/components'
import { styled } from '@aether/styles'

const CatalogWrap = styled('div', {
  display: 'grid',
  marginBottom: '$64',
  gridTemplateColumns: '1fr',
  rowGap: '$32',

  '@md': {
    columnGap: '$32',
    gridTemplateColumns: '1fr 2fr',
  },
})

const Title = styled('h2', {
  $aetherFont: 'heading03',
  marginBottom: '$32',
})
const Description = styled('h2', {
  $aetherFont: 'body01',
  marginBottom: '$32',
})
const InfoWrap = styled('div', {
  display: 'grid',
  alignSelf: 'start',
})

export const SectionCatalog: FC<SectionCatalogProps> = ({
  catalogTitle,
  catalogDescription,
  catalogCover,
  ctaLabel,
  ctaLink,
  content,
}) => {
  return (
    <SectionWrapper>
      <Container>
        <CatalogWrap>
          <Link {...ctaLink} appearance="block" as={'span'} tabIndex={-1}>
            <Media {...catalogCover} sizes={{ md: '50vw' }} withBackground />
          </Link>
          <InfoWrap>
            <Title>{catalogTitle}</Title>
            <Description>{catalogDescription}</Description>
            <Link appearance="badgeBlack" {...ctaLink} target={'_blank'}>
              {ctaLabel}
            </Link>
          </InfoWrap>
        </CatalogWrap>
        <PortableText value={content} withSpacing={false} fontSize={'S'} />
      </Container>
    </SectionWrapper>
  )
}
