import { styled } from '@aether/styles'
import { SlideButton } from '@aether/components'
import { FC } from 'react'
import { ShopifyImageMediaPayload } from '@aether/models'
import { GalleryThumbnails } from './GalleryThumbnails'
import { THUMBNAIL_WIDTH, THUMBNAIL_PADDING_LEFT } from './GalleryThumbnail'
import { ProductGalleryMediaSlide } from './ProductGalleryMediaSlide'

const Root = styled('div', {
  display: 'grid',
  gridTemplateColumns: `${THUMBNAIL_WIDTH + THUMBNAIL_PADDING_LEFT}px auto`,
  gridTemplateRows: '1fr',
  gridGap: '$40',
  position: 'relative',
})
const NavButtonsWrapper = styled('div', {
  opacity: 0,
  transition: 'opacity ease-in 0.2s',
})

const MediaWrapDesktop = styled('div', {
  position: 'relative',
  '@lg': {
    width: '100%',
    gridRow: '1',
    justifySelf: 'center',
    alignSelf: 'start',
    transition: 'opacity ease-in 0.2s',
  },
  variants: {
    withGallery: {
      true: {
        gridColumn: '2 / 3',
      },
      false: {
        gridColumn: '1 / 3',
      },
    },
  },
  [`&:hover ${NavButtonsWrapper}`]: {
    opacity: 1,
  },
})

const StyledNextSlideButton = styled(SlideButton, {
  position: 'absolute',
  top: '50%',
  right: '$24',
})

const StyledPrevSlideButton = styled(SlideButton, {
  position: 'absolute',
  top: '50%',
  left: '$24',
})

type Props = {
  images: ShopifyImageMediaPayload[]
  activeImageId: number
  setActiveImage: (index: number) => void
  className?: string
  priority?: boolean
}

export const ProductGalleryDesktop: FC<Props> = ({
  images,
  activeImageId = 0,
  setActiveImage,
  className,
  priority,
}) => {
  return (
    <Root className={className}>
      <GalleryThumbnails
        images={images}
        name="product-gallery-thumbnails"
        activeImageIndex={activeImageId}
        onImageSelected={(index) => setActiveImage(index)}
        priority={!!priority}
      />
      <MediaWrapDesktop withGallery={images.length > 0}>
        {images.map((image, index) => {
          return (
            <ProductGalleryMediaSlide
              key={image.image.src}
              active={activeImageId === index}
              priority={index === 0}
              mediaPayload={image}
            />
          )
        })}
        <NavButtonsWrapper>
          <StyledPrevSlideButton
            handleClick={() =>
              setActiveImage(
                activeImageId > 0 ? activeImageId - 1 : images.length - 1,
              )
            }
            isVisible={images.length > 1}
            arrowDirection="left"
            ariaLabel="Previous slide"
          />
          <StyledNextSlideButton
            handleClick={() =>
              setActiveImage(
                activeImageId < images.length - 1 ? activeImageId + 1 : 0,
              )
            }
            isVisible={images.length > 1}
            arrowDirection="right"
            ariaLabel="Next slide"
          />
        </NavButtonsWrapper>
      </MediaWrapDesktop>
    </Root>
  )
}
