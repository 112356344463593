import { styled, useMediaQuery } from '@aether/styles'
import {
  ProductColorType,
  ShopifyProduct,
  ShopifyProductsData,
  ShopifyProductVariant,
} from '@aether/models'
import {
  CartTotalPrice,
  Media,
  Modal,
  ProductCard,
  ProductVariantPrice,
  StyledClickable,
} from '@aether/components'
import React from 'react'
import { formatProductName, getProductMetafieldValues } from '@aether/utils'
import { useCartContext } from '@aether/cart'
import { Close } from '@aether/icons'
import { useTranslation } from 'react-i18next'
import { useCheckout } from '@aether/cart'

const ModalWrap = styled('div', {
  position: 'relative',
  overflow: 'hidden',
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100vh',
  padding: '$32 0 $20',
  maxWidth: '966px',

  '@md': {
    minHeight: '100%',
    overflow: 'hidden',
    padding: '$56 $48',
  },
})

const ProductGrid = styled('div', {
  width: '100%',
  padding: '0 $24',
  '@md': {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gridGap: '$12',
    borderBottom: '1px solid black',
    padding: '0 0 $16',
  },
})

const ProductWrap = styled('div', {
  padding: '0',
  '@md': {
    borderTop: '1px solid black',
  },
})

const CloseButton = styled('button', {
  background: 'none',
  justifySelf: 'end',
  padding: 0,
  $focusStyle: 'default',
  height: 44,
  width: 44,
  position: 'absolute',
  right: '$12',
  top: '40px',
  zIndex: 9999,

  '@md': {
    top: '$12',
    right: '$8',
  },
})

const FeaturedProducts = styled('div', {
  display: 'inline-flex',
  flexDirection: 'column',
  minHeight: '100%',
})

const Title = styled('h2', {
  $aetherFont: 'heading04',
  borderTop: '1px solid black',
  padding: '$16 0',
  '@sm': {
    borderTop: 'hidden',
    marginBottom: '$16',
  },

  '@md': {
    padding: '0',
  },

  variants: {
    paddingType: {
      noPadding: {
        padding: '0',
      },
      withPadding: {
        margin: '0 $24',
        '@md': {
          margin: '0',
          padding: '$16 0',
        },
      },
    },
  },
})

const SubTitle = styled('h3', {
  $aetherFont: 'heading04',
  borderTop: '1px solid black',
  padding: '$16 0',
  '@sm': {
    borderTop: 'hidden',
    marginBottom: '$16',
  },

  '@md': {
    $aetherFont: 'heading05',
    padding: '0',
  },

  variants: {
    paddingType: {
      noPadding: {
        padding: '0',
      },
      withPadding: {
        margin: '0 $24',
        '@md': {
          margin: '0',
          padding: '$16 0',
        },
      },
    },
  },
})

const ProductDetails = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  padding: '0 0 $16',

  '@md': {
    padding: '0',
  },
})

const FeaturedImage = styled('div', {
  maxWidth: '35%',

  '@sm': {
    maxWidth: '25%',
  },
})

const ProductDetailsWrap = styled('div', {
  '@sm': {
    borderTop: '1px solid black',
    padding: '$16 0 0',
  },
  '@md': {
    borderTop: 'hidden',
    gridColumn: '1 / 2',
  },
})

const SelectedOptionsInfo = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  rowGap: '$8',
  paddingLeft: '$20',
})

const SelectedOptionsTitle = styled('span', {
  $aetherFont: 'heading06',
})

const SelectedOptionsWrap = styled('div', {
  alignItems: 'center',
  display: 'inline-flex',
  gap: '$12',
})

const SelectedOptionsLabel = styled('span', {
  $aetherFont: 'ui05',
  alignItems: 'center',
  whiteSpace: 'break-spaces',
})

const Color = styled('div', {
  height: '$12',
  width: '$12',
  borderRadius: '$rMax',
  overflow: 'hidden',
})

const ItemsInCartWrap = styled('div', {
  background: '$gray_light',
  borderRadius: '$r2',
  padding: '$24',
  margin: '$8 0 $24',
  '@md': {
    gridColumn: '2 / 3',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    margin: '$16 0 0',
  },
})

const ItemsInCartSummary = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',

  rowGap: '$8',
  marginBottom: '$20',

  variants: {
    hasDiscountedLine: {
      true: {
        flexDirection: 'column',
        alignItems: 'start',

        '@md': {
          flexDirection: 'row',
          alignItems: 'center',
        },
      },
      false: {
        flexDirection: 'row',
        alignItems: 'center',
      },
    },
  },
})

const ItemsInCartTitle = styled('h3', {
  $aetherFont: 'heading06',
})

const SubTotalWrap = styled('div', {
  display: 'flex',
  justifyContent: 'flex-end',
  columnGap: '$4',
  $aetherFont: 'ui01',
})

const ContinueToCheckoutButton = styled(StyledClickable, {
  width: '100%',
  marginBottom: '$12',
  maxHeight: '42px',

  '@sm': {
    maxHeight: '44px',
  },
})

const ViewCartButton = styled(StyledClickable, {
  width: '100%',
  maxHeight: '42px',

  '@sm': {
    maxHeight: '44px',
  },
})

const ProductVariantPriceWrap = styled('div', {
  $aetherFont: 'ui06',
})

const Slider = styled('ul', {
  width: '100vw',
  overflowX: 'scroll',
  overflowY: 'auto',
  display: 'flex',
  padding: '0 $24',
  scrollSnapType: 'x mandatory',
  scrollBehavior: 'smooth',
  gridGap: '0 $12',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
  $lin: {
    scrollPaddingLeft: 'CONTAINER_SPACE_M',
  },

  '@md': {
    display: 'grid',
    gridGap: '0 $20',
    padding: '0',
    gridTemplateColumns: 'repeat(4 ,1fr)',
    width: '100%',
  },
})

const Slide = styled('li', {
  flex: '0 0 45%',
  flexFlow: 'column',
  minWidth: 'auto',
  scrollSnapAlign: 'start',
  height: '100%',
})

type AddedToCartPopupProps = {
  variant?: ShopifyProductVariant
  productData?: ShopifyProduct
  color?: ProductColorType
  shopifyData?: ShopifyProductsData
}

export const CartUpsellModal = ({
  productData,
  shopifyData,
  color,
  variant,
}: AddedToCartPopupProps) => {
  const {
    cart,
    quantity,
    setCartModalOpen,
    isCartUpsellModalOpen,
    setCartUpsellModalOpen,
  } = useCartContext()
  const { handleCheckout } = useCheckout()
  const { t } = useTranslation('cart')
  const complementaryProductsIds = getProductMetafieldValues(
    'complementaryProducts',
    productData ?? null,
  )

  const complementaryProducts = complementaryProductsIds.reduce<
    ShopifyProduct[]
  >((acc, currentId) => {
    const product = shopifyData?.products[currentId]
    if (product) return [...acc, product]
    return acc
  }, [])

  const matchesMd = useMediaQuery('md')

  const title = formatProductName(variant?.product.title) ?? ''

  const hasDiscountedLine = cart?.lines.some(
    (line) => line.cost.subtotalAmount.amount !== line.cost.totalAmount.amount,
  )

  const handleGoToCheckout = () => {
    setCartUpsellModalOpen(false)
    handleCheckout()
  }

  const handleViewCart = () => {
    setCartUpsellModalOpen(false)
    setCartModalOpen(true)
  }

  const handleSlideClick = () => setCartUpsellModalOpen(false)

  return (
    <Modal
      size={matchesMd ? 'intrinsic' : 'stretch'}
      preventScroll={true}
      isOpen={isCartUpsellModalOpen}
    >
      <ModalWrap>
        <CloseButton
          onClick={() => setCartUpsellModalOpen(false)}
          aria-label="Close"
        >
          <Close />
        </CloseButton>
        <ProductWrap>
          <ProductGrid>
            <ProductDetailsWrap>
              <Title>{t('addedToCart')}</Title>
              <ProductDetails>
                <FeaturedImage>
                  {variant?.image && (
                    <Media
                      mediaPayload={variant?.image}
                      sizes={{ default: '100vw' }}
                      hardcropRatio="portrait1"
                      objectFit={'contain'}
                    />
                  )}
                </FeaturedImage>
                <SelectedOptionsInfo>
                  <SelectedOptionsTitle>{title}</SelectedOptionsTitle>
                  {color && (
                    <SelectedOptionsWrap>
                      <Color>
                        <Media
                          {...color.image}
                          hardcropRatio="square"
                          sizes="24px"
                        />
                      </Color>
                      <SelectedOptionsLabel>{`${color.label} / ${variant?.title}`}</SelectedOptionsLabel>
                    </SelectedOptionsWrap>
                  )}
                  <ProductVariantPriceWrap>
                    {variant && (
                      <ProductVariantPrice productVariant={variant} />
                    )}
                  </ProductVariantPriceWrap>
                </SelectedOptionsInfo>
              </ProductDetails>
            </ProductDetailsWrap>
            <ItemsInCartWrap>
              <ItemsInCartSummary hasDiscountedLine={hasDiscountedLine}>
                <ItemsInCartTitle>
                  {quantity} {quantity === 1 ? 'item' : t('items')}
                </ItemsInCartTitle>
                <SubTotalWrap>
                  <h3>{t('subtotal')}:</h3>
                  <CartTotalPrice cart={cart} />
                </SubTotalWrap>
              </ItemsInCartSummary>
              <ContinueToCheckoutButton
                type={'button'}
                size={'L'}
                appearance={'badgeBlack'}
                onClick={handleGoToCheckout}
              >
                {t('checkout')}
              </ContinueToCheckoutButton>
              <ViewCartButton
                type={'button'}
                appearance={'badgeOutlineBig'}
                size={'L'}
                onClick={handleViewCart}
              >
                {t('viewCart')}
              </ViewCartButton>
            </ItemsInCartWrap>
          </ProductGrid>
        </ProductWrap>
        {complementaryProducts?.length > 0 ? (
          <FeaturedProducts>
            <SubTitle paddingType={'withPadding'}>
              {t('pairsWellWith')}
            </SubTitle>
            <Slider>
              {complementaryProducts.map(
                (product: ShopifyProduct, index: number) => (
                  <Slide
                    key={`${product.id}-${index}`}
                    onClick={handleSlideClick}
                  >
                    <ProductCard
                      product={product}
                      mediaSizes={{ default: '150vw' }}
                      variant={'popup'}
                    />
                  </Slide>
                ),
              )}
            </Slider>
          </FeaturedProducts>
        ) : null}
      </ModalWrap>
    </Modal>
  )
}
