import { FC, useRef, useState } from 'react'
import { SectionKlaviyoFormEmbedProps } from '@aether/models'
import { styled } from '@aether/styles'
import { useMutationObserver } from '@aether/utils'
import { Container, Media, PortableText } from '@aether/components'

const StyledContainer = styled(Container, {
  display: 'grid',
  gridTemplateRows: 'auto auto',
  gridTemplateColumns: '1fr',
  gridColumnGap: '$64',
  paddingTop: '$48',
  '@lg': {
    $posterHeight: true,
    gridTemplateRows: '1fr',
    gridTemplateColumns: '1fr 1fr',
  },
})

const MediaWrap = styled('div', {})

const ContentWrap = styled('div', {
  display: 'grid',
  gridAutoFlow: 'row',
  gridRowGap: '$24',
  gridTemplateRows: 'auto 1fr',
  paddingTop: '$32',
  '@lg': {
    paddingTop: '0',
  },
  variants: {
    fullSize: {
      true: {
        $lin: {
          paddingRight: 'CONTAINER_SPACE_S',
          paddingLeft: 'CONTAINER_SPACE_S',
        },
        '@lg': {
          $lin: {
            paddingRight: 'CONTAINER_SPACE_S',
            paddingLeft: [0, 0],
          },
        },
      },
    },
  },
})

const Title = styled('h1', {
  $aetherFont: 'heading04',
})

const RichTextWrap = styled('div', {})

export const SectionKlaviyoFormEmbed: FC<SectionKlaviyoFormEmbedProps> = ({
  title,
  media,
  sectionSpaceSize,
  content,
}) => {
  const ref = useRef<HTMLDivElement>(null)
  const [isPlaceholderVisible, setIsPlaceholderVisible] = useState(true)

  useMutationObserver({
    target: ref.current,
    callback: () => {
      if (isPlaceholderVisible) {
        setIsPlaceholderVisible(false)
      }
    },
  })

  return (
    <StyledContainer spaceSize={sectionSpaceSize} size="L">
      <MediaWrap>{media && <Media {...media} />}</MediaWrap>
      <ContentWrap fullSize={sectionSpaceSize == 'none'}>
        <Title>{title}</Title>
        <RichTextWrap>
          <PortableText value={content} withSpacing={false} fontSize={'S'} />
        </RichTextWrap>
      </ContentWrap>
    </StyledContainer>
  )
}
