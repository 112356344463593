import { FC } from 'react'
import { SectionProductGuaranteesProps } from '@aether/models'
import { styled } from '@aether/styles'
import { Container, Link, SectionWrapper } from '@aether/components'

const CardsList = styled('div', {
  display: 'grid',
  gridAutoFlow: 'row',
  gap: '$48',
  '@lg': {
    display: 'grid',
    gridAutoFlow: 'column',
  },
})

const Card = styled('article', {
  display: 'grid',
  gridTemplateRows: 'min-content auto max-content',
  gridTemplateColumns: '1fr',
  gap: '$20',
  '@lg': {
    borderLeft: '1px solid $black',
    padding: '0 0 0 $20',
    height: '100%',
  },
})

const CardTitle = styled('h4', {
  $aetherFont: 'heading04',
  color: '$black',
})

const CardText = styled('p', {
  $aetherFont: 'body02',
  color: '$black',
})

const StyledLink = styled(Link, {
  display: 'flex',
  width: 'min-content',
})

export const SectionProductGuarantees: FC<SectionProductGuaranteesProps> = ({
  terms,
}) => {
  return (
    <SectionWrapper>
      <Container>
        <CardsList>
          {terms.map(({ description, title, link, linkLabel }, index) => (
            <Card key={`guarantee-${index}`}>
              {title && <CardTitle>{title}</CardTitle>}
              {description && <CardText>{description}</CardText>}
              {linkLabel && link && (
                <StyledLink {...link} key={linkLabel} appearance="primary">
                  {linkLabel}
                </StyledLink>
              )}
            </Card>
          ))}
        </CardsList>
      </Container>
    </SectionWrapper>
  )
}
