import { FC } from 'react'
import { ShopifyProduct } from '@aether/models'
import { styled } from '@aether/styles'
import { ProductCard } from '@aether/components'

type DesktopGalleryProps = {
  products: ShopifyProduct[]
}

const Root = styled('ul', {
  display: 'grid',
  gridGap: '$24',
  gridTemplateColumns: `repeat(2, 1fr)`,
  '@xl': {
    gridTemplateColumns: `repeat(4, 1fr)`,
  },
})

export const DesktopGallery: FC<DesktopGalleryProps> = ({ products }) => {
  return (
    <Root>
      {products.map((product, index) => (
        <li key={`${product.id}-${index}`}>
          <ProductCard
            product={product}
            mediaSizes={{ lg: '50vw', xl: '25vw' }}
          />
        </li>
      ))}
    </Root>
  )
}
