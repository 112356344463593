import { FC } from 'react'
import { SectionTripleCardsProps } from '@aether/models'
import { styled, useMediaQuery } from '@aether/styles'
import { Container, Link, MediaLink, SectionWrapper } from '@aether/components'

const StyledContainer = styled(Container, {
  position: 'relative',
})

const Title = styled('h3', {
  $aetherFont: 'heading03',
  color: '$black',
})

const StyledLink = styled(Link, {
  display: 'flex',
  width: 'min-content',
})

const Card = styled('article', {
  display: 'grid',
  gridAutoFlow: 'row',
  alignItems: 'start',
  gap: '$20',
})

const CardsList = styled('ul', {
  display: 'grid',
  gridAutoFlow: 'row',
  gap: '$56',
  '@lg': {
    gridAutoFlow: 'column',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gap: '$24',
  },
})

const TitleWrap = styled('div', {
  display: 'flex',
  margin: '0 0 $24 0',
  '@lg': {
    margin: 0,
    paddingBottom: '$32',
  },

  variants: {
    border: {
      top: {
        borderTop: '1px solid $black',
        paddingTop: '$24',
      },
      none: {
        border: 'none',
      },
    },
  },
})

const CardTitleContainer = styled('div', {
  display: 'grid',
  gridAutoFlow: 'row',
  gap: '$16',
})

const CardTitle = styled('h4', {
  $aetherFont: 'heading04',
  color: '$black',
})

const CardText = styled('p', {
  $aetherFont: 'body02',
  color: '$black',
})

const CardListItem = styled('li', {
  overflow: 'hidden',
})

export const SectionTripleCards: FC<SectionTripleCardsProps> = ({
  title,
  cards,
  sectionSpaceSize = 'small',
  sectionColor,
  borderPosition,
}) => {
  const sectionCards = cards ? cards.slice(0, 3) : []
  const isMd = useMediaQuery('md')

  return sectionCards.length === 3 ? (
    <SectionWrapper withBackground={sectionColor && sectionColor !== 'default'}>
      <StyledContainer
        spaceSize={isMd ? sectionSpaceSize : 'small'}
        sectionColor={sectionColor}
      >
        <TitleWrap border={borderPosition}>
          <Title>{title}</Title>
        </TitleWrap>
        <CardsList>
          {sectionCards.map(({ image, link, label, title, text }, index) => (
            <CardListItem key={`${label}-${index}`}>
              <Card>
                {image && (
                  <MediaLink
                    media={{
                      ...image,
                      hardcropRatio: 'portrait1',
                      sizes: { lg: '33vw' },
                    }}
                    link={label && link ? link : undefined}
                  />
                )}
                <CardTitleContainer>
                  {title && <CardTitle>{title}</CardTitle>}
                  {text && <CardText>{text}</CardText>}
                  {label && link && (
                    <StyledLink {...link} key={label} appearance="primary">
                      {label}
                    </StyledLink>
                  )}
                </CardTitleContainer>
              </Card>
            </CardListItem>
          ))}
        </CardsList>
      </StyledContainer>
    </SectionWrapper>
  ) : null
}
