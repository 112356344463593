import { FC, useRef, useEffect, useState, useCallback } from 'react'
import { styled } from '@aether/styles'
import { Link } from '@aether/components'
import { CollectionNavigation } from '@aether/models'
import { useRouter } from 'next/router'
import { FiltersButton } from './FiltersButton'
import { SmallArrowLeft, SmallArrowRight } from '@aether/icons'
import { useTranslation } from 'react-i18next'
import throttle from 'lodash.throttle'

const Root = styled('div', {
  width: '100vw',
  maxWidth: '100%',
  padding: '$16 0',
  marginTop: '$8',
  position: 'sticky',
  top: '-1px',
  zIndex: '$plpSubNavigation',
  backgroundColor: '$white',
})

const Content = styled('div', {
  display: 'grid',
  gridTemplateColumns: 'auto 1fr auto auto',
  alignItems: 'center',
  width: '100%',

  '&::after': {
    content: ' ',
    position: 'relative',
    boxShadow: '-20px 0px 30px 15px white, 10px 0px 20px 15px white',
  },
  '&::before': {
    content: ' ',
    position: 'relative',
    boxShadow: '20px 0px 30px 15px white, -10px 0px 20px 15px white',
  },

  '@md': {
    gap: '$8',
    $containerSpace: 'small',

    '&::after': {
      content: 'none',
    },
    '&::before': {
      content: 'none',
    },
  },
})

const LinksWrap = styled('nav', {
  display: 'grid',
  gridAutoFlow: 'column',
  gridAutoColumns: 'min-content',
  gap: '$8',
  overflowX: 'scroll',
  overflowY: 'auto',
  scrollSnapType: 'x mandatory',
  scrollPaddingLeft: 'CONTAINER_SPACE_S',
  '&::-webkit-scrollbar': {
    display: 'none',
  },

  $containerSpace: 'medium',

  '@md': {
    $containerSpace: 'none',
  },

  transition: 'opacity linear 200ms',
  variants: {
    isVisible: {
      true: {
        opacity: 1,
      },
      false: {
        opacity: 0,
      },
    },
  },
})

const StyledLink = styled(Link, {
  whiteSpace: 'nowrap',
  padding: '0 $12 !important',
})

const StyledFiltersButton = styled(FiltersButton, {
  display: 'none',
  '@md': {
    display: 'flex',
  },
})

const ArrowBox = styled('button', {
  display: 'none',
  width: '38px',
  height: '$buttonHeightS',
  background: '$white',
  borderRadius: '$r1',
  cursor: 'pointer',
  $focusStyle: 'default',
  zIndex: '1',

  '&:hover': {
    background: '$gray_light',
  },

  '@md': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  '&:disabled': {
    opacity: '0',
    cursor: 'auto',
  },
})

type Props = {
  openFilters: () => void
  navigationLinks?: CollectionNavigation
}

export const NavigationHeader: FC<Props> = ({
  openFilters,
  navigationLinks,
}) => {
  const { asPath: currentPath } = useRouter()
  const [activeSlideOffset, setActiveSlideOffset] = useState<
    number | undefined
  >(undefined)
  const { t } = useTranslation('sectionCollectionFiltering')

  // collection page can be also a filters page which has "/f" path followed by query
  const isActiveLink = (href: string | undefined) =>
    currentPath.split('?')[0] === href

  const hasActiveLink = navigationLinks?.some((navLink) =>
    isActiveLink(navLink.link?.href),
  )

  const [isInitiallyScrolled, setIsInitiallyScrolled] = useState<boolean>(false)

  const sliderRef = useRef<HTMLElement | null>(null)
  const sliderEl = sliderRef.current

  const handleActiveSlideRef = useCallback<
    (node: HTMLElement | null, isActive: boolean) => void
  >((node, isActive: boolean) => {
    if (isActive && node !== null) {
      const { offsetLeft } = node
      setActiveSlideOffset(offsetLeft)
    }
    return
  }, [])

  const [isPrevArrowVisible, setIsPrevArrowVisible] = useState<
    boolean | undefined
  >(undefined)
  const [isNextArrowVisible, setIsNextArrowVisible] = useState<
    boolean | undefined
  >(undefined)

  // Scroll to currently active category
  useEffect(() => {
    if (hasActiveLink && activeSlideOffset && sliderEl) {
      const scrollerOffset = sliderEl.offsetLeft
      const SLIDER_PADDING = 8
      const SLIDER_EXTRA_PADDING = 32
      const linkScrollOffset = activeSlideOffset - scrollerOffset

      sliderEl.scroll({
        top: 0,
        left: linkScrollOffset - SLIDER_PADDING - SLIDER_EXTRA_PADDING,
        behavior: isInitiallyScrolled ? 'smooth' : 'auto',
      })
      setIsInitiallyScrolled(true)
      checkControlsDisableState()
    }

    if (!hasActiveLink && sliderEl) {
      sliderEl.scroll({
        top: 0,
        left: 0,
        behavior: isInitiallyScrolled ? 'smooth' : 'auto',
      })
      setIsInitiallyScrolled(true)
      checkControlsDisableState()
    }
  }, [activeSlideOffset, sliderEl, hasActiveLink])

  const checkControlsDisableState = () => {
    if (sliderEl) {
      sliderEl.scrollLeft > 0
        ? setIsPrevArrowVisible(true)
        : setIsPrevArrowVisible(false)

      sliderEl.scrollLeft + sliderEl.offsetWidth === sliderEl.scrollWidth
        ? setIsNextArrowVisible(false)
        : setIsNextArrowVisible(true)
    }
  }

  const refreshControlsDisableState = throttle(checkControlsDisableState, 300)

  useEffect(() => {
    window.addEventListener('resize', refreshControlsDisableState)
    return () =>
      window.removeEventListener('resize', refreshControlsDisableState)
  })

  const scrollNavigation = (scrollValue: number): void => {
    sliderEl?.scroll({
      top: 0,
      left: sliderEl.scrollLeft + scrollValue,
      behavior: 'smooth',
    })
  }

  return (
    <Root>
      <Content>
        <ArrowBox
          disabled={!isPrevArrowVisible}
          onClick={() => scrollNavigation(-250)}
          css={{ boxShadow: '30px 0px 35px 10px white' }}
          aria-label={t('navigationHeader.previous') ?? 'previous'}
        >
          <SmallArrowLeft />
        </ArrowBox>
        <LinksWrap
          ref={sliderRef}
          onScroll={checkControlsDisableState}
          isVisible={isInitiallyScrolled || !hasActiveLink}
        >
          {navigationLinks?.map(({ link, label }, index) => {
            const href = link?.href
            const isActive = isActiveLink(href)

            return (
              <StyledLink
                key={`${href}-${label}-${index}`}
                {...link}
                appearance={'badge'}
                active={isActive}
                size="S"
                ref={(node) => handleActiveSlideRef(node, isActive)}
                aria-current={isActive && 'page'}
                shallow={false}
              >
                {label}
              </StyledLink>
            )
          })}
        </LinksWrap>
        <ArrowBox
          aria-label={t('navigationHeader.next') ?? 'next'}
          disabled={!isNextArrowVisible}
          onClick={() => scrollNavigation(250)}
          css={{ boxShadow: '-30px 0px 35px 10px white' }}
        >
          <SmallArrowRight />
        </ArrowBox>

        <StyledFiltersButton openFilters={openFilters} theme={'light'} />
      </Content>
    </Root>
  )
}
