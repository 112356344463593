import { LocaleId } from '@aether/configs'
import { ShopifyResolvedListProduct } from '@aether/shopify-sdk'
import { useQuery, useQueryClient } from 'react-query'
import { queryKeys } from '@aether/utils'
import { getSDKShopifyRelatedProducts } from '@aether/services/shopify-service'
import { useCustomerContext } from '@aether/account/utils-customer-context'

export const useCollectionRelatedProducts = ({
  locale,
  handle,
  titles,
}: {
  locale: LocaleId
  handle: string
  titles: string[]
}): ShopifyResolvedListProduct[] => {
  const queryClient = useQueryClient()
  const { userRole } = useCustomerContext()
  const isVip = userRole === 'vip'

  useQuery(
    queryKeys.plpRelatedProducts(handle, titles),
    () =>
      getSDKShopifyRelatedProducts({
        locale,
        titles,
      }),
    {
      keepPreviousData: true,
      staleTime: 1000 * 60 * 150,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    },
  )

  // return all previously fetched related products
  return queryClient
    .getQueriesData<{
      products: ShopifyResolvedListProduct[]
    }>({
      queryKey: queryKeys.plpRelatedProducts(handle)[0],
    })
    .reduce<ShopifyResolvedListProduct[]>((acc, batch) => {
      return [...acc, ...(batch?.[1]?.products || [])]
    }, [])
    .filter((product) => (isVip ? product : !product?.vipProduct))
}
