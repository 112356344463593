import { styled } from '@aether/styles'
import { FC, Fragment } from 'react'
import { Media } from '@aether/components'
import { SiteConfiguration } from '@aether/models'
import { UpdateFiltersParamsFnType } from '../useCollectionSearchParams'
import { NoFiltersInfoText } from './NoFiltersInfoText'
import { ResolvedFilter } from '@aether/collection'

const Root = styled('div', {
  paddingBottom: '$48',
  display: 'grid',
  columnGap: '$12',
  rowGap: '$28',
  gridTemplateColumns: 'repeat(3, 1fr)',
})

const InputRadio = styled('input', {
  appearance: 'none',
  border: 0,
  position: 'absolute',
})

const LabelWithIcon = styled('label', {
  display: 'grid',
  width: '100%',
  padding: '$8',
  margin: '0 auto',
  textAlign: 'center',
  flexDirection: 'column',
  justifyItems: 'center',
  alignItems: 'center',
  $aetherFont: 'ui02',
  gap: '$20',
  borderRadius: '$r1',
  border: '1px solid $gray_light',
  variants: {
    selected: {
      true: {
        backgroundColor: '$gray_light',
      },
    },
    isDisabled: {
      true: {
        opacity: 0.4,
      },
      false: {
        cursor: 'pointer',
      },
    },
  },
})

const TechFeatureIconWrap = styled('div', {
  width: 42,
  height: 42,
})

const HTML_ID_PREFIX = 'tech_features_filter'

type Props = {
  className?: string
  onFilterChange: UpdateFiltersParamsFnType
  siteConfiguration?: SiteConfiguration
  filters: ResolvedFilter[]
  selectedFilters: string[]
}

const getTechFeature = (
  siteConfiguration: SiteConfiguration | undefined,
  value: string,
) =>
  siteConfiguration?.technicalFeaturesMap?.find(
    (obj) => obj.shopifyId === value,
  )

export const TechFeaturesFilter: FC<Props> = ({
  className,
  onFilterChange,
  siteConfiguration,
  selectedFilters,
  filters,
}) => {
  if (!filters?.length) {
    return <NoFiltersInfoText />
  }

  const queryKey = filters[0].searchParamName

  return (
    <Root className={className}>
      {filters.map(({ value, count, label }) => {
        const techFeature = getTechFeature(siteConfiguration, label)
        const isSelected = selectedFilters?.includes(value)
        const HTML_ID = `${HTML_ID_PREFIX}_${value}`
        const isDisabled = Boolean(count === 0)
        if (!techFeature) {
          return null
        }

        return (
          <Fragment key={value}>
            <InputRadio
              type="checkbox"
              id={HTML_ID}
              value={label}
              onClick={() => onFilterChange(queryKey, value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  onFilterChange(queryKey, value)
                }
              }}
              checked={isSelected}
              disabled={isDisabled}
            />
            <LabelWithIcon
              htmlFor={HTML_ID}
              selected={isSelected}
              isDisabled={isDisabled}
            >
              <TechFeatureIconWrap>
                <Media {...techFeature.image} sizes={{ default: '42px' }} />
              </TechFeatureIconWrap>
              <div>{`${techFeature.label}`}</div>
            </LabelWithIcon>
          </Fragment>
        )
      })}
    </Root>
  )
}
