import { FC } from 'react'
import { SectionQuoteProps } from '@aether/models'
import { Container, SectionWrapper } from '@aether/components'
import { styled } from '@aether/styles'

const Wrap = styled('div', {
  variants: {
    showLine: {
      true: {
        borderLeft: '1px solid $black',
        paddingLeft: '$24',
      },
    },
  },
})
const Quote = styled('div', {
  $aetherFont: 'heading03',
})

const Author = styled('div', {
  $aetherFont: 'ui05',
  paddingTop: '$24',
})
export const SectionQuote: FC<SectionQuoteProps> = ({
  quote,
  author,
  spaceSize,
  showLine,
}) => {
  return (
    <SectionWrapper>
      <Container spaceSize={spaceSize}>
        <Wrap showLine={!!showLine}>
          {quote && <Quote>{quote}</Quote>}
          {author && <Author>- {author}</Author>}
        </Wrap>
      </Container>
    </SectionWrapper>
  )
}
