import { FC } from 'react'
import { SectionAboutProps } from '@aether/models'
import { styled } from '@aether/styles'
import { useInView } from 'react-intersection-observer'
import { Container, PortableText, SectionWrapper } from '@aether/components'
import { AnimatedLogo } from '@aether/footer'

const StyledContainer = styled(Container, {
  display: 'grid',
  gap: '$48',
  '@lg': {
    gridTemplateColumns: 'auto auto',
    gridRowGap: '$64',
    gridColumnGap: '$120',
  },
})
const Title = styled('h3', {
  $aetherFont: 'heading03',
  maxWidth: '600px',
  paddingLeft: '$24',
  borderLeft: '1px solid $black',
  '@lg': {
    gridColumn: 1,
    gridRow: 1,
  },
})
const Description = styled('div', {
  $aetherFont: 'body01',

  '@lg': {
    gridColumn: '2 / 3',
    gridRow: '1 / 3',
  },
})
const IconWrap = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  '@lg': {
    display: 'block',
    gridColumn: 1,
    gridRow: '2 / 3',
  },
})
export const SectionAbout: FC<SectionAboutProps> = ({ title, description }) => {
  const [logoRef, logoInView] = useInView({
    triggerOnce: true,
  })
  return (
    <SectionWrapper>
      <StyledContainer>
        <IconWrap>
          <AnimatedLogo ref={logoRef} animate={logoInView} />
        </IconWrap>
        {title && <Title>{title}</Title>}
        <Description>
          {description && (
            <PortableText value={description} withSpacing={false} />
          )}
        </Description>
      </StyledContainer>
    </SectionWrapper>
  )
}
