import { CollectionSearchParamsNames } from '@aether/collection'

export const updateCollectionSearchParams = (
  searchParams: URLSearchParams,
  searchParamName: CollectionSearchParamsNames,
  value: string,
  isSingleChoice?: boolean,
): URLSearchParams => {
  const currentValues = new URLSearchParams(searchParams).getAll(
    searchParamName,
  )

  // set new filter if no value is set up in query
  if (!currentValues.length) {
    const newSearchParams = new URLSearchParams(searchParams)

    newSearchParams.set(searchParamName, value)
    return newSearchParams
  }

  // remove value if already exists in query
  if (currentValues.includes(value)) {
    const newSearchParams = new URLSearchParams(searchParams)

    const newValues = currentValues.filter((v) => v !== value)
    newSearchParams.delete(searchParamName)
    newValues.forEach((val) => newSearchParams.append(searchParamName, val))
    return newSearchParams
  }

  // do not extend existing values if param is single choice
  if (isSingleChoice) {
    const newSearchParams = new URLSearchParams(searchParams)

    newSearchParams.delete(searchParamName)
    newSearchParams.set(searchParamName, value)
    return newSearchParams
  }

  // add new value to existing filters
  const newSearchParams = new URLSearchParams(searchParams)

  newSearchParams.append(searchParamName, value)
  return newSearchParams
}
