import { FC } from 'react'
import { styled } from '@aether/styles'
import { CategoryPostPreview } from '@aether/models'
import { Link, Media } from '@aether/components'

type PostItemProps = {
  post: CategoryPostPreview
}

const ImageWrap = styled('div', {
  marginBottom: '$16',
})

const PostHeading = styled('h4', {
  $aetherFont: 'heading04',
})

const Root = styled(Link, {
  margin: '$4',
  '&:focus-within': {
    outline: '$blue solid 3px',
    outlineOffset: '2px',
  },
})

export const PostItem: FC<PostItemProps> = ({
  post: { title, image, path },
}) => {
  return (
    <Root href={path} appearance="block">
      <ImageWrap>
        <Media
          {...image}
          hardcropRatio={'landscape1'}
          sizes={{ md: '50vw', xl: '33vw' }}
          withBackground
        />
      </ImageWrap>
      <PostHeading>{title}</PostHeading>
    </Root>
  )
}
