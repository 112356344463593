import { FC } from 'react'

import { Container, PortableText, SectionWrapper } from '@aether/components'
import { SectionRichTextProps } from '@aether/models'

export const SectionRichText: FC<SectionRichTextProps> = ({
  content,
  size,
}) => {
  return (
    <SectionWrapper>
      {/** adding the size check as this prop might be null */}
      <Container size={size || 'fullWidth'}>
        <PortableText value={content} withSpacing={false}></PortableText>
      </Container>
    </SectionWrapper>
  )
}
