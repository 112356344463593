import { Button, Divider, Modal } from '@aether/components'
import { Close, CloseCircle } from '@aether/icons'
import { styled, useMediaQuery } from '@aether/styles'
import { Fragment, useEffect, useState } from 'react'
import Div100vh from 'react-div-100vh'
import { renderKlaviyoReviewsWidgets } from './renderKlaviyoReviewsWidgets'
import { useTranslation } from 'react-i18next'

function ReviewsModal({ productId }: { productId: string }) {
  const matchesMd = useMediaQuery('md')
  const [isOpen, setIsOpen] = useState(false)
  const [node, setNode] = useState<HTMLElement | null>(null)
  const { t } = useTranslation('product')

  const [isImageOpen, setIsImageOpen] = useState(false)
  const [imageSrc, setImageSrc] = useState('')

  function adjustImageUrl(url: string) {
    let modifiedUrl = url.replace(/\/height:\d+\//, '/height:720/')
    modifiedUrl = modifiedUrl.replace(/\/width:\d+\//, '/width:680/')

    return modifiedUrl
  }

  useEffect(() => {
    if (node) {
      renderKlaviyoReviewsWidgets()

      const observer = new MutationObserver((mutations) => {
        // After the reviews widget is rendered, we clone the button for adding new review and append it
        // to the bottom of the modal to match the design. Original button is hidden.
        for (const mutation of mutations) {
          if (mutation.addedNodes.length) {
            const reviewButton = node.querySelector<HTMLElement>(
              '.kl_reviews__button',
            )

            if (reviewButton) {
              const clonedReviewButton = reviewButton.cloneNode(
                true,
              ) as HTMLElement
              clonedReviewButton.style.width = '100%'
              // We have to manually trigger the click event on the original button because `cloneNode` doesn't
              // copy the event listeners.
              clonedReviewButton.addEventListener('click', () => {
                reviewButton.click()
              })
              reviewButton.style.display = 'none'
              node.parentElement!.appendChild(clonedReviewButton)
            }

            const imagesContainer = node.querySelector(
              '.kl_reviews__list_container',
            )

            if (imagesContainer) {
              imagesContainer.addEventListener('click', (event) => {
                const clickedElement = event.target as HTMLElement
                const imageWrap = clickedElement?.closest(
                  '.kl_reviews__review__image_container',
                )
                const img = imageWrap?.querySelector('img')

                if (img) {
                  const imageSrc = img.getAttribute('src')
                  if (imageSrc) {
                    setImageSrc(adjustImageUrl(imageSrc))
                    setIsImageOpen(true)
                  }
                }
              })
            }

            observer.disconnect()
            break
          }
        }
      })

      observer.observe(node, { childList: true, subtree: true })

      return () => {
        observer.disconnect()
      }
    }
  }, [node])

  const closeButtonLabel = t('closeReviewsModalActionLabel')

  return (
    <Fragment>
      <OpenReviewsButton
        appearance="defaultLink"
        onClick={() => {
          setIsOpen(true)
        }}
        id="aether-open-reviews-modal-button"
      >
        <span>{t('showReviewsActionLabelEmpty')}</span>
        <span>{t('showReviewsActionLabel')}</span>
      </OpenReviewsButton>
      <Modal
        isOpen={isOpen}
        onRequestClose={() => {
          setIsOpen(false)
        }}
        size={matchesMd ? 'intrinsic' : 'stretch-x'}
        position={matchesMd ? 'right' : 'center'}
        transition="slide-from-right"
      >
        <ModalContent>
          <KlaviyoReviewsWrapper id="aether-klaviyo-reviews-list-wrapper">
            <Divider
              css={{
                marginTop: 0,
                marginBottom: 20,
              }}
            />

            <CloseButton
              type="button"
              onClick={() => {
                setIsOpen(false)
              }}
              aria-label={closeButtonLabel}
            >
              <CloseCircle />
            </CloseButton>
            <div
              id="klaviyo-reviews-list"
              data-id={productId.replace('gid://shopify/Product/', '')}
              ref={setNode}
            />
          </KlaviyoReviewsWrapper>
        </ModalContent>
      </Modal>
      <Modal
        isOpen={isImageOpen}
        onRequestClose={() => {
          setIsImageOpen(false)
        }}
        size="intrinsic"
        position="center"
        transition="fade"
      >
        <ImageModalContent>
          <CloseButtonImage
            type="button"
            onClick={() => {
              setIsImageOpen(false)
            }}
            aria-label={closeButtonLabel}
          >
            <Close />
          </CloseButtonImage>

          <ImageWrapper>
            <ReviewImage src={imageSrc} alt="Review image" />
          </ImageWrapper>
        </ImageModalContent>
      </Modal>
    </Fragment>
  )
}

export { ReviewsModal }

const ImageWrapper = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: 'fit-content',
})

const ReviewImage = styled('img', {
  width: '100%',
  maxHeight: 'calc(100dvh - 200px)',
})

const ImageModalContent = styled('div', {
  padding: '$32',
  backgroundColor: '$white',
  display: 'flex',
  justifyContent: 'center',
  minWidth: 'calc(100dvw - 48px)',
  minHeight: '65dvh',
  maxHeight: '785px',

  '@md': {
    padding: '$56',
    minWidth: '792px',
    maxWidth: '1000px',
    height: '90dvh',
    maxHeight: '832px',
  },
})

const CloseButtonImage = styled('button', {
  position: 'absolute',
  top: '$8',
  right: '$4',
  background: 'transparent',

  '@md': {
    top: '$24',
    right: '$24',
  },
})

const OpenReviewsButton = styled(Button, {
  display: 'none',
  position: 'relative',
  top: 2,
  $aetherFont: 'ui03',
  textTransform: 'uppercase',
  textDecoration: 'underline',
})

const ModalContent = styled(Div100vh, {
  width: '100%',

  '@md': {
    width: 'auto',
    minWidth: 479,
    maxWidth: 479,
  },
})

const CloseButton = styled('button', {
  position: 'absolute',
  top: 51,
  right: 24,

  '@md': {
    top: 72,
    right: 36,
  },

  display: 'flex',
  padding: 0,
  backgroundColor: 'transparent',
})

const KlaviyoReviewsWrapper = styled('div', {
  '&#aether-klaviyo-reviews-list-wrapper': {
    position: 'relative',
    padding: 24,

    '@md': {
      padding: '44px 36px',
    },

    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',

    '#klaviyo-reviews-list': {
      width: '100%',
      flex: '1 1 0%',
      padding: 0,
      margin: '0 0 24px 0',
      overflow: 'hidden',
    },

    '.reviews_all_container': {
      height: '100%',
      padding: 0,
    },

    '.kl_reviews__reviews_list': {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
    },

    '#kl_reviews_tabs__reviews_panel': {
      overflowY: 'auto',
      flex: '1 1 0%',
    },

    '.kl_reviews__list__tabs': {
      gap: 0,
      borderBottom: 0,
      marginBottom: 24,
    },

    '.kl_reviews__list__tab': {
      display: 'flex',
      gap: 16,
      alignItems: 'center',
      $aetherFont: 'heading03',
      padding: 0,
      cursor: 'default',
      pointerEvents: 'none',

      '&.kl_reviews__list__tab--active::after': {
        display: 'none',
      },

      '& small': {
        margin: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: 24,
        height: 24,
        $aetherFont: 'ui03',
        letterSpacing: 0,

        '@md': {
          $aetherFont: 'ui06',
          letterSpacing: 0,
        },

        color: '$black',
        border: '1px solid $black',
        borderRadius: '100%',
      },
    },

    '.kl_reviews__button': {
      margin: '0',
      backgroundColor: '$black',
      color: '$white',
      display: 'inline-flex',
      $aetherFont: 'ui02',
      textTransform: 'uppercase',
      borderRadius: '$r1',
      height: 42,
      padding: '0 $20',
      alignItems: 'center',
      justifyContent: 'center',
    },

    '.kl_reviews__list_container': {
      marginBottom: 0,
    },

    '.kl_reviews__filters': {
      display: 'none',
    },

    '.kl_reviews__review_item': {
      padding: 20,
      border: '1px solid $black',
      borderRadius: 25,
      marginBottom: 22,

      '& > div': {
        display: 'grid',
      },
    },

    '.kl_reviews__review__image_row': {
      display: 'flex',
      flexWrap: 'wrap',
    },

    '.kl_reviews__review__image_row :nth-child(n+4)': {
      display: 'none',
    },

    '.kl_reviews__review__image_container': {
      pointerEvent: 'none',
      display: 'flex',
      width: '$96',
    },

    '.kl_reviews__review__image_container img': {
      width: 'inherit',
    },

    '.kl_reviews__review__timestamp': {
      $aetherFont: 'ui03',
    },

    '.kl_reviews__review__title': {
      gridRow: 3,
      marginBottom: 4,
      $aetherFont: 'heading06',
    },

    '.kl_reviews__review__author': {
      marginBottom: 18,
      $aetherFont: 'ui03',
      color: '$black',
      textTransform: 'uppercase',
    },

    '.kl_reviews__review__verified': {
      fontSize: 0,

      '& path': {
        color: '$blue',
      },
    },

    '.kl_reviews__review__content,.kl_reviews__list_empty_state': {
      marginBottom: 0,
      $aetherFont: 'body03',
    },

    '.kl_reviews__load_more_button .kl_reviews__button': {
      boxSizing: 'border-box',
      display: 'inline-flex',
      fontFamily: 'var(--font-maison-neue-book), sans-serif',
      fontWeight: '400',
      letterSpacing: '0.1em',
      fontSize: '12px',
      textTransform: 'uppercase',
      lineHeight: '22px',
      borderRadius: '$r1',
      height: '42px',
      minWidth: '54px',
      padding: '0 32px',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
      transition: 'background-color 250ms ease 0s, color 250ms ease 0s',
      position: 'relative',
      overflow: 'hidden',
      backgroundColor: '$white',
      color: '$black',
      border: '1px solid $black',
    },
  },
})
