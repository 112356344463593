import { Container, Link, Media, StyledClickable } from '@aether/components'
import { SectionStoresLandingPageProps } from '@aether/models'
import { styled, useMediaQuery } from '@aether/styles'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

const SectionTitle = styled('h1', {
  $aetherFont: 'heading03',
  marginBottom: '$20',
  paddingTop: '$16',
  borderTop: '1px solid $black',
})
const StoreCardWrap = styled(Link, {
  marginBottom: '$sectionSpaceMobile',
  display: 'grid',
  color: '$white',
  '@lg': {
    marginBottom: '$sectionSpaceDesktop',
  },
})
const MediaWrap = styled('div', {
  '@lg': {
    gridColumn: 1,
    gridRow: 1,
  },
})

const StoreCardContent = styled('div', {
  zIndex: '$content',
  color: '$black',
  padding: '$12 0 0 0',
  '@lg': {
    color: '$white',
    gridColumn: 1,
    gridRow: 1,
    padding: '$40',
  },
})

const Title = styled('h2', {
  $aetherFont: 'heading02',
  textTransform: 'none',
  marginBottom: '$12',
})

export const SectionStoresLandingPage: FC<SectionStoresLandingPageProps> = ({
  stores,
}) => {
  const { t } = useTranslation('stores')
  const isLg = useMediaQuery('lg')
  return (
    <Container>
      <SectionTitle>{t('stores')}</SectionTitle>
      {stores?.map((store, index) => (
        <StoreCardWrap
          key={`${index}-${store.title}`}
          tabIndex={-1}
          href={store.path}
          appearance="grid"
        >
          <MediaWrap>
            {store.heroMedia && (
              <Media
                {...store.heroMedia}
                hardcropRatio={isLg ? undefined : 'portrait1'}
                sizes={{ default: '100vw' }}
                withBackground
              />
            )}
          </MediaWrap>
          <StoreCardContent>
            <Title>{store?.title}</Title>
            {store.path && (
              <StyledClickable appearance="primaryLightWhite">
                {t('viewStore')}
              </StyledClickable>
            )}
          </StoreCardContent>
        </StoreCardWrap>
      ))}
    </Container>
  )
}
