import { FC } from 'react'
import { SectionPageTitleProps } from '@aether/models'
import { Container, SectionWrapper } from '@aether/components'
import { styled } from '@aether/styles'

const Title = styled('h1', {
  $aetherFont: 'heading02',
  marginTop: '$48',
  marginBottom: '$72',
})

export const SectionPageTitle: FC<SectionPageTitleProps> = ({ title }) => {
  return (
    <SectionWrapper withBackground>
      <Container>{title && <Title>{title}</Title>}</Container>
    </SectionWrapper>
  )
}
