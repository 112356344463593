import { FC } from 'react'
import { JournalPictogram, PageLink } from '@aether/models'
import { styled } from '@aether/styles'
import { Container, Link } from '@aether/components'

const HEADER_TRANSITION_DURATION = 150

const Content = styled(Container, {
  flex: 1,
  display: 'grid',
  gridAutoColumns: '1fr auto',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
})

const ScrollIndicatorContainer = styled('div', {
  width: '100%',
  height: '6px',
  background: '$gray_light_third',
})

const ProgressBar = styled('div', {
  height: '6px',
  background: '$black',
  width: '0%',
  borderRadius: '0px 25px 25px 0px',
  transition: 'width 50ms linear',
})

const TitleContainer = styled('div', {
  display: 'grid',
  gridTemplateColumns: 'auto 1fr',
  gridColumn: '1',
  gridRow: '1',
})

const Title = styled('h4', {
  gridColumn: '2',
  gridRow: '1',
  $aetherFont: 'heading06',
  display: 'inline-block',
  marginRight: '$40',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  '@lg': {
    $aetherFont: 'heading04',
  },
})

const LinkWrap = styled('div', {
  gridColumn: '2',
  gridRow: '1',
})

const Wrap = styled('div', {
  justifyContent: 'space-between',
  backgroundColor: '$white',
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.1)',
  transition: `opacity ${HEADER_TRANSITION_DURATION}ms`,
  transform: 'translateY(0)',
  top: '$postSubheaderHeightNormal',
  '@lg': {
    top: '$postSubheaderHeightLarge',
  },
})

type DefaultHeaderProps = {
  pictogram?: JournalPictogram
  title?: string
  linkLabel?: string
  scrollPercent: number
  link?: PageLink
  hideShopTheProducts?: boolean
}

export const DefaultHeader: FC<DefaultHeaderProps> = ({
  title,
  link,
  linkLabel,
  scrollPercent,
  hideShopTheProducts = false,
}) => {
  return (
    <Wrap>
      <ScrollIndicatorContainer>
        <ProgressBar css={{ width: `${scrollPercent}%` }} />
      </ScrollIndicatorContainer>
      <Content>
        <TitleContainer>
          <Title>{title}</Title>
        </TitleContainer>
        {!hideShopTheProducts && (
          <LinkWrap>
            <Link appearance="primary" {...link}>
              {linkLabel}
            </Link>
          </LinkWrap>
        )}
      </Content>
    </Wrap>
  )
}
