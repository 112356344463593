import { FC } from 'react'
import { SectionBoldTextProps } from '@aether/models'
import { Container, Link, SectionWrapper } from '@aether/components'
import { styled } from '@aether/styles'
import { createDoubleLinksArray } from '@aether/utils'

const StyledContainer = styled(Container, {
  display: 'grid',
  gridGap: '$24',
  justifyItems: 'start',
})

const StyledLinksWrap = styled('div', {
  display: 'grid',
  gridGap: '$16',
  justifyItems: 'start',
})

const StyledText = styled('p', {
  $aetherFont: 'heading02',

  '@lg': {
    maxWidth: '690px',
  },
})

export const SectionBoldText: FC<SectionBoldTextProps> = ({
  text,
  ctaLink,
  ctaLabel,
  secondaryCtaLabel,
  secondaryCtaLink,
}) => {
  const links = createDoubleLinksArray(
    ctaLink,
    ctaLabel,
    secondaryCtaLink,
    secondaryCtaLabel,
  )

  return (
    <SectionWrapper>
      <StyledContainer spaceSize="medium">
        <StyledText>{text}</StyledText>

        <StyledLinksWrap>
          {links.length > 0 &&
            links.map(({ ctaLink, ctaLabel }, index) => (
              <Link
                {...ctaLink}
                appearance="primary"
                key={`${ctaLabel}-${index}`}
              >
                {ctaLabel}
              </Link>
            ))}
        </StyledLinksWrap>
      </StyledContainer>
    </SectionWrapper>
  )
}
