import { FC } from 'react'
import { styled } from '@aether/styles'
import { Link } from '@aether/components'
import { ROUTE_BASE_COLLECTIONS } from '@aether/configs'
import { ShopifyCollectionRes } from '@aether/models'

const List = styled('ul', {
  display: 'flex',
})

const ListItem = styled('li', {
  '&:not(:first-of-type)::before': {
    content: '/',
    display: 'inline-block',
    margin: '0 $4',
    $aetherFont: 'ui03',
  },
})

const StyledLink = styled(Link, {
  $aetherFont: 'ui03',
  padding: '0',
})

type BreadcrumbsType = {
  breadcrumbs?: ShopifyCollectionRes[]
}

export const ProductBreadcrumbs: FC<BreadcrumbsType> = ({ breadcrumbs }) => {
  if (!breadcrumbs?.length) return null

  return (
    <List>
      {breadcrumbs?.map((breadcrumb) => {
        return (
          <ListItem key={breadcrumb.title}>
            <StyledLink
              href={`${ROUTE_BASE_COLLECTIONS}/${breadcrumb.handle}`}
              appearance="defaultLink"
            >
              {breadcrumb?.breadcrumbTitle?.value || breadcrumb.title}
            </StyledLink>
          </ListItem>
        )
      })}
    </List>
  )
}
