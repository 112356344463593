import { FC } from 'react'
import {
  Media,
  PortableText,
  SectionWrapper,
  ShopifyDescriptionHTMLWrap,
} from '@aether/components'
import { styled } from '@aether/styles'
import { SectionCollectionHeroProps } from '@aether/models'

const Title = styled('h1', {
  $aetherFont: 'heading02',
})

const TextWrap = styled('div', {
  display: 'grid',
  gap: '$8',
  gridRow: 2,
  gridColumn: 2,
  justifySelf: 'start',
  alignSelf: 'end',
  variants: {
    theme: {
      light: {
        color: '$black',
      },
      dark: {
        color: '$white',
      },
    },
  },
})

const MobileDescription = styled('div', {
  $containerSpace: 'small',
  $containerSize: 'XL',
  marginTop: '$24',

  '@md': {
    display: 'none',
  },
})

const DesktopDescription = styled('div', {
  display: 'none',

  '@md': {
    display: 'block',
    maxWidth: '$collectionDesktopDescriptionWidth',
  },
})

const Line = styled('div', {
  gridRow: '1/3',
  gridColumn: 1,
  justifySelf: 'start',
  alignSelf: 'end',
  border: '1px solid currentColor',
  height: '100%',
  variants: {
    theme: {
      light: {
        color: '$black',
      },
      dark: {
        color: '$white',
      },
    },
  },
})

const Root = styled('div', {
  position: 'relative',
  display: 'grid',
  width: '100%',
  variants: {
    withBackground: {
      true: {
        minHeight: '50vh',
      },
    },
    withSplitHeader: {
      true: {
        gridTemplateRows: '1.6fr 1fr',
        '@lg': {
          gridTemplateColumns: '1fr 1fr',
          gridTemplateRows: 'auto',
        },
      },
    },
  },
})

const MediaWrap = styled('div', {
  gridRow: 1,
  gridColumn: 1,
})

const SplitHeaderWrapper = styled('div', {
  display: 'flex',
  flexDirection: 'column-reverse',
  $containerSpace: 'small',
  paddingTop: '$24',
  paddingBottom: '$24',

  variants: {
    theme: {
      light: {
        color: '$black',
      },
      dark: {
        color: '$white',
      },
    },
  },

  '@lg': {
    padding: '0 $72 $24 $48',
  },
})

const Overlay = styled('div', {
  gridColumnGap: '$24',
  width: '100%',
  gridRow: 1,
  gridColumn: 1,
  display: 'grid',
  zIndex: 1,
  justifyContent: 'start',
  paddingTop: '$20',
  paddingBottom: '$20',
  $containerSpace: 'small',
  $containerSize: 'XL',
})

export const SectionCollectionHero: FC<SectionCollectionHeroProps> = ({
  title,
  shopifyData,
  background,
  theme = 'dark',
  splitHeaderTheme,
  splitHeaderText,
  splitHeaderBackgroundColor,
  priority,
  isSplitHeader = false,
}) => {
  const collectionName = title || shopifyData?.collection?.title
  const description = shopifyData?.collection?.descriptionHtml

  return (
    <SectionWrapper withBackground={Boolean(background)}>
      <Root
        withBackground={Boolean(background)}
        withSplitHeader={isSplitHeader}
      >
        {background && (
          <MediaWrap>
            <Media
              {...background}
              layout="fill"
              priority={priority}
              hardcropRatio={
                isSplitHeader
                  ? {
                      '420': 'square',
                      '768': 'square',
                      '1024': 'square',
                      '1400': 'landscape1',
                      '1600': 'landscape1',
                      '1920': 'landscape1',
                      '2560': 'landscape1',
                    }
                  : {
                      '420': 'square',
                      '768': 'square',
                      '1024': 'square',
                      '1400': 'landscape1',
                      '1600': 'landscape3',
                      '1920': 'landscape3',
                      '2560': 'landscape3',
                    }
              }
              withBackground
            />
          </MediaWrap>
        )}

        <Overlay as="div">
          <Line theme={theme} />
          <TextWrap theme={theme}>
            <Title>{collectionName}</Title>

            {description && (
              <DesktopDescription>
                <ShopifyDescriptionHTMLWrap theme="collection">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: description,
                    }}
                  />
                </ShopifyDescriptionHTMLWrap>
              </DesktopDescription>
            )}
          </TextWrap>
        </Overlay>
        {isSplitHeader && (
          <SplitHeaderWrapper
            theme={splitHeaderTheme}
            style={{
              backgroundColor: splitHeaderBackgroundColor?.rgba || 'unset',
            }}
          >
            <PortableText
              value={splitHeaderText}
              withSpacing={false}
              lineSpacing="S"
            />
          </SplitHeaderWrapper>
        )}
      </Root>

      {description && (
        <MobileDescription>
          <ShopifyDescriptionHTMLWrap theme="collection">
            <div
              dangerouslySetInnerHTML={{
                __html: description,
              }}
            />
          </ShopifyDescriptionHTMLWrap>
        </MobileDescription>
      )}
    </SectionWrapper>
  )
}
