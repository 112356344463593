import {
  Container,
  Media,
  ProductsModalMediaWrap,
  SectionWrapper,
} from '@aether/components'
import { SectionDoubleImageProps } from '@aether/models'
import { styled } from '@aether/styles'
import { FC } from 'react'

const StyledContainer = styled(Container, {
  display: 'grid',
  gridTemplateRows: '1fr 1fr',
  gridTemplateColumns: '1fr',
  '@lg': {
    gridTemplateRows: '1fr',
    gridTemplateColumns: '1fr 1fr',
  },
})

export const SectionDoubleImage: FC<SectionDoubleImageProps> = ({
  leftImage,
  leftMediaShopifyIds,
  leftProductsButtonAppearance,
  rightImage,
  rightMediaShopifyIds,
  rightProductsButtonAppearance,
  sectionSpaceSize = 'small',
  imagesOrientation,
}) => {
  return (
    <SectionWrapper>
      <StyledContainer spaceSize={sectionSpaceSize}>
        <ProductsModalMediaWrap
          shopifyIds={leftMediaShopifyIds}
          appearance={leftProductsButtonAppearance}
        >
          <Media
            {...leftImage}
            hardcropRatio={
              imagesOrientation === 'portrait' ? 'portrait1' : 'landscape1'
            }
            sizes={{ lg: '50vw' }}
            withBackground
          />
        </ProductsModalMediaWrap>
        <ProductsModalMediaWrap
          shopifyIds={rightMediaShopifyIds}
          appearance={rightProductsButtonAppearance}
        >
          <Media
            {...rightImage}
            hardcropRatio={
              imagesOrientation === 'portrait' ? 'portrait1' : 'landscape1'
            }
            sizes={{ lg: '50vw' }}
            withBackground
          />
        </ProductsModalMediaWrap>
      </StyledContainer>
    </SectionWrapper>
  )
}
