export type SubscriptionType = 'MEN' | 'WOMEN' | 'SNOW' | 'MOTO'

export type SubscribtionData = {
  email: string
  categories?: SubscriptionType[]
}

export type ProfileData = {
  email: string
  properties: {
    Gender: SubscriptionType[]
    Category: SubscriptionType[]
  }
}

export type JoinWaitlistData = {
  email: string
  variantId: string
}

export const newsletterSubscribe = async (profile: SubscribtionData) => {
  return await fetch('/api/klaviyo/newsletter/subscribe', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(profile),
  })
}

export const checkSubscription = async (email: string) =>
  await fetch('/api/klaviyo/newsletter/check-subscription', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(email),
  })

export const createNewsletterProfile = async (profileData: ProfileData) => {
  return await fetch('/api/klaviyo/newsletter/create-profile', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(profileData),
  })
}

export const subscribeToBackInStock = async (
  joinWaitlistData: JoinWaitlistData,
) => {
  return await fetch('/api/klaviyo/back-in-stock', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(joinWaitlistData),
  })
}
