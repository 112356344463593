import { ShopifyProductCollectionSortKeys } from '@aether/shopify-sdk'
import { CollectionSearchParamsNames } from './models'

export const PRICE_FILTERS = {
  type: 'PRICE_RANGE',
  label: 'Price',
  id: 'price',
  values: [
    {
      count: 0,
      id: 'price.0-100',
      input: '{"price":{"min":0,"max":100}}',
      label: '$0 - $100',
    },
    {
      count: 0,
      id: 'price.100-250',
      input: '{"price":{"min":100,"max":250}}',
      label: '$100 - $250',
    },
    {
      count: 0,
      id: 'price.250-500',
      input: '{"price":{"min":250,"max":500}}',
      label: '$250 - $500',
    },
    {
      count: 0,
      id: 'price.over500',
      input: '{"price":{"min":500}}',
      label: 'over $500',
    },
  ],
}

export const SORT_FILTERS_VALUES: Record<
  string,
  { sortKey: ShopifyProductCollectionSortKeys; reverse: boolean }
> = {
  'sort.featured': {
    sortKey: ShopifyProductCollectionSortKeys.CollectionDefault,
    reverse: false,
  },
  'sort.newest': {
    sortKey: ShopifyProductCollectionSortKeys.Created,
    reverse: true,
  },
  'sort.lowHigh': {
    sortKey: ShopifyProductCollectionSortKeys.Price,
    reverse: false,
  },
  'sort.highLow': {
    sortKey: ShopifyProductCollectionSortKeys.Price,
    reverse: true,
  },
}

/**
 * This configuration map allows for customising the look of URL Search Params for each filter,
 * You need to set
 * - the filter type you want to configure,
 * - the search param and
 * - the name which will be used to prefix the final search param value
 */
export const FILTERS_GROUPS_MAP: Record<
  // the name of the filter under which it will be set in the value -  ie setting 'color' will result in  ?searchParamName=color.value
  string,
  {
    // the id of the filter we receive from shopify ie. filter.p.m.product.color
    shopifyFilterGroupId: string
    // the visible search param under which the filter is set - ie setting 'filters' will result in  ?filters=myFilter.value
    // this property will be used for replacing the default ugly shopifyFilterGroupId
    searchParamName: CollectionSearchParamsNames
  }
> = {
  sort: {
    shopifyFilterGroupId: 'sort',
    searchParamName: 'sort',
  },
  color_group: {
    shopifyFilterGroupId: 'filter.p.m.product.color_group',
    searchParamName: 'filters',
  },
  tech_features: {
    shopifyFilterGroupId: 'filter.p.m.product.tech_features',
    searchParamName: 'filters',
  },
  temperature: {
    shopifyFilterGroupId: 'filter.p.m.product.temperature',
    searchParamName: 'filters',
  },
  type_of_product: {
    shopifyFilterGroupId: 'filter.p.m.product.type_of_product',
    searchParamName: 'filters',
  },
  size: {
    shopifyFilterGroupId: 'filter.v.option.size',
    searchParamName: 'filters',
  },
  price: {
    shopifyFilterGroupId: 'price',
    searchParamName: 'filters',
  },
  activity: {
    shopifyFilterGroupId: 'filter.p.m.product.activity',
    searchParamName: 'filters',
  },
}
