import { MediumCheckIcon } from '@aether/icons'
import { PerformanceFeatureBaseValue } from '../PerformanceBaseFeature/PerformanceBaseFeature'
import { styled } from '@aether/styles'

const PerformanceLevelGrid = styled('div', {
  width: '100%',
  display: 'flex',
  columnGap: '$4',
  flexDirection: 'row',
})

const PerformanceLevelItem = styled('div', {
  width: '100%',
  marginTop: '$8',
})

const PerformanceLevelItemTitle = styled('span', {
  display: 'flex',
  alignItems: 'center',
  columnGap: '$4',
  $aetherFont: 'ui04',
  color: '$gray_medium',
  lineHeight: '15px',
  transition: '300ms ease-in-out',
  variants: {
    selected: {
      true: {
        color: '$black',
      },
      false: {
        color: '$gray_medium',
      },
    },
    isProductConversionArea: {
      true: {},
      false: {},
    },
  },
  compoundVariants: [
    {
      isProductConversionArea: true,
      selected: true,
      css: {
        '&:hover': {
          color: '$gray_medium',
        },
      },
    },
  ],
})
const PerformanceLevelItemValue = styled('div', {
  height: '$12',
  width: '100%',
  marginBottom: '$8',
  variants: {
    isProductConversionArea: {
      true: {
        backgroundColor: '$gray_light_third',
      },
      false: {
        backgroundColor: '$gray_light',
      },
    },
    selected: {
      true: {
        backgroundColor: '$black',
      },
    },
    inRange: {
      true: {
        backgroundColor: '$black',
      },
    },
    isFirst: {
      true: {
        borderTopLeftRadius: '10px',
        borderBottomLeftRadius: '10px',
      },
    },
    isLast: {
      true: {
        borderTopRightRadius: '10px',
        borderBottomRightRadius: '10px',
      },
    },
  },
  compoundVariants: [
    {
      isProductConversionArea: true,
      inRange: true,
      css: {
        backgroundColor: '$black',
      },
    },
    {
      isProductConversionArea: true,
      inRange: false,
      css: {
        backgroundColor: '$gray_light_third',
      },
    },
    {
      isProductConversionArea: false,
      inRange: true,
      css: {
        backgroundColor: '$black',
      },
    },
    {
      isProductConversionArea: false,
      inRange: false,
      css: {
        backgroundColor: '$black_light',
      },
    },
  ],
})

type PerformanceLevelItemsProps = {
  title?: string
  levels: PerformanceFeatureBaseValue[]
  type: 'ProductConversionArea' | 'TechnicalPerformance'
}

export const PerformanceLevelItems = ({
  title,
  levels,
  type,
}: PerformanceLevelItemsProps) => {
  return (
    <PerformanceLevelGrid>
      {levels.map((value, index) => (
        <PerformanceLevelItem
          key={`${title ?? `level-item-title-${index}`}-${value.title}`}
        >
          <PerformanceLevelItemValue
            isFirst={index === 0}
            isLast={index === levels.length - 1}
            inRange={value.inRange}
            isProductConversionArea={type === 'ProductConversionArea'}
          ></PerformanceLevelItemValue>
          <PerformanceLevelItemTitle
            selected={value.selected}
            isProductConversionArea={type === 'ProductConversionArea'}
          >
            {value.selected && <MediumCheckIcon />}
            {value.title}
          </PerformanceLevelItemTitle>
        </PerformanceLevelItem>
      ))}
    </PerformanceLevelGrid>
  )
}
