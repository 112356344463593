import { styled } from '@aether/styles'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

const NoFIltersInfoText = styled('span', {
  $aetherFont: 'ui02',
  paddingBottom: '$24',
  textAlign: 'center',
  width: '100%',
  display: 'block',
})

export const NoFiltersInfoText: FC = () => {
  const { t } = useTranslation('collection')

  return <NoFIltersInfoText>{t('noFiltersInfoText')}</NoFIltersInfoText>
}
