import { Close, MediumCheckIcon } from '@aether/icons'
import { Modal } from '@aether/components'
import { styled, useMediaQuery } from '@aether/styles'
import { TemperatureRangeTechSection } from '../../SectionTechnicalSpecifications/TemperatureRangeTechSection/TemperatureRangeTechSection'
import { useTranslation } from 'react-i18next'
import { PerformanceTemperatureValue } from './PerformanceTemperatureFeature'

const ShowTechnicalFeaturesModal = styled('div', {
  position: 'relative',
  padding: '$48 $24 $72',
  maxWidth: '650px',

  '@md': {
    padding: '$48 $64',
  },
})

const PerformanceLevel = styled('div', {
  marginTop: '$28',
})

const PerformanceLevelList = styled('ul', {
  marginTop: '$32',
})

const PerformanceLevelDescription = styled('p', {
  $aetherFont: 'body02',
  marginTop: '$24',
})

const PerformanceLevelListItem = styled('li', {
  $aetherFont: 'ui02',
  display: 'flex',
  alignItems: 'center',
  gap: '$8',
  marginTop: '$12',

  variants: {
    selected: {
      true: {
        fontWeight: '700',
      },
    },
  },
})

const StyledCloseModal = styled('button', {
  background: 'transparent',
  position: 'absolute',
  top: '$16',
  right: '$14',
})

const IconPlaceholder = styled('div', {
  width: '15px',
  height: '15px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '9999px',
  background: '$black_light',

  variants: {
    isHover: {
      true: {
        transition: 'all 300ms ease',
        '&:hover': {
          cursor: 'pointer',
          background: '$black',
          '& > svg > path': {
            fill: '$white',
          },
        },
      },
    },
  },
})

const PerformanceLevelTemp = styled('div', {})

type PerformanceFeaturesModalProps = {
  isOpen: boolean
  handleClose: () => void
  title?: string
  description?: string
  values?: PerformanceTemperatureValue[]
}

export const PerformanceTemperatureModal = ({
  isOpen,
  handleClose,
  title,
  description,
  values,
}: PerformanceFeaturesModalProps) => {
  const { t } = useTranslation('product')
  const matchesMd = useMediaQuery('md')
  return (
    <Modal
      preventScroll={true}
      isOpen={isOpen}
      size={matchesMd ? 'intrinsic' : 'stretch-x'}
      overlayVariant={'darker'}
      onRequestClose={handleClose}
    >
      <ShowTechnicalFeaturesModal>
        <StyledCloseModal
          onClick={handleClose}
          aria-label={t('closeModal') ?? ''}
        >
          <Close size="medium" />
        </StyledCloseModal>
        <PerformanceLevel>
          <PerformanceLevelTemp>
            {(() => {
              const selectedValue = values?.find((item) => item.selected)
              return (
                <TemperatureRangeTechSection
                  title={title}
                  selectedMinTemp={selectedValue?.minTempValue ?? 0}
                  selectedMaxTemp={selectedValue?.maxTempValue ?? 0}
                  isProductConversionArea={false}
                />
              )
            })()}
          </PerformanceLevelTemp>

          <PerformanceLevelList>
            {values?.map((value) => (
              <PerformanceLevelListItem
                key={value.title}
                selected={value.selected}
              >
                {value.selected ? <MediumCheckIcon /> : <IconPlaceholder />}
                {value.title} - {value.subtitle}
              </PerformanceLevelListItem>
            ))}
          </PerformanceLevelList>
          <PerformanceLevelDescription>
            {description}
          </PerformanceLevelDescription>
        </PerformanceLevel>
      </ShowTechnicalFeaturesModal>
    </Modal>
  )
}
