import { styled } from '@aether/styles'
import { MediumCheckIcon } from '@aether/icons'
import { PerformanceFeatureBaseValue } from '../../SectionProductConversionArea/PerformanceBaseFeature/PerformanceBaseFeature'
import { PerformanceLevelItems } from '../../SectionProductConversionArea/PerformanceLevelItems/PerformanceLevelItems'

const PerformanceLevel = styled('div', {})

const PerformanceLevelLabel = styled('h3', {
  $aetherFont: 'heading05',
  marginBottom: '$16',
})
const PerformanceLevelList = styled('ul', {
  marginTop: '$32',
})
const PerformanceLevelListItem = styled('li', {
  $aetherFont: 'ui02',
  display: 'flex',
  alignItems: 'center',
  gap: '$8',
  marginTop: '$12',
  textTransform: 'uppercase',

  variants: {
    selected: {
      true: {
        fontWeight: '700',
      },
    },
  },
})

const IconPlaceholder = styled('div', {
  width: '15px',
  height: '15px',
  display: 'block',
  borderRadius: '9999px',
  background: '$black_light',
})

const PerformanceLevelDescription = styled('p', {
  $aetherFont: 'body02',
  marginTop: '$24',
})
type PerformanceBaseFeatureProps = {
  title?: string
  description?: string
  values: PerformanceFeatureBaseValue[]
}
export const PerformanceBaseFeature = ({
  title,
  description,
  values,
}: PerformanceBaseFeatureProps) => {
  return values.length !== 0 ? (
    <div>
      <PerformanceLevel>
        <PerformanceLevelLabel>{title}</PerformanceLevelLabel>
        <PerformanceLevelItems
          title={title}
          levels={values}
          type="TechnicalPerformance"
        />
        <PerformanceLevelList>
          {values.map((item) => (
            <PerformanceLevelListItem key={item.title} selected={item.selected}>
              {item.selected ? <MediumCheckIcon /> : <IconPlaceholder />}
              {item.title} - {item.subtitle}
            </PerformanceLevelListItem>
          ))}
        </PerformanceLevelList>
        <PerformanceLevelDescription>{description}</PerformanceLevelDescription>
      </PerformanceLevel>
    </div>
  ) : null
}
