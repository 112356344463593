import { FC } from 'react'
import {
  Container,
  Link,
  Media,
  PortableText,
  SectionWrapper,
} from '@aether/components'
import { styled } from '@aether/styles'
import { SectionPostPreviewProps } from '@aether/models'
import { useTranslation } from 'react-i18next'

const Wrap = styled('div', {
  width: '100%',
  display: 'grid',
  variants: {
    pageContext: {
      journal: {
        backgroundColor: '$beige',
      },
      default: {
        backgroundColor: '$white',
      },
    },
  },
})
const LinkOverlay = styled(Link, {
  gridColumn: '1',
  gridRow: '1 / -1',
  zIndex: '$content',
})

const MediaWrap = styled('div', {
  variants: {
    imagePosition: {
      left: {
        gridArea: 'left',
      },
      right: {
        gridArea: 'left',
        '@lg': {
          gridArea: 'right',
        },
      },
    },
  },
})
const ContainerWrap = styled(Container, {
  width: '100%',
  padding: '$24 0',
  gridColumn: '1',
  gridRow: '1 / -1',
  '@lg': { padding: '$48 0' },
})

const ContentWrap = styled('div', {
  display: 'grid',
  gridGap: '$56',
  width: '100%',
  height: '100%',

  gridTemplateAreas: `
                      'left'
                      'right'
                      `,
  '@lg': {
    gridTemplateAreas: `
    'left right'
    `,
    gridTemplateColumns: '11fr 13fr',
  },
  variants: {
    imagePosition: {
      left: {},
      right: {},
    },
    imageOrientation: {
      landscape: {},
      portrait: {},
    },
  },
  compoundVariants: [
    {
      imagePosition: 'left',
      imageOrientation: 'landscape',
      css: {
        '@lg': {
          gridTemplateColumns: '13fr 11fr',
        },
      },
    },
    {
      imagePosition: 'right',
      imageOrientation: 'landscape',
      css: {
        '@lg': {
          gridTemplateColumns: '11fr 13fr',
        },
      },
    },
    {
      imagePosition: 'left',
      imageOrientation: 'portait',
      css: {
        '@lg': {
          gridTemplateColumns: '11fr 13fr',
        },
      },
    },
    {
      imagePosition: 'right',
      imageOrientation: 'portrait',
      css: {
        '@lg': {
          gridTemplateColumns: '13fr 11fr',
        },
      },
    },
  ],
})
const Title = styled('h2', {
  $aetherFont: 'heading02',
})
const ColumnWithText = styled('div', {
  display: 'flex',
  gap: '$36',
  alignItems: 'flex-start',
  justifyContent: 'space-between',
  flexDirection: 'column',
  paddingLeft: '$12',
  borderLeft: '1px solid $black',
  '@lg': {
    paddingLeft: 0,
    borderLeft: 0,
  },
  variants: {
    imagePosition: {
      left: {
        gridArea: 'right',
        paddingLeft: '$12',
        borderLeft: '1px solid $black',
        '@lg': {
          borderLeft: 0,
          paddingLeft: 0,
          paddingRight: '$32',
          borderRight: '1px solid $black',
        },
      },
      right: {
        paddingLeft: '$12',
        gridArea: 'right',
        '@lg': {
          gridArea: 'left',
          paddingLeft: '$32',
          borderLeft: '1px solid $black',
        },
      },
    },
  },
})
const ShortDescription = styled('div', {
  $aetherFont: 'body02',
})
const TopWrap = styled('div', {
  display: 'grid',
  gap: '$20',
})

const ReadStoryLink = styled(Link, {
  width: 'fit-content',
  zIndex: '$journalLink',
})

const CategoryLink = styled(Link, {
  zIndex: '$journalLink',
})

const BottomWrap = styled('div', {
  display: 'flex',
  gap: '$24',
  alignItems: 'center',
})
const DateWrap = styled('div', {
  $aetherFont: 'ui05',
})
const CategoriesWrap = styled('div', {
  display: 'none',
  '@lg': {
    display: 'flex',
    ':not(:last-child)': {
      marginRight: '$12',
    },
  },
})

export const SectionPostPreview: FC<SectionPostPreviewProps> = ({
  post,
  pageContext,
  imagePosition,
}) => {
  const title = post?.title
  const media = post?.background
  const shortDescription = post?.shortDescription
  const imageOrientation = post?.imageOrientation
  const path = post?.path
  const date = post?.date
  const categories = post?.categories
  const ctaLabel = post?.ctaLabel
  const imagePositionWithDefault = imagePosition || 'left'

  const { t } = useTranslation('journal')
  return (
    <SectionWrapper withBackground>
      <Wrap pageContext={pageContext}>
        <LinkOverlay href={path} appearance={'block'} tabIndex={-1} />

        <ContainerWrap spaceSize="small">
          <ContentWrap
            imagePosition={imagePositionWithDefault}
            imageOrientation={imageOrientation}
          >
            <MediaWrap imagePosition={imagePositionWithDefault}>
              {media && (
                <Media
                  {...media}
                  sizes={{ lg: '50vw' }}
                  hardcropRatio={
                    imageOrientation === 'portrait' ? 'portrait1' : 'landscape1'
                  }
                  withBackground
                />
              )}
            </MediaWrap>

            <ColumnWithText imagePosition={imagePositionWithDefault}>
              <TopWrap>
                {title && <Title>{title}</Title>}
                {shortDescription && (
                  <ShortDescription>
                    <PortableText
                      value={shortDescription}
                      withStyles={true}
                      fontSize={'S'}
                      withSpacing={false}
                    />
                  </ShortDescription>
                )}
                {path && (
                  <ReadStoryLink appearance="primary" href={path}>
                    {ctaLabel || t('readStory')}
                  </ReadStoryLink>
                )}
              </TopWrap>

              <BottomWrap>
                {date && (
                  <DateWrap>
                    {new Date(date).toLocaleDateString('en-US', {
                      month: 'long',
                      day: 'numeric',
                      year: 'numeric',
                    })}
                  </DateWrap>
                )}
                <CategoriesWrap>
                  {categories?.map((category, index) => (
                    <CategoryLink
                      href={category.path}
                      appearance={
                        pageContext === 'journal' ? 'badgeWhite' : 'badgeGray'
                      }
                      key={`${category.title}-${index}`}
                    >
                      {category.title}
                    </CategoryLink>
                  ))}
                </CategoriesWrap>
              </BottomWrap>
            </ColumnWithText>
          </ContentWrap>
        </ContainerWrap>
      </Wrap>
    </SectionWrapper>
  )
}
