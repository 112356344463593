import { getSDKShopifyCollection } from '@aether/services/shopify-service'
import { buildResolvedFilters } from './utils/buildResolvedFilters'
import { LocaleId } from '@aether/configs'
import {
  ShopifyCollectionFiltersFieldsFragment,
  ShopifyProductFilter,
} from '@aether/shopify-sdk'
import { getShopifyFilters } from './utils/getShopifyFilters'
import { getShopifySortVariables } from './utils/getShopifySortVariables'

const createVipFilter = (isVip: boolean): ShopifyProductFilter => {
  return {
    productMetafield: {
      namespace: 'product',
      key: 'vip_product',
      value: isVip ? 'true' : 'false',
    },
  }
}

export const getInfiniteCollection = async (
  handle: string,
  searchParams: URLSearchParams,
  locale: LocaleId,
  isVip: boolean,
  initialCollectionFilters?: ShopifyCollectionFiltersFieldsFragment['products']['filters'],
  afterCursor?: string,
) => {
  const initialFilters = buildResolvedFilters(initialCollectionFilters || [])
  const searchParamFilters = getShopifyFilters(searchParams, initialFilters)
  const vipFilter = createVipFilter(isVip)
  const filters = [...searchParamFilters, vipFilter]
  const sortParams = getShopifySortVariables(searchParams)

  const collection = await getSDKShopifyCollection({
    locale,
    handle,
    filters,
    afterCursor: afterCursor,
    ...sortParams,
  })
  const endCursor = collection?.products.pageInfo.endCursor
  const hasNextPage = collection?.products.pageInfo.hasNextPage

  return {
    collection,
    endCursor,
    hasNextPage,
  }
}
