import { FC } from 'react'
import { SectionSingleImageWithDoubleTextProps } from '@aether/models'
import { Container, Media, SectionWrapper } from '@aether/components'
import { styled } from '@aether/styles'

const StyledContainer = styled(Container, {
  display: 'grid',
  rowGap: '$72',
  width: '100%',
  height: '100%',

  '@lg': {
    gridTemplateColumns: '1fr 1fr',
    columnGap: '$72',
    rowGap: '$96',
  },

  variants: {
    backgroundColor: {
      default: {
        background: 'transparent',
      },
      beige: {
        background: '$beige',
        paddingTop: '$sectionSpaceMobile',
        paddingBottom: '$sectionSpaceMobile',
        '@lg': {
          paddingTop: '$sectionSpaceDesktop',
          paddingBottom: '$sectionSpaceDesktop',
        },
      },
    },
  },
})

const StyledBigText = styled('p', {
  gridColumn: '1 / 3',
  $aetherFont: 'heading03',

  '@lg': {
    gridColumn: '1 / 2',
    $aetherFont: 'heading02',
  },
})

const StyledSmallText = styled('p', {
  gridColumn: '1 / 3',
  $aetherFont: 'heading04',

  '@lg': {
    gridColumn: '2 / 3',
  },
})

const StyledMediaWrap = styled('div', {
  gridColumn: '1 / 3',
  variants: {
    textPosition: {
      top: {
        gridRow: '1/2',
      },
      bottom: {
        gridRow: '3/4',
        '@lg': {
          gridRow: '2/3',
        },
      },
    },
  },
})

export const SectionSingleImageWithDoubleText: FC<
  SectionSingleImageWithDoubleTextProps
> = ({ smallText, bigText, media, backgroundColor, textPosition }) => {
  return (
    <SectionWrapper withBackground={backgroundColor === 'beige'}>
      <StyledContainer spaceSize="medium" backgroundColor={backgroundColor}>
        {bigText && <StyledBigText>{bigText}</StyledBigText>}
        {smallText && <StyledSmallText>{smallText}</StyledSmallText>}
        {media && (
          <StyledMediaWrap textPosition={textPosition}>
            <Media {...media} sizes={{ default: '100vw' }} withBackground />
          </StyledMediaWrap>
        )}
      </StyledContainer>
    </SectionWrapper>
  )
}
