import { QuestionMark } from '@aether/icons'
import { styled } from '@aether/styles'
import { useState } from 'react'
import { PerformanceFeatureModal } from './PerformanceFeatureModal'
import { PerformanceLevelItems } from '../PerformanceLevelItems/PerformanceLevelItems'

const PerformanceLevel = styled('div', {
  marginTop: '$28',
  cursor: 'pointer',
})
const PerformanceLevelLabel = styled('h3', {
  $aetherFont: 'ui01',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  columnGap: '$8',
})

const IconPlaceholder = styled('div', {
  width: '15px',
  height: '15px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '9999px',
  background: '$gray_light_third',

  variants: {
    isHover: {
      true: {
        transition: 'all 300ms ease',
        '&:hover': {
          cursor: 'pointer',
          background: '$black',
          '& > svg > path': {
            fill: 'white',
          },
        },
      },
    },
  },
})

export type PerformanceFeatureBaseValue = {
  title?: string
  subtitle?: string
  selected: boolean
  inRange: boolean
}
type PerformanceBaseFeatureProps = {
  title?: string
  description?: string
  values: PerformanceFeatureBaseValue[]
}

export const PerformanceBaseFeature = ({
  title,
  description,
  values,
}: PerformanceBaseFeatureProps) => {
  const [featureDetailsModalOpen, setFeatureDetailsModalOpen] = useState(false)

  return values.length !== 0 ? (
    <>
      <PerformanceLevel onClick={() => setFeatureDetailsModalOpen(true)}>
        <PerformanceLevelLabel>
          {title}
          <IconPlaceholder isHover>
            <QuestionMark fill="currentColor" />
          </IconPlaceholder>
        </PerformanceLevelLabel>
        <PerformanceLevelItems
          title={title}
          levels={values}
          type="ProductConversionArea"
        />
      </PerformanceLevel>
      <PerformanceFeatureModal
        title={title}
        description={description}
        isOpen={featureDetailsModalOpen}
        handleClose={() => setFeatureDetailsModalOpen(false)}
        values={values}
      />
    </>
  ) : null
}
