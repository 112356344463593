import { FC } from 'react'
import { SectionTripleImageProps } from '@aether/models'
import { styled } from '@aether/styles'
import { Container, Media, SectionWrapper } from '@aether/components'

const StyledContainer = styled(Container, {
  display: 'grid',
  gap: '$24',
  '@lg': {
    gridTemplateColumns: '1fr 1fr 1fr',
  },
})
export const SectionTripleImage: FC<SectionTripleImageProps> = ({
  spaceSize,
  images,
}) => {
  return (
    <SectionWrapper>
      <StyledContainer spaceSize={spaceSize}>
        {images?.map((image, index) => (
          <Media
            key={`${index}-${image.alt}`}
            {...image}
            sizes={{ lg: '33vw', default: '100vw' }}
            withBackground
          />
        ))}
      </StyledContainer>
    </SectionWrapper>
  )
}
