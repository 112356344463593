import { LocaleId } from '@aether/configs'
import { useInfiniteQuery, useQueryClient } from 'react-query'
import { getSDKShopifyCollectionFilters } from '@aether/services/shopify-service'
import { queryKeys } from '@aether/utils'
import { getInfiniteCollection } from './getInfiniteCollection'
import { ShopifyResolvedListProduct } from '@aether/shopify-sdk'
import { buildResolvedFilters } from './utils/buildResolvedFilters'
import { useCustomerContext } from '@aether/account/utils-customer-context'

const useInitialFilters = (handle: string) => {
  const queryClient = useQueryClient()
  return (
    queryClient.getQueriesData<
      Awaited<ReturnType<typeof getSDKShopifyCollectionFilters>>
    >({
      queryKey: queryKeys.plpInitialFilters(handle),
    })?.[0]?.[1]?.filters || []
  )
}

export const useCollectionProducts = ({
  locale,
  handle,
  searchParams,
}: {
  locale: LocaleId
  handle: string
  searchParams?: URLSearchParams
}) => {
  const { userRole } = useCustomerContext()
  const isVip = userRole === 'vip'
  const filters = useInitialFilters(handle)

  const filtersQueryString = searchParams?.toString() || ''

  const {
    data: productsData,
    hasNextPage,
    fetchNextPage,
    isFetching,
  } = useInfiniteQuery(
    queryKeys.plpProducts(handle, filtersQueryString),
    ({ pageParam }) =>
      getInfiniteCollection(
        handle,
        new URLSearchParams(filtersQueryString),
        locale,
        isVip,
        filters,
        pageParam,
      ),
    {
      getNextPageParam: (lastPage) => {
        return lastPage?.endCursor ?? null
      },
      keepPreviousData: true,
      staleTime: 1000 * 60 * 150,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    },
  )

  const products =
    productsData?.pages.reduce<ShopifyResolvedListProduct[]>(
      (acc, page) => [...acc, ...(page?.collection?.products.nodes || [])],
      [],
    ) || []

  const latestFilters =
    productsData?.pages[productsData?.pages.length - 1]?.collection?.products
      .filters || []

  const lastPageProducts =
    productsData?.pages[productsData?.pages.length - 1]?.collection?.products
      .nodes || []

  const resolvedFilters = buildResolvedFilters(latestFilters)

  return {
    products,
    filters: latestFilters,
    resolvedFilters,
    lastPageProducts,
    hasNextPage,
    fetchNextPage,
    isFetching,
  }
}
