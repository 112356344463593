import { FC } from 'react'
import { SectionHeroLandingProps } from '@aether/models'
import { Container, Link, Media, SectionWrapper } from '@aether/components'
import { styled } from '@aether/styles'

const Wrap = styled('div', {
  width: '100%',
  $posterHeight: true,
  display: 'grid',
  variants: {
    heroTextTheme: {
      light: {
        color: '$white',
      },
      dark: {
        color: '$black',
      },
    },
  },
})

const MediaWrap = styled('div', {
  width: '100%',
  gridColumn: 1,
  gridRow: 1,
})

const StyledContainer = styled(Container, {
  width: '100%',
  zIndex: '$content',
  gridColumn: 1,
  gridRow: 1,
  padding: '$64 0',
})

const ContentWrap = styled('div', {
  display: 'grid',
  width: '100%',
  height: '100%',
  paddingLeft: '$24',
  borderLeft: '1px solid',
})
const Title = styled('h1', {
  $aetherFont: 'heading02',
})

const LinkWrap = styled('div', {
  justifyItems: 'start',
  alignSelf: 'end',
})

export const SectionHeroLanding: FC<SectionHeroLandingProps> = ({
  title,
  background,
  ctaLabel,
  ctaLink,
  heroTextTheme,
}) => {
  return (
    <SectionWrapper withBackground>
      <Wrap heroTextTheme={heroTextTheme}>
        <MediaWrap>
          {background && (
            <Media
              {...background}
              layout="fill"
              sizes={{ default: '100vw' }}
              withBackground
            />
          )}
        </MediaWrap>
        {(title || ctaLink) && (
          <StyledContainer>
            <ContentWrap>
              {title && <Title>{title}</Title>}
              <LinkWrap>
                {ctaLink && (
                  <Link {...ctaLink} appearance="primary">
                    {ctaLabel}
                  </Link>
                )}
              </LinkWrap>
            </ContentWrap>
          </StyledContainer>
        )}
      </Wrap>
    </SectionWrapper>
  )
}
