import { FC, useState } from 'react'
import { SectionCollectionFilteringProps } from '@aether/models'
import { Modal } from '@aether/components'
import { styled, useMediaQuery } from '@aether/styles'
import { useRouter } from 'next/router'
import {
  ROUTE_BASE_COLLECTIONS,
  ROUTE_FILTERED_COLLECTIONS,
} from '@aether/configs'
import { NavigationHeader } from './NavigationHeader'
import { FiltersPicker } from './FiltersPicker'
import { FilteredProductsList } from './FilteredProductsList'
import { FiltersButton } from './FiltersButton'

const MobileAbsoluteButton = styled('div', {
  position: 'fixed',
  bottom: '$24',
  left: '50%',
  transform: 'translateX(-50%)',
  zIndex: '$content',
  '@md': {
    display: 'none',
  },
})

export const SectionCollectionFiltering: FC<
  SectionCollectionFilteringProps
> = ({ shopifyData, context, siteConfiguration, navigationLinks }) => {
  const collectionHandle = shopifyData?.collection?.handle || ''

  const isSm = useMediaQuery('sm')

  const [filterModalOpen, setFilterModalOpen] = useState(false)
  const closeModal = () => {
    setFilterModalOpen(false)
  }

  const router = useRouter()

  const clearFilters = () => {
    router.replace(`${ROUTE_BASE_COLLECTIONS}/${collectionHandle}`)
  }

  const applyFilters = (searchParams: URLSearchParams) => {
    setFilterModalOpen(false)
    switch (context) {
      case 'collectionPage': {
        router.push({
          pathname: router.asPath + ROUTE_FILTERED_COLLECTIONS,
          search: searchParams.toString(),
        })
        break
      }
      case 'filterPage': {
        router.replace(
          {
            search: searchParams.toString(),
          },
          undefined,
          {
            shallow: true,
          },
        )
        break
      }
    }
  }

  return (
    <>
      <NavigationHeader
        navigationLinks={navigationLinks}
        openFilters={() => setFilterModalOpen(true)}
      />

      <MobileAbsoluteButton>
        <FiltersButton
          openFilters={() => setFilterModalOpen(true)}
          theme={'dark'}
        />
      </MobileAbsoluteButton>

      {context === 'filterPage' ? (
        <FilteredProductsList
          shopifyData={shopifyData}
          siteConfiguration={siteConfiguration}
        />
      ) : null}
      <Modal
        preventScroll={true}
        isOpen={filterModalOpen}
        onRequestClose={closeModal}
        position={'right'}
        transition={'slide-from-right'}
        size={isSm ? 'intrinsic' : 'stretch'}
      >
        <FiltersPicker
          onClose={() => setFilterModalOpen(false)}
          siteConfiguration={siteConfiguration}
          shopifyData={shopifyData}
          applyFilters={applyFilters}
          clearFilters={clearFilters}
        />
      </Modal>
    </>
  )
}
