import { FC } from 'react'
import { styled } from '@aether/styles'
import { CategoryPostPreview } from '@aether/models'
import { PostItem } from './PostItem'

type PostsGalleryProps = {
  posts: CategoryPostPreview[]
  className?: string
}

const mobileSlideWidth = '88%'
const tabletSlideWidth = '45%'

const Slider = styled('ul', {
  display: 'grid',
  gridTemplateColumns: `auto repeat(3, minmax(${mobileSlideWidth}, 1fr))  auto`,
  width: '100vw',
  overflowX: 'scroll',
  overflowY: 'auto',
  scrollSnapType: 'x mandatory',
  scrollBehavior: 'smooth',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
  $lin: {
    scrollPaddingLeft: 'CONTAINER_SPACE_M',
  },
  '@md': {
    gridTemplateColumns: `auto repeat(3, minmax(${tabletSlideWidth}, 1fr)) auto`,
  },
  '@xl': {
    gridTemplateColumns: 'auto repeat(3, minmax(0, 1fr)) auto',
  },
})

const Slide = styled('li', {
  scrollSnapAlign: 'start',
  height: '100%',
  marginRight: '$16',
  '@lg': {
    marginRight: '$32',
  },
  '@xl': {
    marginRight: '$56',
  },
})

const Spacer = styled('li', {
  $lin: {
    paddingLeft: 'CONTAINER_SPACE_S',
  },
  variants: {
    isLast: {
      true: {
        marginLeft: '-$16',
        '@lg': {
          marginLeft: '-$32',
        },
        '@xl': {
          marginLeft: '-$56',
        },
      },
    },
  },
})

export const PostsGallery: FC<PostsGalleryProps> = ({ posts, className }) => {
  return (
    <Slider className={className}>
      <Spacer />
      {posts.map((post) => (
        <Slide key={post.path}>
          <PostItem post={post} />
        </Slide>
      ))}
      <Spacer isLast={true} />
    </Slider>
  )
}
