import { FC, PropsWithChildren } from 'react'
import {
  SectionWrapper,
  Container,
  Media,
  Link,
  PortableText,
} from '@aether/components'
import { PageLink, SectionDoubleMediaProps } from '@aether/models'
import { styled } from '@aether/styles'

const StyledContainer = styled(Container, {
  display: 'grid',
  gridTemplateRows: 'auto auto',
  gridTemplateColumns: '1fr',
  '@lg': {
    gridTemplateRows: '1fr',
    gridTemplateColumns: '1fr 1fr',
  },
})

const SubSectionContainer = styled('div', {
  display: 'grid',
  gridTemplateColumns: '1fr',
  height: '100%',
  width: '100%',
  '@lg': {
    $posterHeight: false,
  },
  variants: {
    mobilePosterHeight: {
      true: {
        $posterHeight: true,
      },
      false: {
        $posterHeight: false,
      },
    },
  },
})

const Background = styled('div', {
  width: '100%',
  height: '100%',
})

const ContentContainer = styled('article', {
  zIndex: 1,
  gridColumn: '1/2',
  gridRow: '1/2',
  rowGap: '$16',
  display: 'grid',
  $aetherFont: 'body02',
  gridAutoRows: 'min-content',
  margin: '$32 $24',
  gridTemplateColumns: '0.9fr 0.1fr',
  '@md': {
    gridTemplateColumns: '0.75fr 0.25fr',
    margin: '$48',
    rowGap: '$20',
  },
  variants: {
    appearance: {
      light: {
        color: '$white',
      },
      dark: {
        color: '$black',
      },
    },
    border: {
      top: {
        borderTop: '1px solid currentcolor',
        padding: '$16 0 0 0',
      },
      left: {
        borderLeft: '1px solid currentcolor',
        padding: '0 0 0 $16',
      },
      none: {},
    },
    isContentNonInteractive: {
      true: {
        pointerEvents: 'none',
      },
    },
  },
})

const Title = styled('h2', {
  $aetherFont: 'heading03',
  gridColumn: '1/1',
})

const CaptionWrap = styled('div', {
  gridColumn: '1/1',
})

const StyledLink = styled(Link, {
  justifySelf: 'flex-start',
  gridColumn: '1/1',
  pointerEvents: 'all',
})

const StyledLinkWrap = styled(Link, {
  gridColumn: '1/2',
  gridRow: '1',
})

const StyledWrap = styled('div', {
  gridColumn: '1/2',
  gridRow: '1',
})

const OptionalLinkWrap: FC<PropsWithChildren<{ link?: PageLink }>> = ({
  link,
  children,
}) => {
  if (link) {
    return (
      <StyledLinkWrap {...link} appearance="block" tabIndex={-1}>
        {children}
      </StyledLinkWrap>
    )
  }
  return <StyledWrap>{children}</StyledWrap>
}

export const SectionDoubleMedia: FC<SectionDoubleMediaProps> = (props) => {
  const { sections = [], sectionSpaceSize = 'none', priority } = props

  if (!sections) {
    return null
  }

  const isWithBackground: boolean = (() => {
    if (!sectionSpaceSize || sectionSpaceSize === 'none') {
      return true
    }
    return false
  })()

  return (
    <SectionWrapper withBackground={isWithBackground}>
      <StyledContainer spaceSize={sectionSpaceSize}>
        {sections.map(
          (
            {
              title,
              background,
              appearance = 'dark',
              caption,
              border = 'top',
              link,
            },
            index,
          ) => {
            const ctaLink = link?.ctaLink
            const ctaLabel = link?.ctaLabel
            const ctaAppearance = link?.ctaAppearance || 'primary'
            const isCtaPresent = ctaLink !== undefined && Boolean(ctaLabel)

            const content = (
              <ContentContainer
                appearance={appearance}
                border={border}
                isContentNonInteractive={isCtaPresent}
              >
                {title && <Title>{title}</Title>}
                {caption && (
                  <CaptionWrap>
                    <PortableText
                      value={caption}
                      withSpacing={false}
                      fontSize={'S'}
                    />
                  </CaptionWrap>
                )}
                {isCtaPresent && (
                  <StyledLink
                    {...ctaLink}
                    appearance={ctaAppearance}
                    size={'S'}
                  >
                    {ctaLabel}
                  </StyledLink>
                )}
              </ContentContainer>
            )

            switch (background?.type) {
              case 'color':
                return (
                  <SubSectionContainer key={index}>
                    <OptionalLinkWrap link={isCtaPresent ? ctaLink : undefined}>
                      <Background
                        css={{
                          backgroundColor:
                            background?.payload?.rgba || '$white',
                        }}
                      />
                    </OptionalLinkWrap>

                    {content}
                  </SubSectionContainer>
                )
              case 'media':
                return (
                  <SubSectionContainer key={index}>
                    <OptionalLinkWrap link={isCtaPresent ? ctaLink : undefined}>
                      <Background>
                        <Media
                          {...(background?.payload || {})}
                          layout="responsive"
                          sizes={{ lg: '50vw' }}
                          priority={priority}
                          withBackground
                        />
                      </Background>
                    </OptionalLinkWrap>

                    {content}
                  </SubSectionContainer>
                )
              default:
                return <div key={index}>wrong media type</div>
            }
          },
        )}
      </StyledContainer>
    </SectionWrapper>
  )
}
