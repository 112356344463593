import { styled } from '@aether/styles'
import { FC, Fragment } from 'react'

import { UpdateFiltersParamsFnType } from '../useCollectionSearchParams'
import { NoFiltersInfoText } from './NoFiltersInfoText'
import { ResolvedFilter } from '@aether/collection'

const Root = styled('div', {
  paddingBottom: '$48',
  display: 'grid',
  columnGap: '$12',
  rowGap: '$28',
  gridTemplateColumns: 'repeat(3, 1fr)',
})

const InputRadio = styled('input', {
  appearance: 'none',
  border: 0,
  position: 'absolute',
})

const LabelWrap = styled('label', {
  display: 'inline-flex',
  $aetherFont: 'ui02',
  borderRadius: '$r1',
  height: '$40',
  border: '1px solid',
  alignItems: 'center',
  justifyContent: 'center',
  variants: {
    selected: {
      true: {
        backgroundColor: '$black',
        color: '$white',
      },
    },
    isDisabled: {
      true: {
        opacity: 0.4,
      },
      false: {
        cursor: 'pointer',
      },
    },
  },
})

type Props = {
  className?: string
  onFilterChange: UpdateFiltersParamsFnType
  filters: ResolvedFilter[]
  selectedFilters: string[]
}

export const MetaFieldFilter: FC<Props> = ({
  className,
  filters,
  onFilterChange,
  selectedFilters,
}) => {
  if (!filters?.length) {
    return <NoFiltersInfoText />
  }

  const { searchParamName, filterName } = filters[0]

  return (
    <Root className={className}>
      {filters.map(({ label, value, count }) => {
        const isSelected = selectedFilters?.includes(value)
        const HTML_ID = `${filterName}_${value}`

        const isDisabled = Boolean(count === 0)

        return (
          <Fragment key={value}>
            <InputRadio
              type="checkbox"
              id={HTML_ID}
              value={label}
              onClick={() => onFilterChange(searchParamName, value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  onFilterChange(searchParamName, value)
                }
              }}
              checked={isSelected}
              disabled={isDisabled}
            />
            <LabelWrap
              selected={isSelected}
              htmlFor={HTML_ID}
              isDisabled={isDisabled}
            >
              {`${label}`}
            </LabelWrap>
          </Fragment>
        )
      })}
    </Root>
  )
}
