import { FC } from 'react'
import { PortableText, SectionWrapper } from '@aether/components'

import {
  SectionSingleImage,
  SectionDoubleImage,
  SectionScatteredGallery,
  SectionVideoPlayer,
} from '@aether/sections'
import {
  SectionEditorialRichTextProps,
  SectionDoubleImageProps,
  SectionSingleImageProps,
  SectionScatteredGalleryProps,
  SectionVideoPlayerProps,
} from '@aether/models'
import { styled } from '@aether/styles'
import { PortableTextTypeComponent } from '@portabletext/react'

const Wrap = styled('div', {
  '*:last-child': {
    marginBottom: 0,
  },
  '*:first-child': {
    marginTop: 0,
  },
  '.highlighted': {
    color: '$blue',
  },
  '.italic': {
    fontStyle: 'italic',
  },
  '& > blockquote': {
    $containerSize: 'S',
    $containerSpace: 'small',
    $aetherFont: 'heading07',
    marginBottom: '$24',
    marginTop: '$24',
  },
  '& > h2': {
    $aetherFont: 'heading04',
    $containerSpace: 'small',
    $containerSize: 'S',
    marginBottom: '$24',
    marginTop: '$24',
  },
  '& > h3': {
    $aetherFont: 'heading06',
    $containerSpace: 'small',
    $containerSize: 'S',
    marginBottom: '$24',
    marginTop: '$24',
  },

  '& > p, & > ul, & > ol': {
    listStyle: 'inside',
    $containerSpace: 'small',
    $containerSize: 'S',
    $aetherFont: 'body01',
    marginBottom: '$24',
    marginTop: '$24',
  },
  'ol > li': {
    listStyleType: 'decimal',
  },
  'ul > li': {
    listStyleType: 'disc',
  },

  variants: {
    pageContext: {
      default: {
        background: '$white',
      },
      journal: {
        background: '$beige',
        paddingTop: '$sectionSpaceMobile',
        paddingBottom: '$sectionSpaceMobile',
        '@lg': {
          paddingTop: '$sectionSpaceDesktop',
          paddingBottom: '$sectionSpaceDesktop',
        },
      },
    },
  },
})

const sectionSingleImage: PortableTextTypeComponent<
  SectionSingleImageProps
> = ({ value }) => {
  return <SectionSingleImage {...value} />
}
const sectionDoubleImage: PortableTextTypeComponent<
  SectionDoubleImageProps
> = ({ value }) => {
  return <SectionDoubleImage {...value} />
}

const sectionScatteredGallery: PortableTextTypeComponent<
  SectionScatteredGalleryProps
> = ({ value }) => {
  return <SectionScatteredGallery {...value} />
}
const sectionVideoPlayer: PortableTextTypeComponent<
  SectionVideoPlayerProps
> = ({ value }) => {
  return <SectionVideoPlayer {...value} />
}
export const SectionEditorialRichText: FC<SectionEditorialRichTextProps> = ({
  content,
  pageContext = 'default',
}) => {
  return (
    <SectionWrapper withBackground={pageContext === 'journal'}>
      <Wrap pageContext={pageContext}>
        <PortableText
          withStyles={false}
          value={content}
          additionalComponents={{
            types: {
              sectionSingleImage,
              sectionDoubleImage,
              sectionScatteredGallery,
              sectionVideoPlayer,
            },
          }}
        />
      </Wrap>
    </SectionWrapper>
  )
}
