import { FC, useEffect } from 'react'
import { SectionCollectionAnalyticsProps, ShopifyProduct } from '@aether/models'
import { GTMViewItemList } from '@aether/services/gtm-service'

export const SectionCollectionAnalytics: FC<
  SectionCollectionAnalyticsProps
> = ({ collectionName, shopifyData }) => {
  const products = shopifyData?.products
  useEffect(() => {
    if (products && collectionName) {
      const gtmProducts = Object.keys(products).reduce(
        (acc: ShopifyProduct[], val) => {
          return [...acc, products[val]]
        },
        [],
      )
      GTMViewItemList(gtmProducts, 'section_collection', collectionName)
    }
  }, [collectionName, JSON.stringify(products)])
  return null
}
