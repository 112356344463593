import { FC, PropsWithChildren } from 'react'
import { Container, Link, Media, SectionWrapper } from '@aether/components'
import { styled } from '@aether/styles'
import { PageLink, SectionJournalHeroProps } from '@aether/models'
import { useTranslation } from 'react-i18next'

const Wrap = styled('div', {
  width: '100%',
  $posterHeight: true,
  display: 'grid',
  variants: {
    heroTextTheme: {
      light: {
        color: '$white',
      },
      dark: {
        color: '$black',
      },
    },
  },
})
const StyledContainer = styled(Container, {
  width: '100%',
  zIndex: '$content',
  gridColumn: 1,
  gridRow: 1,
  padding: '$64 0',
  pointerEvents: 'none',
})
const ContentWrap = styled('div', {
  display: 'grid',
  gridGap: '$24',
  width: '100%',
  height: '100%',
  paddingLeft: '$24',
  alignContent: 'end',
  borderLeft: '1px solid',
  variants: {
    heroTitlePosition: {
      top: {
        '@lg': {
          gridTemplateRows: 'auto 1fr',
          alignSelf: 'end',
          gridRow: '2/3',
        },
      },
      bottom: {},
    },
  },
})
const Title = styled('h1', {
  $aetherFont: 'heading01',
  '@lg': { maxWidth: '60vw' },
})
const TopWrap = styled('div', {
  display: 'grid',
  justifyItems: 'start',
  gridGap: '$24',
})

const StyledLink = styled(Link, {
  pointerEvents: 'all',
})

const MediaLinkWrap = styled(Link, {
  width: '100%',
  gridColumn: 1,
  gridRow: 1,
})
const MediaWrap = styled('div', {
  width: '100%',
  gridColumn: 1,
  gridRow: 1,
})

const OptionalLinkWrap: FC<PropsWithChildren<{ link?: PageLink }>> = ({
  link,
  children,
}) => {
  if (link) {
    return (
      <MediaLinkWrap {...link} appearance="block" tabIndex={-1}>
        {children}
      </MediaLinkWrap>
    )
  }
  return <MediaWrap>{children}</MediaWrap>
}

export const SectionJournalHero: FC<SectionJournalHeroProps> = (props) => {
  const { t } = useTranslation('journal')

  const { title, background, heroTitlePosition, heroTextTheme, ctaLink } = props

  return (
    <SectionWrapper withBackground>
      <Wrap heroTextTheme={heroTextTheme}>
        <OptionalLinkWrap link={ctaLink ? ctaLink : undefined}>
          {background && (
            <Media
              {...background}
              sizes={{ default: '100vw' }}
              layout="fill"
              priority
              withBackground
            />
          )}
        </OptionalLinkWrap>

        <StyledContainer>
          <ContentWrap heroTitlePosition={heroTitlePosition}>
            <TopWrap>
              <Title as={ctaLink ? Link : 'h1'} {...ctaLink} appearance="block">
                {title}
              </Title>
              {ctaLink && (
                <StyledLink {...ctaLink} appearance="primary">
                  {t('viewStory')}
                </StyledLink>
              )}
            </TopWrap>
          </ContentWrap>
        </StyledContainer>
      </Wrap>
    </SectionWrapper>
  )
}
