import { FC } from 'react'

export const JournalHeadingIcon: FC<{ className?: string }> = ({
  className,
}) => (
  <svg
    className={className}
    viewBox="0 0 700 81"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 52.967L13.3826 51.0991C14.7913 62.6095 19.4232 67.4368 29.1408 67.4368C38.8584 67.4368 43.7172 60.7537 43.7172 48.2488V1.64953H57.6369V49.68C57.6369 68.6497 46.2003 80.0508 29.1408 80.0508C12.0813 80.0508 1.51613 70.5054 0 52.967Z"
      fill="#231F20"
    />
    <path
      d="M95.9462 40.0254C95.9462 16.7743 112.242 0 135.342 0C158.442 0 174.737 16.7743 174.737 40.0254C174.737 63.2765 158.549 80.0508 135.342 80.0508C112.134 80.0508 95.9462 63.2765 95.9462 40.0254ZM160.71 40.0254C160.71 23.5786 150.455 12.5049 135.342 12.5049C120.228 12.5049 109.973 23.5786 109.973 40.0254C109.973 56.4722 120.336 67.4368 135.342 67.4368C150.348 67.4368 160.71 56.4722 160.71 40.0254Z"
      fill="#231F20"
    />
    <path
      d="M213.047 49.0129V1.64953H226.966V48.4671C226.966 61.9545 233.556 67.4368 246.605 67.4368C259.653 67.4368 266.135 61.9545 266.135 48.4671V1.64953H280.055V49.0129C280.055 69.8504 267.854 80.0508 246.593 80.0508C225.331 80.0508 213.023 69.8504 213.023 49.0129H213.047Z"
      fill="#231F20"
    />
    <path
      d="M367.597 49.0129L386.483 78.4013H370.617L352.376 49.3404H336.617V78.4013H322.698V1.64953H361.449C377.852 1.64953 387.891 10.8554 387.891 25.4465C387.891 38.2789 380.335 46.8297 367.597 48.8068V49.0251V49.0129ZM360.255 36.7263C369.101 36.7263 373.96 32.7845 373.96 25.3252C373.96 17.8659 369.531 14.1423 360.04 14.1423H336.617V36.7385H360.255V36.7263Z"
      fill="#231F20"
    />
    <path
      d="M426.081 1.64953H441.302L480.268 58.5584H480.483V1.64953H494.403V78.4134H479.182L440.216 21.3954H440.001V78.4134H426.081V1.64953Z"
      fill="#231F20"
    />
    <path
      d="M590.242 65.1323H550.094L544.483 78.4013H529.369L563.476 1.64953H576.859L610.966 78.4134H595.852L590.242 65.1444V65.1323ZM584.953 52.7365L570.269 17.975H570.054L555.37 52.7365H584.953Z"
      fill="#231F20"
    />
    <path
      d="M659.745 1.64953V65.7994H700V78.4134H645.813V1.64953H659.745Z"
      fill="#231F20"
    />
  </svg>
)
