import { FC, useState, useEffect, PropsWithChildren } from 'react'
import {
  Container,
  Link,
  SectionWrapper,
  SliderIndicator,
  StackImagesSlider,
  useStackImagesSlider,
  STACK_IMAGE_OFFSET_Y,
  FullWidthImageSlider,
  ProgressIndicator,
} from '@aether/components'
import { styled, useMediaQuery } from '@aether/styles'
import { SectionStackImagesProps, PageLink } from '@aether/models'
import { useInView } from 'react-intersection-observer'
import { createDoubleLinksArray } from '@aether/utils'

const MediaWrap = styled('div', {
  variants: {
    imagePosition: {
      left: {
        gridArea: 'left',
      },
      right: {
        gridArea: 'left',
        '@lg': {
          gridArea: 'right',
        },
      },
    },
  },
})

const StyledContainer = styled(Container, {
  display: 'grid',
  gridGap: '$32',
  width: '100%',
  height: '100%',
  gridTemplateAreas: `
                      'left'
                      'right'
                      `,
  '@lg': {
    gridTemplateAreas: `
    'left right'
    `,
  },
  variants: {
    imagePosition: {
      left: {
        '@lg': {
          $lin: {
            gridGap: ['$0', '80px'],
          },
          gridTemplateColumns: '2fr 1fr',
        },
      },
      right: {
        '@lg': {
          $lin: {
            gridGap: ['$0', '80px'],
          },
          gridTemplateColumns: '1fr 2fr',
        },
      },
    },
  },
})
const Title = styled('h2', {
  $aetherFont: 'heading03',
})
const Description = styled('p', {
  $aetherFont: 'body02',
})
const ColumnWithText = styled('div', {
  display: 'flex',
  gap: '$16',
  alignItems: 'flex-start',
  flexDirection: 'column',
  paddingLeft: '$12',
  borderLeft: '1px solid $black',
  marginRight: '25%',
  '@lg': {
    marginRight: 'unset',
    paddingLeft: 'none',
    borderLeft: 'none',
  },
  variants: {
    imagePosition: {
      left: {
        gridArea: 'right',
        paddingLeft: '$12',
        borderLeft: '1px solid $black',
        '@lg': {
          borderLeft: 0,
          paddingLeft: 0,
          $lin: {
            paddingRight: ['$0', '80px'],
          },
          borderRight: '1px solid $black',
        },
      },
      right: {
        gridArea: 'right',
        '@lg': {
          gridArea: 'left',
          paddingLeft: '$40',
          borderLeft: '1px solid $black',
        },
      },
    },
  },
})

const SliderWrap = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '$24',
})

const StyledSliderIndicator = styled(SliderIndicator, {
  alignSelf: 'end',
})

const MobileGalleryContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '$20',
})

const OptionalLinkWrap: FC<PropsWithChildren<{ link?: PageLink }>> = ({
  link,
  children,
}) => {
  if (link) {
    return (
      <Link {...link} appearance="block" tabIndex={-1}>
        {children}
      </Link>
    )
  }
  return <>{children}</>
}

export const SectionStackImages: FC<SectionStackImagesProps> = ({
  title,
  description,
  images: imagesCollection,
  sectionSpaceSize = 'small',
  imagePosition,
  ctaLabel,
  ctaLink,
  secondaryCtaLabel,
  secondaryCtaLink,
}) => {
  const [isHovered, setIsHovered] = useState<boolean>(false)
  const [currentMobileSlide, setCurrentMobileSlide] = useState<number>(0)

  const links = createDoubleLinksArray(
    ctaLink,
    ctaLabel,
    secondaryCtaLink,
    secondaryCtaLabel,
  )

  const images = imagesCollection || []

  const {
    activeSlideIndex,
    setNextSlideIndex,
    setPrevSlideIndex,
    setSlideIndex,
    onAnimationEnd,
    onAnimationStart,
  } = useStackImagesSlider(images)
  const isMd = useMediaQuery('md')

  const [mediaWrapRef, mediaWrapInView] = useInView({
    triggerOnce: true,
    rootMargin: isMd ? '-50%' : '-20%',
  })

  const marginTop = STACK_IMAGE_OFFSET_Y * (images.length - 1)
  const marginRight = STACK_IMAGE_OFFSET_Y * images.length

  useEffect(() => {
    if (mediaWrapInView) {
      setIsHovered(true)
    }
  }, [mediaWrapInView])

  if (!images || !images.length) {
    return null
  }

  return (
    <SectionWrapper>
      {isMd ? (
        <StyledContainer
          spaceSize={sectionSpaceSize}
          imagePosition={imagePosition}
        >
          <SliderWrap>
            <MediaWrap
              ref={mediaWrapRef}
              css={{ marginTop, marginRight }}
              imagePosition={imagePosition}
            >
              <OptionalLinkWrap
                link={links.length === 1 ? links[0].ctaLink : undefined}
              >
                <StackImagesSlider
                  mediaSizes={{ md: '50vw', default: '100vw' }}
                  images={images}
                  activeSlideIndex={activeSlideIndex}
                  isUnfolded={isHovered}
                  onNextSlideClick={setNextSlideIndex}
                  onAnimationEnd={onAnimationEnd}
                  onAnimationStart={onAnimationStart}
                  onPrevSlideClick={setPrevSlideIndex}
                />
              </OptionalLinkWrap>
            </MediaWrap>
            {images.length > 1 && (
              <OptionalLinkWrap
                link={links.length === 1 ? links[0].ctaLink : undefined}
              >
                <StyledSliderIndicator
                  css={{ marginRight }}
                  images={images}
                  activeSlideIndex={activeSlideIndex}
                  onSlideClick={setSlideIndex}
                />
              </OptionalLinkWrap>
            )}
          </SliderWrap>
          <ColumnWithText imagePosition={imagePosition}>
            <Title>{title}</Title>
            {description && <Description>{description}</Description>}
            {links.length > 0 &&
              links.map(({ ctaLink, ctaLabel }, index) => (
                <Link
                  {...ctaLink}
                  appearance="primary"
                  key={`${ctaLabel}-${index}`}
                >
                  {ctaLabel}
                </Link>
              ))}
          </ColumnWithText>
        </StyledContainer>
      ) : (
        <>
          <MobileGalleryContainer>
            <OptionalLinkWrap
              link={links.length === 1 ? links[0].ctaLink : undefined}
            >
              <FullWidthImageSlider
                images={images}
                onSlideChange={setCurrentMobileSlide}
                spacing="medium"
              />
            </OptionalLinkWrap>
            <ProgressIndicator
              currentSlide={currentMobileSlide}
              images={images}
              spacing={sectionSpaceSize}
              size="M"
            />
          </MobileGalleryContainer>
          <StyledContainer spaceSize="medium" imagePosition={imagePosition}>
            <ColumnWithText imagePosition={imagePosition}>
              <Title>{title}</Title>
              {description && <Description>{description}</Description>}
              {links.length > 0 &&
                links.map(({ ctaLink, ctaLabel }, index) => (
                  <Link
                    {...ctaLink}
                    appearance="primary"
                    key={`${ctaLabel}-${index}`}
                  >
                    {ctaLabel}
                  </Link>
                ))}
            </ColumnWithText>
          </StyledContainer>
        </>
      )}
    </SectionWrapper>
  )
}
