import { FC } from 'react'
import { SectionDoublePromoProps } from '@aether/models'
import { Container, Media, SectionWrapper } from '@aether/components'
import { styled } from '@aether/styles'

const StyledContentWrap = styled('div', {
  display: 'grid',
  '@md': {
    gridTemplateColumns: '1fr 1fr',
    gridAutoRows: '1fr',
  },
})

const ImageWrap = styled('div', {
  display: 'grid',
})

const MediaWrap = styled('div', {
  gridRow: 1,
  gridColumn: 1,
})

const ImageText = styled('h2', {
  wordBreak: 'break-word',
  $aetherFont: 'heading04',
  display: 'flex',
  gridRow: 1,
  gridColumn: 1,
  zIndex: '$content',
  $containerSpace: 'small',
  paddingTop: '$24',
  paddingBottom: '$24',
  '@lg': {
    $aetherFont: 'heading03',
    paddingTop: '$48',
    paddingBottom: '$48',
  },

  variants: {
    textColor: {
      light: {
        color: '$white',
      },

      dark: {
        color: '$black',
      },
    },

    textPosition: {
      top: {
        alignItems: 'start',
      },

      center: {
        alignItems: 'center',
      },

      bottom: {
        alignItems: 'end',
      },
    },
  },
})

export const SectionDoublePromo: FC<SectionDoublePromoProps> = ({
  cards,
  sectionSpaceSize,
  priority,
}) => {
  return (
    <SectionWrapper withBackground={true}>
      <Container spaceSize={sectionSpaceSize}>
        <StyledContentWrap>
          {cards &&
            cards.map((card, index) => (
              <ImageWrap key={`${index}-${card.text}`}>
                {card.text && (
                  <ImageText
                    as={priority && index === 0 ? 'h1' : 'h2'}
                    textColor={card.textColor}
                    textPosition={card.textPosition}
                  >
                    {card.text}
                  </ImageText>
                )}
                {card.image && (
                  <MediaWrap>
                    <Media
                      {...card.image}
                      hardcropRatio="portrait1"
                      sizes={{ md: '50vw' }}
                      withBackground
                    />
                  </MediaWrap>
                )}
              </ImageWrap>
            ))}
        </StyledContentWrap>
      </Container>
    </SectionWrapper>
  )
}
