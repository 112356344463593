import { ShopifyProductFilter } from '@aether/shopify-sdk'
import { ResolvedFilter } from '../models'
import { getFiltersArray } from './getFiltersArray'

export const getShopifyFilters = (
  searchParams: URLSearchParams,
  resolvedFilters: ResolvedFilter[],
): ShopifyProductFilter[] => {
  const selectedFilters = [
    ...getFiltersArray(searchParams.getAll('filters')),
    ...searchParams.getAll('price'),
  ]

  if (!selectedFilters) return []
  const filters = selectedFilters.reduce<ShopifyProductFilter[]>(
    (acc, filterId) => {
      const filterData = resolvedFilters.find(
        (filter) => filter.value === filterId,
      )

      if (filterData) {
        return [...acc, JSON.parse(filterData.shopifyInput)]
      }

      return acc
    },
    [],
  )

  return filters
}
