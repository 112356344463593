import { FC } from 'react'
import { styled } from '@aether/styles'
import { ShopifyProduct } from '@aether/models'
import { ProductCard } from '@aether/components'

type MobileGalleryProps = {
  products: ShopifyProduct[]
}

const Slider = styled('ul', {
  width: '100vw',
  overflowX: 'scroll',
  overflowY: 'auto',
  display: 'flex',
  scrollSnapType: 'x mandatory',
  scrollBehavior: 'smooth',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
  $lin: {
    scrollPaddingLeft: 'CONTAINER_SPACE_M',
  },
})

const Slide = styled('li', {
  flex: '0 0 85%',
  flexFlow: 'column',
  minWidth: 'auto',
  marginRight: '$12',
  scrollSnapAlign: 'start',
  height: '100%',
})

const Spacer = styled('li', {
  $lin: {
    paddingLeft: 'CONTAINER_SPACE_S',
  },
})

export const MobileGallery: FC<MobileGalleryProps> = ({ products }) => {
  return (
    <Slider>
      <Spacer />
      {products.map((product, index) => (
        <Slide key={`${product.id}-${index}`}>
          <ProductCard product={product} mediaSizes={{ default: '100vw' }} />
        </Slide>
      ))}
      <Spacer />
    </Slider>
  )
}
